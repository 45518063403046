import React from 'react'
import _ from 'lodash'
import M from '../../common/moment'
import Intent from './Intent'
import {toast} from 'react-toastify'

import {
  Form, FormField, Button, Heading, Box
} from 'grommet'


import {Paper} from '../Paper'
import {DatePicker} from '../DatePicker'

import gql from 'graphql-tag'
import {
  useUpdateEmployeeCardMutation,
  useEmployeeCardQuery,
  UpdateEmployeeInput,
  EmployeeCardDocument
} from '../../generated/graphql'

// eslint-disable-next-line
const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployeeCard($input: UpdateEmployeeInput!) {
    updateEmployeeCard(input: $input) {
      employee {
        id,
        firedDate, hiredDate,
        passport {id, firstName, lastName}
      }
    }
  }
`

type IntentVariables = Pick<UpdateEmployeeInput, 'employeeId'>

export default class UpdateEmployee extends Intent<IntentVariables> {
  static type = 'employees.update_card'

  get type(): string {
    return UpdateEmployee.type
  }

  asComponent() {
    return () => {
      const [mutate, {data, loading, error}] =
        useUpdateEmployeeCardMutation({
          refetchQueries: [{
            query: EmployeeCardDocument,
            variables: {employeeId: this.initialProps.employeeId}
          }]
        })

      const submit = (data: FormVariables) => {
        mutate({
          variables: {
            input: {
              ...data,
              hiredDate: data.hiredDate
                ? M(data.hiredDate).format('YYYY-MM-DD') : null,
              firedDate: data.firedDate
                ? M(data.firedDate).format('YYYY-MM-DD') : null,
            }
          }
        }).then(({data}) => {
          console.log('FINISHED', data)
          toast.success('Изменения сохранены')
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      // if (data) return <p>ГОТОВО</p>

      return (
        <UpdateEmployeeForm onSubmit={submit} {...this.initialProps} />
      )
    }
  }
}

type FormVariables = UpdateEmployeeInput

//TODO graphql#5: split update flow into updatePassport and updateEmployeeCard
// for now, passport-related updates will be silently ignored
const UpdateEmployeeForm = ({onSubmit, employeeId}) => {
  const {data, loading, error} = useEmployeeCardQuery({
    variables: {employeeId}
  })

  const [changes, setChanges] = React.useState<FormVariables>(null)
  const employee = data?.listEmployees.employees[0]
  const fields: FormVariables = changes || {
    employeeId,
    ..._.pick(employee.passport, 'firstName', 'middleName', 'lastName'),
    ..._.pick(employee, 'firedDate', 'hiredDate')
  }

  return employee ? (
    <Form value={fields}
      onChange={v => {v.preventDefault ?? setChanges(v)}}
      onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Карточка сотрудника
        </Heading>
        <FormField name="lastName" required label="Фамилия" />
        <FormField name="firstName" required label="Имя" />
        <FormField name="hiredDate" label="Дата приема"
          component={DatePicker}
          erasable
        />
        <FormField name="firedDate" label="Дата увольнения"
          component={DatePicker}
          erasable
        />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary disabled={!changes}
            label="Сохранить" />
        </Box>
      </Paper>
    </Form >
  ) : <i>'ЗАГРУЗКА'</i>
}
