import styled from 'styled-components/macro'
import {Box} from 'grommet'

export const Paper = styled(Box).attrs(props => ({
  pad: '.5rem',
  ...props,
  background: props.background || 'paper',
}))`
  border-radius: 2px;
`
