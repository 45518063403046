import React, {ReactNode} from 'react'
import {Box, Heading, HeadingProps} from 'grommet'
import styled from 'styled-components/macro'

type PagebarProps = {
  title: any,
  actions?: ReactNode | Array<ReactNode>,
  level?: HeadingProps['level']
} & React.ComponentProps<typeof Box>

const Pagebar = ({title, level='1', actions = null, ...props}: PagebarProps) => (
  <Box
    direction="row"
    justify="between"
    align="center"
    margin={{horizontal: '-0.5rem', bottom: '0.5rem'}}
    pad={{horizontal: '0.5rem'}}
    css={`
      max-width: none;
      color: ${p => p.theme.getColor(
        p.level == '1' ? 'text-primary' : 'grey-800')};
      &:last-child { margin-bottom: 0; }
    `}
    {...props}
  >
    <PagebarHeading level={level}>{title}</PagebarHeading>
    {actions && (
      <Box direction="row" align="center" gap="xsmall">
        {actions}
      </Box>
    )}
  </Box>
)

export default Pagebar

export const PagebarHeading = styled(Heading).attrs(props => ({
  size: 'small',
  margin: '0',
  color: 'inherit'
}))``
