import React from 'react'
import {Text} from 'grommet'


export const CardLabel: React.FC<React.ComponentProps<typeof Text>> =
  ({children, ...props}) => (
    <Text weight={500} size=".9em" color="grey-700" {...props}>
      {children}
    </Text>
  )
