import React from 'react'
import 'styled-components/macro'
import _ from 'lodash'
import M from '../../common/moment'
import {useParams} from 'react-router-dom'

import Pagebar from '../../components/Pagebar'
import {Paper} from '../../components/Paper'
import {JobLabel} from '../../components/JobLabel'
import Anchor from '../../components/RoutedAnchor'
import {CardLabel} from '../../components/CardLabel'

import {List, Box, Text, Button} from 'grommet'
import {
  FormEdit as EditIcon,
  AddCircle as AddIcon
} from 'grommet-icons'

import {useHistory} from 'react-router-dom'
import {orgPath} from '../../common/url'
import {IntentContext} from '../../components/intent/IntentContext'
import UpdateCompany from '../../components/intent/admin/UpdateCompany'
import EnlistEmployee from '../../components/intent/EnlistEmployee'


import gql from 'graphql-tag'
import {
  useCompanyCardQuery,
  CompanyCardQuery,
} from '../../generated/graphql'

// eslint-disable-next-line
const GET_COMPANY_CARD = gql`
  query CompanyCard($companyId: ID!) {
    listCompanies(input: {companyIds: [$companyId]}) {
      companies {
        id
        name
        slug
        employees {
          id, role, passport {id, type, firstName, lastName}
        }
        createdStamp
      }
    }
  }
`


export default function AdminCompany() {
  const {companyId} = useParams()
  const {onIntent} = React.useContext(IntentContext)
  const history = useHistory()
  const {data, loading, error, refetch} = useCompanyCardQuery({
    variables: {companyId},
    fetchPolicy: 'cache-and-network'
  })

  const onAddEmployee = () => onIntent(
    new EnlistEmployee({
      companyId: company.id
    }, {
      then: () => refetch()
    }))

  if (!data && loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  const company = _.first(data.listCompanies.companies)

  return (
    <article>
      <Box direction="column" gap="medium">

        <Paper>
          <Pagebar title={company.name}
            actions={(<Button
              css="padding: 0 .3em 0 .3em"
              margin={{horizontal: 'xsmall'}}
              hoverIndicator
              icon={<EditIcon />}
              onClick={() => onIntent(new UpdateCompany({
                companyId: company.id
              }))}
            />)}
          />
          <CompanyCard company={company} />
        </Paper>

        <Paper>
          <Pagebar level='2' title="Сотрудники"
            actions={(<Button
              css="padding: 0 .3em 0 .3em"
              margin={{horizontal: 'xsmall'}}
              hoverIndicator
              icon={<AddIcon />}
              onClick={onAddEmployee}
            />)}
          />
          {company.employees.length ? (
            <EmployeeList employees={company.employees} />
          ) : (
              <Text>Никого нет</Text>
            )}
        </Paper>

      </Box>
    </article>
  )
}

interface CardProps {
  company: CompanyCardQuery["listCompanies"]["companies"][0],
}
const CompanyCard = ({company}: CardProps) => {
  return (
    <Box direction="column" gap="medium" pad={{vertical: 'medium'}}>
      <Text>
        <CardLabel>URL: </CardLabel>
        {company.slug}
      </Text>
      <Text>
        <CardLabel>Создана: </CardLabel>
        {M(company.createdStamp).format('DD.MM.YYYY HH:mm')}
      </Text>
    </Box>
  )
}

type Employee = CompanyCardQuery['listCompanies']['companies'][0]['employees']

const EmployeeList = ({employees}: {employees: Employee}) => {
  const sorted = _.sortBy(employees, ['passport.lastName'])

  return (
    <List
      data={sorted}
      primaryKey={e =>
        <EmployeeLink key={e.id} employee={e} />}
      secondaryKey={e =>
        <Taglist key={e.id + 'r'} tags={[
          ...(e.role == 'owner' ? [{name: 'владелец'}] : []),
          ...(e.role == 'manager' ? [{name: 'менеджер'}] : []),
          ...(e.passport.type == 'userless' ? [] : [{name: 'user'}])
        ]} />}
      css={`
      & li {justify-content: unset;}
    `}
    />
  )
}

const EmployeeLink = ({employee}) => (
  <Text>
    <Anchor
      href={`/passports/${employee.passport.id}`}>
      {employee.passport.firstName} {employee.passport.lastName}
    </Anchor>
  </Text>
)

// copypasted from Team.JobsLabel
const Taglist = ({tags}) => (
  <Box direction="row" gap="xsmall" wrap css={`
    // hack for vertical spacing of wrapped flex elemers
    margin-top: -1ex;
    & > * {margin-top: 1ex}
  `} >
    {_.map(tags, t => <JobLabel job={t} key={t.name} />)}
  </Box>
)
