import React from 'react'
import _ from 'lodash'
import {ResponsiveContext} from "grommet"

const sizes = [
  'xsmall',
  'small',
  'medium',
  'large'
]


export const useBreakpointsContext = () => {
  const size = React.useContext(ResponsiveContext)
  return {
    size,
    screenAbove: (test: string) =>
      sizes.indexOf(size) > sizes.indexOf(test),
    screenAboveEq: (test: string) =>
      sizes.indexOf(size) >= sizes.indexOf(test),
    screenBelow: (test: string) =>
      sizes.indexOf(size) < sizes.indexOf(test),
    screenBelowEq: (test: string) =>
      sizes.indexOf(size) <= sizes.indexOf(test)
  }
}
