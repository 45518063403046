import React from 'react'
import _ from 'lodash'
import Anchor from '../../components/RoutedAnchor'
import {Box, Text, Button} from 'grommet'
import {AddCircle} from 'grommet-icons'
import 'styled-components/macro'

import Pagebar from '../../components/Pagebar'
import {Paper} from '../../components/Paper'

import {IntentContext} from '../../components/intent/IntentContext'
import CreateCompany from '../../components/intent/admin/CreateCompany'

import gql from 'graphql-tag'
import {
  useListAllCompaniesQuery,
  ListAllCompaniesQuery,
} from '../../generated/graphql'

// eslint-disable-next-line
const GET_COMPANIES = gql`
  query ListAllCompanies {
    listCompanies(input: {}) {
      companies {id, name}
    }
  }
`

export default function AdminCompanies() {
  const {onIntent} = React.useContext(IntentContext)
  const {data, loading, error} = useListAllCompaniesQuery({
    fetchPolicy: 'cache-and-network'
  })
  if (!data && loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  return (
    <article>
      <Paper>
        <Pagebar title="Организации"
          actions={(<Button
            css="padding: 0 .3em 0 .3em"
            margin={{horizontal: 'xsmall'}}
            hoverIndicator
            icon={<AddCircle />}
            onClick={() => onIntent(new CreateCompany())}
          />)}
        />
        <CompanyList {...data.listCompanies} />
      </Paper>
    </article>
  )
}


function CompanyList ({companies}
  : ListAllCompaniesQuery['listCompanies']
) {
  const sorted = _.sortBy(companies, 'name')
  return (
    <Box direction="column" gap="xxsmall">
      {_.map(sorted, c => (
        <CompanyEntry key={c.id} {...c} />
      ))}
    </Box>
  )
}

type CompanyProps = ListAllCompaniesQuery['listCompanies']['companies'][0]

const CompanyEntry = ({id, name}: CompanyProps) => (
  <Text>
    <Anchor href={`/companies/${id}`}>
      {name}
    </Anchor>
  </Text>
)
