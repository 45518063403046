import React from 'react'
import 'styled-components/macro'
import theme from './theme'


import Intent from './intent/Intent'


export const ChatBar = ({intent}: {intent: Intent<any>}) => {
  const IntentForm = intent?.asComponent()
  return (
    <div css={`
      position: fixed;
      width: inherit;
      /*TODO: use withEdge()?*/
      /*20px for vertical gaps, i don't know how to get it from theme*/
      max-height: calc(100% - ${theme.header.height} - 20px);
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar { width: 6px; height: 6px;}
      &::-webkit-scrollbar-track { background: #f0f0f0; }
      &::-webkit-scrollbar-thumb { background: #ddd; }
      &:hover::-webkit-scrollbar-thumb { background: #bbb; }
      &::-webkit-scrollbar-thumb:horizontal:hover,
      &::-webkit-scrollbar-thumb:vertical:hover { background: #999; }
      &::-webkit-scrollbar-thumb:horizontal:active,
      &::-webkit-scrollbar-thumb:vertical:active { background: #777; }
    `}>
      {IntentForm && <IntentForm />}
    </div>
  )
}
