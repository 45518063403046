import React from "react"
import _ from 'lodash'
import 'styled-components/macro'


import {CustomPicker} from 'react-color'
import {Swatch} from 'react-color/lib/components/common'
import {Box, Text, DropButton, Calendar, Button} from "grommet"
import {colorIsDark} from "grommet/utils/colors"
import {FormDown} from "grommet-icons"

import {ColoredBox} from './ColoredBox'


export const ColorPicker = ({
  value = '', onChange = null, placeholder = ''
}) => {
  const [open, setOpen] = React.useState(false)
  const [changes, setChanges] = React.useState(null)
  const color = changes || value

  const onSelect = value => {
    setChanges(value.hex == '#ffffff' ? '' : value.hex)
    setOpen(false)
    onChange && onChange({value: value.hex, target: {value: value.hex}})
  }

  return (
    <DropButton
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      dropProps={{stretch: false}}
      dropAlign={{top: 'bottom', left: 'left'}}
      dropContent={
        <FlatColorPicker
          color={color || '#fff'}
          onChangeComplete={onSelect} />
      }
    >
      <Box direction="row" justify="between" align="center" pad="small">
        <ColoredBox flex={false} color={color} backgroundOpacity={0.4}>
          <Text
            size="small"
            color="inherit"
            css="font-variant: all-small-caps"
          >
            {placeholder || value}
          </Text>
        </ColoredBox>
        <FormDown />
      </Box>
    </DropButton>
  )
}


const FlatColorPicker = CustomPicker(({hex, onChange}) => {
  const colors = [
    '#FEF3BD', '#FCCB00', '#FAD0C3', '#C1E1C5',
    '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB',
    '#FFFFFF', '#B80000', '#DB3E00', '#008B02',
    '#006B76', '#1273DE', '#004DCF', '#5300EB',]

  const handleChange = (hex, e) => onChange({hex, source: 'hex'}, e)

  return (
    <Box direction="row" wrap width="232px" pad="2px"
      css="box-sizing: unset"
    >
      {_.map(colors, c => (
        <ColorPickerSwatch
          active={c.toLowerCase() == hex.toLowerCase()}
          color={c}
          key={c}
          onClick={handleChange}
        />
      ))}
    </Box>
  )
})

const ColorPickerSwatch = ({color, onClick, active}) => {
  return (
    <div className={active && 'active'} css={`
      width: 25px;
      height: 25px;
      margin: 2px;
      font-size: 0;

      & .dot {
        position: absolute;
        top: 10px; right: 10px; bottom: 10px; left: 10px;
        background-color: ${colorIsDark(color)
        ? 'rgba(255,255,255,0.85)' : 'rgba(0,0,0,0.7)'};
        border-radius: 50%;
        opacity: 0;
      }

      &.active {
        position: relative;
        z-index: 2;
        outline: 2px solid #fff;

        & .dot {opacity: 1}
      }
    `}>
      <Swatch
        color={color}
        onClick={onClick}
      > <div className="dot" /> </Swatch>
    </div>
  )
}
