import {useLocation} from "react-router-dom"
import urlJoin from 'url-join'


export function currentMode() {
  return /^admin/.test(window.location.host)
    ? 'admin' : 'app'
}

// return host for APP mode
function appHost() {
  var host = window.location.host

  if (currentMode() == 'admin') {
    host = host.replace(/^admin-/, '').replace(/^admin\./, '')
  }
  return `${window.location.protocol}//${host}`
}

// return url for APP mode path
export function appUrl(path) {
  return urlJoin(appHost(), path)
}

export function currentOrgId() {
  const match = window.location.pathname.match(/^\/org\/([^\/]+)/i)
  return match?.[1]
}

export function apiUrl() {
  const url = process.env.REACT_APP_API_URL
  if (!url) throw new Error('No API URL provided')
  return url
}

/**
 * @param paths one or multiple paths to combine
 * @param this {orgId: string} if provided, will override current orgId url context
 */
export function orgPath(...paths) {
  const orgId = this?.orgId ?? currentOrgId()
  if (typeof orgId != 'string') throw new Error('No orgId provided')
  return urlJoin('/org', orgId, ...paths)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
// https://reacttraining.com/react-router/web/example/query-parameters
export function useQuery() {
  const query = new URLSearchParams(useLocation().search)
  return Object.fromEntries(query)
}
