import deepFreeze from 'deep-freeze'

// leaving the fonts and dimensions part from the base theme
// https://github.com/grommet/grommet/blob/master/src/js/themes/base.js

export const generate = ({
  baseSpacing = 24, scale = 6, baseFont = null, limitWidth = true
} = {}) => {
  // 24
  const baseFontSize = baseFont || (baseSpacing * 0.75); // 18
  const fontScale = baseSpacing / scale; // 4

  const fontSizing = factor => ({
    size: `${baseFontSize + factor * fontScale}px`,
    height: `${baseSpacing + factor * fontScale}px`,
    // maxWidth chosen to be ~50 characters wide
    // see: https://ux.stackexchange.com/a/34125
    maxWidth: limitWidth
      ? `${baseSpacing * (baseFontSize + factor * fontScale)}px`
      : '100%',
  });

  const borderWidth = 2;

  const result = {
    global: {
      borderSize: {
        xsmall: '1px',
        small: '2px',
        medium: `${baseSpacing / 6}px`, // 4
        large: `${baseSpacing / 2}px`, // 12
        xlarge: `${baseSpacing}px`, // 24
      },
      breakpoints: {
        // material ui has xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920
        xsmall: {
          value: 600
        },
        small: {
          // grommet has 768
          value: 960,
          borderSize: {
            xsmall: '1px',
            small: '2px',
            medium: `${baseSpacing / 6}px`, // 4
            large: `${baseSpacing / 4}px`, // 6
            xlarge: `${baseSpacing / 2}px`, // 12
          },
          edgeSize: {
            none: '0px',
            hair: '1px', // for Chart
            xxsmall: '2px',
            xsmall: `${baseSpacing / 8}px`, // 3
            small: `${baseSpacing / 4}px`, // 6
            medium: `${baseSpacing / 2}px`, // 12
            large: `${baseSpacing}px`, // 24
            xlarge: `${baseSpacing * 2}px`, // 48
          },
          size: {
            xxsmall: `${baseSpacing}px`, // 24
            xsmall: `${baseSpacing * 2}px`, // 48
            small: `${baseSpacing * 4}px`, // 96
            medium: `${baseSpacing * 8}px`, // 192
            large: `${baseSpacing * 16}px`, // 384
            xlarge: `${baseSpacing * 32}px`, // 768
            full: '100%',
          },
        },
        medium: {
          value: 1280
        },
        large: {}, // anything above 'medium'
      },
      // Breakpoints used at Server Side Rendering for the initial rendering
      // These values correspond to the theme breakpoints
      deviceBreakpoints: {
        phone: 'small',
        tablet: 'medium',
        computer: 'large',
      },
      edgeSize: {
        none: '0px',
        hair: '1px', // for Chart
        xxsmall: `${baseSpacing / 8}px`, // 3
        xsmall: `${baseSpacing / 4}px`, // 6
        small: `${baseSpacing / 2}px`, // 12
        medium: `${baseSpacing}px`, // 24
        large: `${baseSpacing * 2}px`, // 48
        xlarge: `${baseSpacing * 4}px`, // 96
        responsiveBreakpoint: 'small',
      },
      font: {
        ...fontSizing(0),
        // face: undefined,
        // family: undefined,
      },
      input: {
        padding: `${baseSpacing / 2}px`,
        weight: 500,
      },
      spacing: `${baseSpacing}px`,
      size: {
        xxsmall: `${baseSpacing * 2}px`, // 48
        xsmall: `${baseSpacing * 4}px`, // 96
        small: `${baseSpacing * 8}px`, // 192
        medium: `${baseSpacing * 16}px`, // 384
        large: `${baseSpacing * 32}px`, // 768
        xlarge: `${baseSpacing * 48}px`, // 1152
        xxlarge: `${baseSpacing * 64}px`, // 1536
        full: '100%',
      },
    },
    box: {
      responsiveBreakpoint: 'small', // when we switch rows to columns
      // extend: undefined,
    },
    button: {
      border: {
        // color: { dark: undefined, light: undefined }
        width: `${borderWidth}px`,
        radius: `${baseSpacing * 0.75}px`,
      },
      padding: {
        vertical: `${baseSpacing / 4 - borderWidth}px`,
        horizontal: `${baseSpacing - borderWidth}px`,
      },
    },
    calendar: {
      // daySize must align with global.size
      small: {
        fontSize: `${baseFontSize - fontScale}px`,
        lineHeight: 1.3,
        daySize: `${(baseSpacing * 8) / 7}px`,
        slideDuration: '0.2s',
      },
      medium: {
        fontSize: `${baseFontSize - fontScale / 2}px`,
        lineHeight: 1.2,
        daySize: `${(baseSpacing * 16) / 7}px`,
        slideDuration: '0.5s',
      },
      large: {
        fontSize: `${baseFontSize}px`,
        lineHeight: 1.1,
        daySize: `${(baseSpacing * 32) / 7}px`,
        slideDuration: '0.8s',
      },
      heading: {level: '4'}, // level ranges from 1-6
    },

    checkBox: {
      size: `${baseSpacing}px`,
      toggle: {
        radius: `${baseSpacing}px`,
        size: `${baseSpacing * 2}px`,
      },
    },
    clock: {
      analog: {
        // extend: undefined,
        hour: {
          width: `${baseSpacing / 3}px`,
          size: `${baseSpacing}px`,
          shape: 'round',
        },
        minute: {
          width: `${baseSpacing / 6}px`,
          size: `${Math.round(baseSpacing / 2)}px`,
          shape: 'round',
        },
        second: {
          width: `${baseSpacing / 8}px`,
          size: `${Math.round(baseSpacing / 2.666)}px`,
          shape: 'round',
        },
        size: {
          small: `${baseSpacing * 3}px`,
          medium: `${baseSpacing * 4}px`,
          large: `${baseSpacing * 6}px`,
          xlarge: `${baseSpacing * 9}px`,
          huge: `${baseSpacing * 12}px`,
        },
      },
      digital: {
        text: {
          xsmall: {size: `${baseFontSize - 2 * fontScale}px`, height: 1.5},
          small: {size: `${baseFontSize - fontScale}px`, height: 1.43},
          medium: {size: `${baseFontSize}px`, height: 1.375},
          large: {size: `${baseFontSize + fontScale}px`, height: 1.167},
          xlarge: {size: `${baseFontSize + 2 * fontScale}px`, height: 1.1875},
          xxlarge: {size: `${baseFontSize + 4 * fontScale}px`, height: 1.125},
        },
      },
    },
    collapsible: {
      minSpeed: 200,
      baseline: 500,
    },
    heading: {
      font: {
        // family: undefined
      },
      level: {
        1: {
          font: {
            // family: undefined,
            // weight: undefined,
          },
          small: {...fontSizing(2)},
          medium: {...fontSizing(4)},
          large: {...fontSizing(8)},
          xlarge: {...fontSizing(12)},
        },
        2: {
          font: {
            // family: undefined,
            // weight: undefined,
          },
          small: {...fontSizing(1.5)},
          medium: {...fontSizing(2)},
          large: {...fontSizing(4)},
          xlarge: {...fontSizing(6)},
        },
        3: {
          font: {
            // family: undefined,
            // weight: undefined,
          },
          small: {...fontSizing(0)},
          medium: {...fontSizing(1)},
          large: {...fontSizing(2)},
          xlarge: {...fontSizing(4)},
        },
        4: {
          font: {
            // family: undefined,
            // weight: undefined,
          },
          small: {...fontSizing(-1)},
          medium: {...fontSizing(0)},
          large: {...fontSizing(0)},
          xlarge: {...fontSizing(0)},
        },
        5: {
          font: {
            // family: undefined,
            // weight: undefined,
          },
          small: {...fontSizing(-1.5)},
          medium: {...fontSizing(-1)},
          large: {...fontSizing(0)},
          xlarge: {...fontSizing(0)},
        },
        6: {
          font: {
            // family: undefined,
            // weight: undefined,
          },
          small: {...fontSizing(-1.5)},
          medium: {...fontSizing(-1.5)},
          large: {...fontSizing(-1.5)},
          xlarge: {...fontSizing(-1.5)},
        },
      },
      responsiveBreakpoint: 'small', // when we scale the font size down
      weight: 500,
    },
    icon: {
      size: {
        small: fontSizing(-1).size,
        medium: fontSizing(0).size,
        large: fontSizing(1).size,
        xlarge: fontSizing(2).size,
      },
    },
    list: {
      item: {
        //disable responsive paddings
        pad: { horizontal: '1ex', vertical: '1ex' },
      },
    },
    paragraph: {
      small: {...fontSizing(-1)},
      medium: {...fontSizing(0)},
      large: {...fontSizing(1)},
      xlarge: {...fontSizing(2)},
      xxlarge: {...fontSizing(4)},
    },
    radioButton: {
      size: `${baseSpacing}px`,
    },
    text: {
      xsmall: {...fontSizing(-1.5)},
      small: {...fontSizing(-1)},
      medium: {...fontSizing(0)}, // 18px
      large: {...fontSizing(1)}, // 22px
      xlarge: {...fontSizing(2)},
      xxlarge: {...fontSizing(4)},
    },
    textArea: {
      extend: 'max-width: 100%;'
    }
  };

  return deepFreeze(result);
};

export const base = generate();
