import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'

import gql from 'graphql-tag'
import Intent from './Intent'
import {useAuth} from '../../common/auth'
import {toast} from 'react-toastify'

import {
  Form,
  FormField,
  Button,
  Box,
  Heading,
  Tabs,
  Tab
} from 'grommet'
import {withColor} from '../theme'
import {Paper} from '../Paper'

// eslint-disable-next-line
const GET_ME_CARD = gql`
  query Me {
    me {
      passport {
       id, type,
       firstName, lastName,
       employments {id, role, company {id, name}}
      }
    }
  }
`

type IntentVariables = {
  email: string,
  password: string
}

export default class Login extends Intent<IntentVariables> {
  static type = 'app.loginUser'

  get type(): string {
    return Login.type
  }

  asComponent() {
    return () => {
      const {login, register} = useAuth()
      const [busy, setBusy] = React.useState(false)

      const onLogin = async (formData: IntentVariables) => {
        setBusy(true)
        login(formData)
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
              //TODO: pass this to the form error field
              toast.error('Неверный пароль')
            } else if (errorCode === 'auth/user-not-found') {
              toast.error('Неверный email')
            } else {
              toast.error(errorMessage);
            }
            console.log(error)
            setBusy(false)
          })
        //don't need to setBusy(false) after login is performed,
        //since this component will be unmounted sooner
      }

      const onRegister = async (formData: IntentVariables) => {
        setBusy(true)
        register(formData)
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode == 'auth/weak-password') {
              //TODO: pass this to the form error field
              toast.error('Слишком простой пароль')
            } else if (errorCode === 'auth/email-already-in-use') {
              toast.error('Еmail недоступен для регистрации')
            } else {
              toast.error(errorMessage);
            }
            console.log(error)
            setBusy(false)
          })
        //don't need to setBusy(false) after login is performed,
        //since this component will be unmounted sooner
      }

      return (
        <Paper css="width: 100%; max-width: 400px">
          <Tabs>
            <StyledTab title="Вход">
              <LoginForm onSubmit={onLogin} busy={busy} />
            </StyledTab>
            <StyledTab title="Регистрация">
              <RegisterForm onSubmit={onRegister} busy={busy} />
            </StyledTab>
          </Tabs>
        </Paper>
      )
    }
  }
}

const LoginForm = ({onSubmit, busy}) => {
  return (
    <Form onSubmit={({value}: any) => onSubmit(value)}>
      <FormField name="email" required label="email" />
      <FormField name="password" required type="password" label="Пароль" />

      <Box direction="row-reverse" justify="between"
        margin={{top: 'small'}}
      >
        <Button type="submit" disabled={busy} primary label="Войти" />
      </Box>
    </Form >
  )
}

const RegisterForm = ({onSubmit, busy}) => {
  return (
    <Form onSubmit={({value}: any) => onSubmit(value)}>
      <FormField name="email" required label="email" />
      <FormField name="password" required type="password" label="Пароль" />

      <Box direction="row-reverse" justify="between"
        margin={{top: 'small'}}
      >
        <Button type="submit" disabled={busy} primary label="Зарегистрироваться" />
      </Box>
    </Form >
  )
}


const StyledTab = styled(Tab)`
  & > div {
    border-bottom-color: ${(props: any) => props.active
    ? props.theme.getColor('text-primary') : 'transparent'};

    &:hover {
      border-bottom-color: ${withColor('text-primary')};
    }
  }
`
