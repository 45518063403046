import React from 'react';
import _ from 'lodash'
import {useParams} from 'react-router-dom'
import 'styled-components/macro'

import {Paper} from '../components/Paper'
import Pagebar from '../components/Pagebar'
import {List, Box, Text, Button} from 'grommet'
import {FormEdit as EditIcon} from 'grommet-icons'

import {IntentContext} from '../components/intent/IntentContext'
import UpdateJob from '../components/intent/UpdateJob'

import gql from 'graphql-tag'
import {
  useJobCardQuery,
  JobCardQuery,
} from '../generated/graphql'

// eslint-disable-next-line
const GET_JOB_CARD = gql`
  query JobCard($jobId: ID!) {
    listJobs(input: {jobIds: [$jobId]}) {
      jobs {
        id
        name
        qualifications { id, name }
      }
    }
  }
`

export default function Job() {
  const {jobId} = useParams()
  const {onIntent} = React.useContext(IntentContext)
  const {data, loading, error} = useJobCardQuery({
    variables: {jobId}
  })
  if (loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  const job = _.first(data.listJobs.jobs)

  return (
    <article>
      <Box direction="column" gap="medium">

        <Paper>
          <Pagebar title={job.name}
            actions={(<Button
              css="padding: 0 .3em 0 .3em"
              margin={{horizontal: 'xsmall'}}
              hoverIndicator
              icon={<EditIcon />}
              onClick={() => onIntent(new UpdateJob({jobId}))}
            />)}
          />
        </Paper>
        <Paper>
          <Pagebar level="2" title="Квалификации" />
          <JobQualifications job={job} />
        </Paper>
      </Box>
    </article>
  )
}


interface CardProps {
  job: JobCardQuery['listJobs']['jobs'][0],
}
const JobQualifications = ({job}: CardProps) => (
  <List
    data={job.qualifications}
  >
    {(item) => <Text>{item.name}</Text>}
  </List>
)
