import deepFreeze from 'deep-freeze'

import {base} from 'grommet/themes/base'
import {theme as styling} from './theme-styling'
import {generate as generateSpacing} from './theme-grommet-spacing'
import {normalizeColor} from 'grommet/utils'
import {edgeStyle} from 'grommet/utils/styles'

import _ from 'lodash'


// https://github.com/grommet/grommet-theme-hpe/blob/master/src/js/index.js
// https://grommet-theme-builder.netlify.com/
// https://coolors.co/26547c-ef476f-ffd166-06d6a0-fcfcfc

const theme = _.merge(
  {}, base, generateSpacing({baseFont: 16}), styling, {
  "global": {
    edgeSize: withNegative(base.global.edgeSize),
    breakpoints: {
      small: {
        edgeSize: withNegative(base.global.breakpoints.small?.edgeSize)
      }
    }
  },
})

export default deepFreeze({
  ...theme,
  getColor: name => normalizeColor(name, theme),
  getEdge: (kind, edge) => edgeStyle(kind, edge, true, 'small', theme)
})

export const withColor = color => props =>
  props.theme.getColor(color)

export const withEdge = (kind, edge) => props =>
  props.theme.getEdge(kind, edge)


function withNegative(edges) {
  if (!edges) return edges
  const keys = [
    'hair', 'xxsmall', 'xsmall', 'small',
    'medium', 'large', 'xlarge'
  ]
  const negatives = _.zipObject(..._.zip(..._.compact(_.map(keys, key => (
    edges[key] && [`-${key}`, `-${edges[key]}`]
  )))))
  return {...edges, ...negatives}
}
