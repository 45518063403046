import React from 'react'
import _ from 'lodash'
import Anchor from '../components/RoutedAnchor'
import {Box, Text, Button} from 'grommet'
import {AddCircle} from 'grommet-icons'
import 'styled-components/macro'

import {Paper} from '../components/Paper'
import Pagebar from '../components/Pagebar'

import {orgPath} from '../common/url'
import {IntentContext} from '../components/intent/IntentContext'
import CreateJob from '../components/intent/CreateJob'

import gql from 'graphql-tag'
import {
  useListAllJobsQuery,
  ListAllJobsQuery,
} from '../generated/graphql'

// eslint-disable-next-line
const GET_JOBS = gql`
  query ListAllJobs($input: ListJobsInput!) {
    listJobs(input: $input) {
      jobs {
        id
        name
      }
    }
  }
`

export default function Jobs() {
  const {onIntent} = React.useContext(IntentContext)
  const {data, loading, error} = useListAllJobsQuery({
    variables: {input: {}}
  })
  if (loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  return (
    <article>
      <Paper>
        <Pagebar title="Должности"
          actions={(<Button
            css="padding: 0 .3em 0 .3em"
            margin={{horizontal: 'xsmall'}}
            hoverIndicator
            icon={<AddCircle />}
            onClick={() => onIntent(new CreateJob())}
          />)}
        />
        <JobList {...data.listJobs} />
      </Paper>
    </article>
  )
}


const JobList = ({jobs}: ListAllJobsQuery['listJobs']) => (
  <Box direction="column" gap="xxsmall">
    {_.map(jobs, j => (
      <JobEntry key={j.id} job={j} />
    ))}
  </Box>
)

interface JobProps {
  job: ListAllJobsQuery['listJobs']['jobs'][0]
}
const JobEntry = ({job}: JobProps) => (
  <Text>
    <Anchor href={orgPath('/jobs', job.id)}>
      {job.name}
    </Anchor>
  </Text>
)
