import React from 'react'
import _ from 'lodash'
import Anchor from '../components/RoutedAnchor'
import {Box, Text, Button} from 'grommet'
import {AddCircle} from 'grommet-icons'
import 'styled-components/macro'

import Pagebar from '../components/Pagebar'
import {Paper} from '../components/Paper'

import {orgPath} from '../common/url'
import {IntentContext} from '../components/intent/IntentContext'
import CreateTeam from '../components/intent/CreateTeam'

import gql from 'graphql-tag'
import {
  useListAllTeamsQuery,
  ListAllTeamsQuery,
} from '../generated/graphql'

// eslint-disable-next-line
const GET_TEAMS = gql`
  query ListAllTeams {
    listTeams(input: {}) {
      teams {
        id
        name
      }
    }
  }
`

export default function Teams() {
  const {onIntent} = React.useContext(IntentContext)
  const {data, loading, error} = useListAllTeamsQuery()
  if (loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  return (
    <article>
      <Paper>
        <Pagebar title="Команды"
          actions={(<Button
            css="padding: 0 .3em 0 .3em"
            margin={{horizontal: 'xsmall'}}
            hoverIndicator
            icon={<AddCircle />}
            onClick={() => onIntent(new CreateTeam())}
          />)}
        />
        <TeamList {...data.listTeams} />
      </Paper>
    </article>
  )
}


const TeamList = ({teams}: ListAllTeamsQuery['listTeams']) => (
  <Box direction="column" gap="xxsmall">
    {_.map(teams, t => (
      <TeamEntry key={t.id} team={t} />
    ))}
  </Box>
)

interface TeamProps {
  team: ListAllTeamsQuery['listTeams']['teams'][0]
}
const TeamEntry = ({team}: TeamProps) => (
  <Text>
    <Anchor href={orgPath('/teams', team.id)}>
      {team.name}
    </Anchor>
  </Text>
)
