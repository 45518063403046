import React from 'react'
import {Box, Text, Paragraph} from 'grommet'
import RoutedButton from './RoutedButton'
import 'styled-components/macro'

import {orgPath, currentOrgId} from '../common/url'

export const AppNavBar = ({onSelect}) => {
  const items = currentOrgId() ? [
    {label: 'Смены', href: orgPath('/schedule')},
    {label: 'Сотрудники', href: orgPath('/employees')},
    {label: 'Команды', href: orgPath('/teams')},
    {label: 'Должности', href: orgPath('/jobs')},
  ] : []
  return <NavBar items={items} onSelect={onSelect}/>
}

export const AdminNavBar = ({onSelect}) => {
  const items = [
    {label: 'Организации', href: '/companies'},
    {label: 'Паспорта', href: '/passports'},
  ]
  return <NavBar items={items} onSelect={onSelect}/>
}

function NavBar({items, onSelect}) {
  return (
    <Box direction="column" justify="between" fill="vertical">
      <Box direction="column">
        {items.map((link, i) => (
          <RoutedButton
            key={i}
            href={link.href}
            onClick={() => onSelect(link)}
            hoverIndicator
            css="font-variant: none; letter-spacing: unset;"
          >
            <Box pad={{horizontal: 'medium', vertical: 'small'}}>
              <Text>{link.label}</Text>
            </Box>
          </RoutedButton>
        ))}
      </Box>
      <Box pad="medium"
        css="position: absolute; bottom: 0; left: 0;"
      >
        <Paragraph
          css="font-size: .65rem; line-height: 1.3;"
          color="grey-500"
        >
          <span css="font-variant: all-petite-caps">
            По любым вопросам:
        </span><br />
        Алексей Масленников<br />
          <b>mail.maslennikov@gmail.com</b>
        </Paragraph>
      </Box>
    </Box>
  )
}
