import React from 'react'
import _ from 'lodash'
import {Text} from 'grommet'
import styled from 'styled-components/macro'


export const JobLabel = ({job}) => (
  <Text
    size="small"
    css={`
      font-variant: all-small-caps;
      line-height: 1;
      padding: 0 .2em 0 .2em;
      font-weight: normal;
      background-color: #777;
      color: white;
      letter-spacing: 0.02em; `}
  >
    {_.truncate(job.name, {length: 10})}
  </Text>
)
