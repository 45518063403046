import React from 'react'
import _ from 'lodash'
import M from '../../common/moment'
import Intent from './Intent'

import {
  Form, FormField, Button, Heading, Box
} from 'grommet'

import {Paper} from '../Paper'
import {TimePicker} from '../TimePicker'
import {toSeconds} from '../../common/timeUtils'

import gql from 'graphql-tag'
import {
  useCreateShiftTypeMutation,
  useTeamCardQuery,
  CreateShiftTypeInput,
  TeamCardDocument,
} from '../../generated/graphql'

// eslint-disable-next-line
const CREATE_SHIFT_TYPE = gql`
  mutation CreateShiftType($input: CreateShiftTypeInput!) {
    createShiftType(input: $input) {
      shiftType {
        id, name, startTime, duration, isDeleted
      }
    }
  }
`

type IntentVariables = Pick<CreateShiftTypeInput, 'teamId'>

export default class CreateShiftType extends Intent<IntentVariables> {
  static type = 'team.create_shift_type'

  get type(): string {
    return CreateShiftType.type
  }

  asComponent() {
    return () => {
      const [mutate, {data, loading, error}] = useCreateShiftTypeMutation({
        refetchQueries: [TeamCardDocument.definitions[0].name.value]
      })

      const submit = (data: CreateShiftTypeInput) => {
        mutate({
          variables: {input: data}
        }).then(({data}) => {
          console.log('FINISHED', data)
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      if (data) return <p>ГОТОВО</p>

      return (
        <CreateShiftTypeForm onSubmit={submit} {...this.initialProps} />
      )
    }
  }
}

const CreateShiftTypeForm = ({onSubmit, teamId}) => {
  const {data, loading, error} = useTeamCardQuery({
    variables: {teamId}
  })

  const team = data?.listTeams.teams[0]
  const shiftTypes = _.map(team?.shiftTypes, st => _.toLower(st.name))

  const [changes, setChanges] = React.useState(null)
  const fields = changes || {
    name: '', startTime: null, endTime: null
  }

  return team ? (

    <Form value={fields}
      // form.onChange has a bug sometimes leaking the native event
      // into onChange (e.g. upon key press on input)
      // https://github.com/grommet/grommet/issues/3356
      onChange={v => {v.preventDefault ?? setChanges({
        ...v, name: v.name.toLowerCase()
      })}}
      onSubmit={({value}: any) => onSubmit({
        teamId,
        name: value.name,
        // saving this as GMT
        startTime: value.startTime.format('HH:mm:ss') + 'Z',
        duration: toSeconds(value.startTime, value.endTime)
      })}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Новый тип смены
      </Heading>
        <FormField name="name" required label="Название"
          validate={val => {
            return _.includes(shiftTypes, _.toLower(val))
              ? 'В команде уже есть смена с таким названием'
              : null
          }}
        />
        <Box direction="row" gap="small">
          <FormField name="startTime" required label="С"
            component={TimePicker}
            validate={(val, fields) => {
              return (val?.isSame(fields.endTime))
                ? 'Значения не должны совпадать'
                : null
            }}
          />
          <FormField name="endTime" required label="По"
            component={TimePicker}
            validate={(val, fields) => {
              return (val?.isSame(fields.startTime))
                ? 'Значения не должны совпадать'
                : null
            }}
          />
        </Box>

        <Button type="submit" primary label="Сохранить"
        alignSelf="end" margin={{top: 'small'}} />
      </Paper>

    </Form >
  ) : <i>ЗАГРУЗКА</i>
}
