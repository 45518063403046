import React from 'react'
import {Box} from 'grommet'
import {colorIsDark} from "grommet/utils/colors"
import {rgba} from 'polished'
import 'styled-components/macro'

export const ColoredBox = ({
  color = '', backgroundOpacity = 1, children, ...props
}: React.ComponentProps<typeof Box> & {
  color?: string, backgroundOpacity?: number
}) => {

  const foreground = (color && colorIsDark(color) && color) || 'unset'
  const background = (
    color && !colorIsDark(color) && rgba(color, backgroundOpacity)
  ) || ''

  return (
    <Box
      background={background}
      css={`color: ${foreground}`}
      {...props}
    >
      {children}
    </Box>
  )
}
