import React from 'react'
import _ from 'lodash'
import styled, {css} from 'styled-components/macro'
import M from '../../common/moment'
import Intent from './Intent'


import {
  Grid, List, Text, Heading, Select, Box,
} from 'grommet'
import Pagebar from '../Pagebar'
import {TabbedPagebar} from '../TabbedPagebar'
import {JobLabel} from '../JobLabel'
import {ShiftTimeLabel} from '../ShiftTimeLabel'
import {Paper} from '../Paper'
import {shiftDuration} from '../../common/timeUtils'

import gql from 'graphql-tag'
import {
  useAssignShiftMutation,
  useCancelShiftMutation,

  useEmployeeCardQuery,
  useTeamCardQuery,
  useListEmployeeShiftsQuery,
  useListShiftTeammatesQuery,
  useListAllJobsQuery,

  AssignShiftInput,
  CancelShiftInput,
  ListScheduleDocument,
  ListShiftTeammatesQuery
} from '../../generated/graphql'

// eslint-disable-next-line
const ASSIGN_SHIFT = gql`
  mutation AssignShift($input: AssignShiftInput!) {
    assignShift(input: $input) {
      plan {id}
    }
  }
`
// eslint-disable-next-line
const CANCEL_SHIFT = gql`
  mutation CancelShift($input: CancelShiftInput!) {
    cancelShift(input: $input) {
      deletedIds
    }
  }
`
// eslint-disable-next-line
const GET_EMPLOYEE_SHIFTS = gql`
  query ListEmployeeShifts(
    $employeeId: ID!, $date: Date!,
    $weekStart: Date!, $weekEnd: Date!
    ) {
    ownShifts: listSchedule(input: {
        employeeIds: [$employeeId], from: $date, to: $date
    }) {
      plans {
        id
        team {id, name}
        shiftType {id, name}
        job {id, name}
        start
        end
      }
    }
    weekShifts: listSchedule(input: {
        employeeIds: [$employeeId], from: $weekStart, to: $weekEnd
    }) {
      plans {
        id
        shiftType {id, duration}
        job {id, name}
      }
    }
  }
`

const GET_TEAMMATES = gql`
  query ListShiftTeammates($teamIds: [ID!], $date: Date!) {
    listSchedule(input: {
        teamIds: $teamIds, from: $date, to: $date
    }) {
      plans {
        id
        employee {id, passport {id, firstName, lastName}}
        team {id, name}
        shiftType {id, name}
        job {id, name}
        start
        end
      }
    }
  }
`

type IntentVariables = Omit<AssignShiftInput, 'shiftTypeId'>
  & {teamId: string}

type MutationVariables = Pick<AssignShiftInput, 'shiftTypeId'>
  & CancelShiftInput

export default class AssignShift extends Intent<IntentVariables> {
  static type = 'schedule.assign_shift'

  constructor(...args) {
    super(...args)
    this.initialProps.date = M(this.initialProps.date)
      .format('YYYY-MM-DD')
  }

  get type(): string {
    return AssignShift.type
  }

  asComponent() {
    return () => {
      const [assignShift] = useAssignShiftMutation()
      const [cancelShift] = useCancelShiftMutation()

      const change = (data: MutationVariables) => {
        // array of string query names will trigger refetch of all
        // recent queries from the list with latest used variables
        const refetchQueries = [
          ListScheduleDocument.definitions[0].name.value,
          GET_EMPLOYEE_SHIFTS.definitions[0].name.value
        ]

        const mutation: any = data.shiftTypeId
          ? assignShift({
            variables: {
              input: {
                ..._.omit(this.initialProps, 'teamId'),
                ..._.pick(data, 'shiftTypeId')
              }
            },
            refetchQueries
          })
          : cancelShift({
            variables: {input: _.pick(data, 'schedulePlanId')},
            refetchQueries
          })


        mutation.then(({data}) => {
          console.log('FINISHED', data)
          // return navigate(`/employee/${data.enlistEmployee.employee.id}`)
        })
      }

      // if (loading) return <p>РАБОТАЮ...</p>;
      // if (error) return <p>ОШИБКА</p>;
      // if (data) return <p>ГОТОВО</p>

      return (
        <IntentForm onSubmit={change} {...this.initialProps} />
      )
    }
  }
}

const IntentForm = ({onSubmit, employeeId, teamId, date, jobId}: {
  onSubmit: (data: MutationVariables) => any
} & IntentVariables) => {

  const employeeQ = useEmployeeCardQuery({
    variables: {employeeId: employeeId}
  })
  const teamQ = useTeamCardQuery({
    variables: {teamId}
  })
  const jobsQ = useListAllJobsQuery({
    variables: {input: {jobIds: [jobId]}}
  })
  const shiftsQ = useListEmployeeShiftsQuery({
    variables: {
      employeeId, date,
      weekStart: M(date).startOf('isoWeek').format('YYYY-MM-DD'),
      weekEnd: M(date).endOf('isoWeek').format('YYYY-MM-DD'),
    }
  })

  if (_.find([employeeQ, teamQ, jobsQ, shiftsQ], 'loading')) {
    return <b>ЗАГРУЗКА</b>
  }
  if (_.find([employeeQ, teamQ, jobsQ, shiftsQ], 'error')) {
    return <b>ОШИБКА</b>
  }

  const employee = employeeQ.data.listEmployees.employees[0]
  const team = teamQ.data.listTeams.teams[0]
  const job = jobsQ.data.listJobs.jobs[0]
  const shiftTypes = _.sortBy(team?.shiftTypes, 'startTime')
  const noShiftType = {id: '', name: 'выходной'}
  const [[shift], ownShifts] = _.partition(
    _.map(shiftsQ.data.ownShifts.plans, s => ({...s, employee, date})),
    s => s.job.id == jobId && s.team.id == teamId)

  const shiftsThisDay = shiftsQ.data.ownShifts.plans
  const shiftsThisWeek = shiftsQ.data.weekShifts.plans

  return (
    <Box gap="1.5ex">
      <Paper gap="xsmall">
        <Box>
          <Heading
            size="small" level={2} truncate margin="none"
            css="line-height: 1.2;"
          >
            {employee.passport.firstName} {employee.passport.lastName}
          </Heading>
          <Text
            color="secondary-dark"
            size="small"
            css={`
          font-variant: all-small-caps;
          line-height: 1.7;
          letter-spacing: 0.02em
          `}
          >
            {M(date).format('dd DD MMM')}
          </Text>
        </Box>

        <Select
          dropHeight="medium"
          size="small"
          options={[noShiftType, ...shiftTypes]}
          valueKey="id"
          labelKey={option => option.name.toLowerCase()}
          value={shift?.shiftType || ''}
          onChange={({value}) => onSubmit({
            schedulePlanId: shift?.id,
            shiftTypeId: value.id
          })}
          valueLabel={<ShiftLabel
            shiftType={shift?.shiftType}
            team={team}
            job={job}
            placeholder={noShiftType.name}
            start={shift?.start}
            end={shift?.end}
          />}
        />
      </Paper>


      {shift && (<TeammatesWidget {...shift} />)}



      {!!ownShifts.length && (<Paper>
        <Pagebar
          title={`${employee.passport.firstName} в этот же день`}
          level="4"
        />
        <List data={ownShifts}>
          {(item: typeof ownShifts[0]) => (
            <ShiftLabel
              shiftType={item.shiftType}
              team={item.team}
              job={item.job}
              start={item.start}
              end={item.end}
              placeholder={noShiftType.name}
            />
          )}
        </List>
      </Paper>)}

      <Paper>
        <TabbedPagebar
          data={[
            {
              title: 'Часы за день', content: (
                <HoursBreakdown shifts={shiftsThisDay} />)
            },
            {
              title: 'за неделю', content: (
                <HoursBreakdown shifts={shiftsThisWeek} />)
            },
          ]} />

      </Paper >
    </Box >
  )

}

// type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
// type MakeOptional<T, K extends keyof T>
//   = Omit<T, K> & Pick<Partial<T>, K>;

type TeammateProps =
  ListShiftTeammatesQuery['listSchedule']['plans'][0] & {
    [key: string]: any
  }

const TeammatesWidget = ({date, team, employee, start, end}: TeammateProps) => {
  const [changes, setChanges] = React.useState(null)
  const teammatesQ = useListShiftTeammatesQuery({
    variables: {date: date, teamIds: null}
  })

  const filterOptions = [
    {id: 0, label: 'в команде', teamId: team.id},
    {id: 1, label: 'везде', teamId: null}
  ]
  const filter = changes ?? filterOptions[0]
  React.useEffect(() => setChanges(null), [team.id])

  const allTeammates = _.filter(
    teammatesQ.data?.listSchedule.plans, s => (
      s.employee.id != employee.id
      && M(start).isBefore(s.end)
      && M(end).isAfter(s.start)))

  const teammates = _.filter(allTeammates, s => (
    !filter.teamId || (s.team.id == filter.teamId)))

  return !!allTeammates.length && (<Paper>
    <Pagebar
      title="В это же время"
      level="4"
      actions={<Select
        plain
        size="small"
        value={filter}
        options={filterOptions}
        onChange={({value}) => setChanges(value)}
        labelKey="label"
        valueKey="id"
        valueLabel={
          <Text margin="0" size="small">
            {filter.label}
          </Text>
        }
      />}
    />
    {teammates.length ? <TeammatesList teammates={teammates} /> : (
      <Text color="text-secondary" size="small">
        Больше нет смен
      </Text>
    )}
  </Paper>)
}

const TeammatesList = ({teammates}) => {
  const byTeam = _.groupBy(teammates, 'team.id')

  return (
    <>
      {_.map(byTeam, (shifts, k) => (
        <React.Fragment key={k}>
          <Text color="primary-dark" margin="0" size="small">
            {shifts[0].team.name}
          </Text>
          <List data={shifts}>
            {(item: typeof teammates[0]) => (
              <ShiftLabel
                shiftType={item.shiftType}
                employee={item.employee}
                job={item.job}
                start={item.start}
                end={item.end}
              />
            )}
          </List>
        </React.Fragment>
      ))}
    </>)
}

const HoursBreakdown = ({shifts}) => {
  const byJob = _.groupBy(shifts, 'job.id')

  return (
    <Grid columns={['auto', 'flex']}>
      {_.map(byJob, (shifts, j) => (
        <React.Fragment key={j}>
          <Text size="small" margin={{right: 'small'}}>
            {shifts[0].job.name}
          </Text>
          <Text size="small">
            {shiftDuration(shifts)} ч.
          </Text>
        </React.Fragment>
      ))}
    </Grid>
  )
}

const ShiftLabel = ({
  placeholder = 'нет смены',
  date = null,
  team = null,
  employee = null,
  job = null,
  start = null,
  end = null,
  shiftType,
  ...props
}) => {
  return (
    <Box pad="xsmall" {...props}>
      <Box direction="row" gap=".4em"
        css={`
          font-variant: all-small-caps;
          line-height: 1;
          & > span {line-height: inherit;}
        `}
      >
        {date && <Text size="small" color="secondary-dark">
          {M(date).format('dd DD MMM')}
        </Text>}
        {team && <Text size="small" color="primary-dark">
          {_.truncate(team.name, {length: 25})}
        </Text>}
        {employee && <Text size="small">
          {employee.passport.lastName} {employee.passport.firstName[0]}.
        </Text>}
        {job && <JobLabel job={job} />}
      </Box>
      <Box direction="row">
        <Text
          size="small"
          css="font-variant: all-small-caps"
          color={shiftType ? '' : 'placeholder'}
        >
          <span css={`
            font-weight: ${shiftType ? '500' : 'normal'};
            letter-spacing: 0.02em;
          `}>
            {shiftType?.name || placeholder}
          </span>
          <ShiftTimeLabel {...{start, end, shiftType}}
            css="margin-left: .5em;"
          />
        </Text>
      </Box>
    </Box>
  )
}
