import React from 'react'
import {Button} from 'grommet'
import {AddCircle} from 'grommet-icons'
import 'styled-components/macro'

import Pagebar from '../components/Pagebar'
import {Paper} from '../components/Paper'
import {EmployeeList} from '../components/EmployeeList'

import {useHistory} from 'react-router-dom'
import {orgPath} from '../common/url'
import {IntentContext} from '../components/intent/IntentContext'
import EnlistEmployee from '../components/intent/EnlistEmployee'

import gql from 'graphql-tag'
import {
  useListAllEmployeesQuery,
} from '../generated/graphql'

// eslint-disable-next-line
const GET_EMPLOYEES = gql`
  query ListAllEmployees {
    listEmployees(input: {}) {
      employees {
        id
        passport {id, firstName, lastName}
      }
    }
  }
`

export default function Employees() {
  const {onIntent} = React.useContext(IntentContext)
  const history = useHistory()
  const {data, loading, error} = useListAllEmployeesQuery()

  const onAddEmployee = () => onIntent(
    new EnlistEmployee(
      null, {
      then: ({id}) =>
        history.push(orgPath('/employees', id))
    }))

  if (loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  return (
    <article>
      <Paper>
        <Pagebar
          title="Сотрудники"
          actions={(<Button
            css="padding: 0 .3em 0 .3em"
            margin={{horizontal: 'xsmall'}}
            hoverIndicator
            icon={<AddCircle />}
            onClick={onAddEmployee}
          />)}
        />
        <EmployeeList {...data.listEmployees} />
      </Paper>
    </article>
  )
}
