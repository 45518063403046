import React from 'react'
import M from '../common/moment'
import {MaskedInput, MaskedInputProps} from "grommet";

export const TimePicker = ({
  value, onChange, onBlur, ...props
}: MaskedInputProps & {value: M.Moment, onChange: any}) => {

  const labelFormat=  'HH:mm'
  const label = value ? value.format('H:mm') : ''

  const [changes, setChanges] = React.useState('')
  const field = changes || label

  const onType = text => {
    const handler = v => (onChange || (() => {}))({
      value: v, target: {value: v}
    })
    setChanges(text)

    const time = M.utc(text, 'H:mm', true)
    if (time.isValid()) {
      handler(time)
    }
  }

  React.useEffect(() => setChanges(''), [value])

  return (
    <MaskedInput
      {...props}
      mask={[
        {
          length: [1, 2],
          regexp: /^2[0-3]$|^1[0-9]$|^0?[0-9]$/,
          placeholder: "чч"
        },
        {fixed: ":"},
        {
          length: 2,
          regexp: /^[0-5][0-9]$|^[0-9]$/,
          placeholder: "мм"
        }
      ]}
      value={field}
      onChange={e => onType(e.target.value)}
      onBlur={e => {setChanges(''); onBlur && onBlur(e)}}
    />
  );
};
