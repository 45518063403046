import React from 'react'
import _ from 'lodash'
import Anchor from '../components/RoutedAnchor'
import {Box, Text} from 'grommet'
import {ListAllEmployeesQuery} from '../generated/graphql'
import {orgPath} from '../common/url'


export const EmployeeList = (
  {employees}: ListAllEmployeesQuery['listEmployees']
) => (
    <Box direction="column" gap="xxsmall">
      {_.map(employees, e => (
        <EmployeeEntry key={e.id} {...e} />
      ))}
    </Box>
  )

type EmployeeProps = ListAllEmployeesQuery["listEmployees"]["employees"][0]

const EmployeeEntry = ({id, passport}: EmployeeProps) => (
  <Text>
    <Anchor href={orgPath('/employees', id)}>
      {passport.firstName} {passport.lastName}
    </Anchor>
  </Text>
)
