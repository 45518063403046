import React from 'react'
import 'styled-components/macro'
import _ from 'lodash'
import {useLocation} from 'react-router-dom'
import Intent from './intent/Intent'
import {IntentContext, IntentContextValue} from './intent/IntentContext'

import {useBreakpointsContext} from './BreakpointsContext'
import {Box, Grid} from 'grommet'
import theme from '../components/theme'

import {currentMode} from '../common/url'
import {Sidebar} from './Sidebar'
import {AppNavBar, AdminNavBar} from './NavBar'
import {ChatBar} from './ChatBar'
import {AppBar} from './AppBar'


const gridGap = '10px';

// holy grid layout: https://codepen.io/QE4ever/pen/Rjmpjm
const AppGrid = ({children}) => {
  const {screenAbove} = useBreakpointsContext()

  return (<Grid
    fill //grommet complains if not set with flex rows and cols
    // if we want right col to scroll along
    // css="height: auto; min-height: 100vh;"

    // we don't set it here because we want to leave fixed outer gaps
    // when sidebars get collapsed or shown
    // gap={gridGap}
    css={`padding: ${screenAbove('xsmall') ? gridGap : 0}`}
    // header is rendered outside
    // rows={['auto', 'flex', 'auto']}
    rows={['flex', 'auto']}
    columns={['auto', 'flex', 'auto']}
    areas={[
      ['navbar', 'main', 'sidebar'],
      ['footer', 'footer', 'footer'],
    ]}
  >
    {children}
  </Grid>)
}


export default function Layout({children}) {
  const {screenAbove} = useBreakpointsContext()

  const [navbarOpen, setNavbarOpen] = React.useState(null)
  const [chatbarOpen, setChatbarOpen] = React.useState(null)

  const showNavbar = navbarOpen ?? screenAbove('small')
  // const showChatbar = chatbarOpen ?? screenAbove('xsmall')
  const showChatbar = chatbarOpen ?? false

  const onToggleNavbar = React.useCallback(show => {
    setNavbarOpen(show ?? !showNavbar)
  }, [showNavbar, setNavbarOpen])

  const onToggleChatbar = React.useCallback(show => {
    setChatbarOpen(show ?? !showChatbar)
  }, [showChatbar, setChatbarOpen])


  //TODO: move url and intent tracking logic into a higher component
  // because layout can change depending on matched route
  const [intent, onIntent] = React.useState<Intent<any>>(null)
  const [intentContextValue] = React.useState<IntentContextValue>(
    new IntentContextValue(onIntent)
  )
  const location = useLocation()
  React.useEffect(() => {
    // this will happen when during this render cycle, before it was complete, some child element triggered onIntent()
    // parent useEffect() always triggers after all children were complete, so parent's state variables were not modified yet but intent context already got new value
    if (intent != intentContextValue.intent) return

    // resetting intent upon any navigation (search queries ignored)
    // if no children wanted to display their own intent during render
    onIntent(null)
  }, [location.pathname])

  React.useEffect(() => {
    onToggleChatbar(!!intent)
  }, [intent])


  return (
    <IntentContext.Provider value={{
      onIntent: intentContextValue.setIntent
    }}>
      <Box fill overflow="hidden">
        <AppBar
          flex={false}
          onToggleNavbar={onToggleNavbar}
          onToggleChatbar={onToggleChatbar}
          css={`
            height: ${theme.header.height};`}
        />
        <Box flex overflow="auto">
          <AppGrid>
            <Sidebar
              gridArea="navbar"
              show={showNavbar}
              onToggleShow={onToggleNavbar}
            >
              <NavBar onSelect={screenAbove('xsmall')
                ? () => {} : () => setNavbarOpen(false)} />
            </Sidebar>

            <Sidebar
              gridArea="sidebar"
              width="280px"
              show={showChatbar}
              onToggleShow={onToggleChatbar}
            >
              {/* <div css="/*overflow-y: auto;* / overflow-x: hidden;" > */}
              <ChatBar intent={intent} />
              {/* </div> */}
            </Sidebar>

            <Box gridArea="main" tag="main" fill
              css={`
                padding-top: 0; padding-bottom: 0;
                padding-left: ${showNavbar ? gridGap : 0};
                padding-right: ${showChatbar ? gridGap : 0};
              `}
            >
              {children}
            </Box>

          </AppGrid>
        </Box>
      </Box>
    </IntentContext.Provider>
  )
}

function NavBar({onSelect}) {
  return currentMode() == 'admin'
    ? <AdminNavBar onSelect={onSelect} />
    : <AppNavBar onSelect={onSelect} />
}
