import React from "react"
import {useHistory} from 'react-router-dom'
import {Anchor, AnchorProps} from "grommet"

type PropsT = AnchorProps & Omit<JSX.IntrinsicElements['a'], 'color'>


// @see https://codesandbox.io/s/216vvpmlqj as a reference implementation
const RoutedAnchor = ({href, children, ...props}: PropsT) => {
  const history = useHistory()
  return (
    <Anchor
      {...props}
      href={href}
      onClick={e => {
        e.preventDefault()
        // this will cause link clicks not be caught by parent's onClick()
        e.stopPropagation()
        history.push(href)
      }}>
      {children}
    </Anchor>
  )
}

export default RoutedAnchor
