import React from 'react'
import {Box, Button, Collapsible, Layer} from 'grommet'
import {FormClose} from 'grommet-icons'
import {useBreakpointsContext} from './BreakpointsContext'


export const Sidebar = (
  {width = 'small', show, onToggleShow, children, ...props}
) => {
  const {screenAbove} = useBreakpointsContext()
  const boxprops = {
    ...props
  }

  if (!show) return null

  return screenAbove('xsmall') ? (
    // collapsible messes with position fixed
    // <Collapsible direction='horizontal' open={show}>
      <Box flex width={width}
        // elevation='small'
        {...boxprops}>
        {children}
      </Box>
    // </Collapsible>
  ) : (
      <Layer>
        <Box background="light-2" tag="header"
          align="center" direction="row">
          <Button
            icon={<FormClose />}
            onClick={() => onToggleShow(false)} />
        </Box>
        <Box fill {...boxprops}>
          {children}
        </Box>
      </Layer>
    )
}
