import React from 'react'
import _ from 'lodash'
import {toast} from 'react-toastify'
import Intent from './Intent'

import {
  Form, FormField, Button, Heading, Box
} from 'grommet'

import {Paper} from '../Paper'

import gql from 'graphql-tag'
import {
  useUpdateJobCardMutation,
  useJobCardQuery,
  JobCardDocument,
  UpdateJobInput,
} from '../../generated/graphql'

// eslint-disable-next-line
const UPDATE_JOB = gql`
  mutation UpdateJobCard($input: UpdateJobInput!) {
    updateJobCard(input: $input) {
      job {
        id, name
        qualifications { id, name }
      }
    }
  }
`

type IntentVariables = Pick<UpdateJobInput, 'jobId'>

export default class UpdateJob extends Intent<IntentVariables> {
  static type = 'jobs.update_card'

  get type(): string {
    return UpdateJob.type
  }

  asComponent() {
    return () => {
      const [mutate, {data, loading, error}] =
        useUpdateJobCardMutation({
          refetchQueries: [JobCardDocument.definitions[0].name.value]
        })

      const submit = (data: UpdateJobInput) => {
        mutate({
          variables: {input: data}
        }).then(({data}) => {
          console.log('FINISHED', data)
          toast.success('Изменения сохранены')
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      // if (data) return <p>ГОТОВО</p>

      return (
        <UpdateJobForm onSubmit={submit} {...this.initialProps} />
      )
    }
  }
}

const UpdateJobForm = ({onSubmit, jobId}) => {
  const {data, loading, error} = useJobCardQuery({
    variables: {jobId}
  })

  const [changes, setChanges] = React.useState(null)
  const job = data?.listJobs.jobs[0]
  const fields = changes || job

  return job ? (
    <Form value={fields}
      onChange={v => {v.preventDefault ?? setChanges(v)}}
      onSubmit={({value}: any) => onSubmit({
        jobId,
        ..._.pick(value, 'name'),
      })}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Карточка должности
        </Heading>
        <FormField name="name" required label="Название" />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary disabled={!changes}
            label="Сохранить" />
        </Box>
      </Paper>
    </Form >
  ) : <i>'ЗАГРУЗКА'</i>
}
