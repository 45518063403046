import React from 'react'
import _ from 'lodash'

import {ApolloClient} from 'apollo-client'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {createHttpLink} from 'apollo-link-http'
import {setContext} from "apollo-link-context"
import {ApolloProvider} from '@apollo/react-hooks'

import {resolvers, typeDefs, initialData} from './schema'
import {apiUrl, currentOrgId} from './common/url'
import Pages from './pages'

import {
  config as firebaseConfig,
  FirebaseContext, FirebaseContextValue
} from './firebase'

const firebaseContext = new FirebaseContextValue(firebaseConfig)


// auth middleware
// pull login token from localStorage every time a request is sent
// @see https://www.apollographql.com/docs/react/networking/authentication/
// @see https://www.apollographql.com/docs/react/networking/network-layer/
//
// TODO create error handler in the same fashion
// to deal with unauthorized responses (like accessing wrong org slug)
const authLink = setContext(async (req, {headers}) => {
  const user = firebaseContext.auth.currentUser
  const token = user && await user.getIdToken()
  // return the headers to the context so httpLink can read them
  return {
    // we use _defaults because an override may be passed from inside app
    headers: _.defaults({}, headers, {
      // this will be fetched from cognito library
      authorization: token || '',
      'smennik-company': currentOrgId(),
    })
  }
})


const httpLink = createHttpLink({
  uri: apiUrl(),
  headers: {
    // 'client-name': 'Space Explorer [web]',
    // 'client-version': '1.0.0',
  }
})
const cache = new InMemoryCache()

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  resolvers,
  typeDefs
})

// always assume that there could be UNDEFINED cache
// cache gets populated: on logins or on app init
// cache gets killed: on user logout
// @see ./common/login.ts
const resetCache = async () => cache.writeData({
  data: {
    ...initialData,
  },
})

client.onResetStore(resetCache)
resetCache()

const App = () => {
  return (
    <ApolloProvider client={client}>
      <FirebaseContext.Provider value={firebaseContext}>
        <Pages />
      </FirebaseContext.Provider>
    </ApolloProvider>
  )
}

export default App
