import _ from 'lodash'
import M from './moment'

export function daysArray(
  from: M.MomentInput, to: M.MomentInput
): M.Moment[] {
  // trusting the reducer to have `from` and `to` in a correct order
  const days: M.Moment[] = []
  for (let i = M(from).startOf('d');
    i.isSameOrBefore(M(to).startOf('d'));
    i = i.add(1, 'd')) {
    days.push(M(i))
  }
  return days
}

export const formatTime = (
  time, format: 'full' | 'short' | 'shortest' = 'full'
) => {
  const m = M.utc(time, 'HH:mm:ss')
  const hours = m.format(format == 'full' ? 'HH' : 'H')
  const minutes = m.format('mm')

  return (format == 'shortest' && minutes == '00')
    ? hours : `${hours}:${minutes}`
}

export const shiftDuration = (...shifts) => {
  const duration = shift => shift.start
    ? M(shift.end).diff(shift.start)
    : shift.shiftType?.duration * 1000 //mseconds

  const mseconds = _.flatten(shifts)
    .reduce((a, s) => a + duration(s), 0)

  return _.round(M.duration(mseconds).as('hours'), 1)
}

export const shiftEnd = ({startTime, duration}) =>
  M.utc(startTime, 'HH:mm:ss').add(duration, 's')

export const toSeconds = (
  startTime: string | M.Moment, endTime: string | M.Moment
) => {
  const start = M.utc(startTime, "HH:mm")
  const end = M.utc(endTime, "HH:mm")
  // account for crossing over to midnight the next day
  if (end.isBefore(start)) end.add(1, 'day')

  return end.diff(start) / 1000
}
