import React from 'react'
import styled from 'styled-components/macro'
import {ToastContainer, Slide} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {withColor} from './theme'

export const Toastbar = styled(ToastContainer).attrs({
  position: 'bottom-left',
  autoClose: 4000,
  hideProgressBar: true,
  closeButton: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  // draggable: true
  pauseOnHover: true,
  transition: Slide
})`
  .Toastify__toast-container {}
  .Toastify__toast {
    min-height: unset;
    border-radius: 2px;
    font-size: .86rem;
  }
  .Toastify__toast--error {
    background-color: ${withColor('status-critical')}
  }
  .Toastify__toast--warning {
    background-color: ${withColor('status-warning')}
  }
  .Toastify__toast--info {}
  .Toastify__toast--success {
    background-color: ${withColor('status-ok')}
  }
  .Toastify__toast-body {}
  .Toastify__progress-bar {}
`;
