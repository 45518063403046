import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'

import gql from 'graphql-tag'
import Intent from './Intent'
import {toast} from 'react-toastify'

import {
  Form,
  FormField,
  Button,
  Box,
  Heading,
  Tabs,
  Tab
} from 'grommet'
import {withColor} from '../theme'
import {Paper} from '../Paper'

import {
  useRegisterPassportMutation,
  CreatePassportInput,
  MeDocument
} from '../../generated/graphql'

// eslint-disable-next-line
const REGISTER_PASSPORT = gql`
  mutation RegisterPassport($input: CreatePassportInput!) {
    registerPassport(input: $input) {
      passport {id}
    }
  }
`

type IntentVariables = CreatePassportInput

export default class Login extends Intent<IntentVariables> {
  static type = 'user.completePassport'

  get type(): string {
    return Login.type
  }

  asComponent() {
    return () => {
      const [mutate, {loading}] = useRegisterPassportMutation({
        errorPolicy: 'all'
      })

      const submit = async (formData: IntentVariables) => {
        mutate({
          variables: {input: formData},
          refetchQueries: [
            MeDocument.definitions[0].name.value,
          ]
        }).catch(e => {
          toast.error(e.message)
          console.error(e.message)
        })
      }

      return <IntentForm onSubmit={submit} busy={loading} />
    }
  }
}

const IntentForm = ({onSubmit, busy}) => {
  return (
    <Form css="width: 100%; max-width: 400px" onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Как к тебе обращаться?
        </Heading>
        <FormField name="firstName" required label="Имя" />
        <FormField name="lastName" required label="Фамилия" />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary label="Сохранить" />
        </Box>
      </Paper>
    </Form >
  )
}
