
type IntentOptions = {
  then?: any, catch?: any, finally?: any
}

export default abstract class Intent<T extends IntentProps> {
  protected initialProps: T
  protected options: IntentOptions

  constructor(initialProps?: T, options?: IntentOptions) {
    this.initialProps = initialProps
    this.options = options
  }

  abstract get type(): string

  serialize(variables: T) {
    return {
      type: this.type,
      variables
    }
  }

  abstract asComponent(): () => any
}

export type IntentProps = Record<string, any>
