import React from 'react'
import styled from 'styled-components/macro'
import M from '../common/moment'

import {formatTime, shiftEnd} from '../common/timeUtils'


type TimeProps = {start?: any, end?: any, shiftType?: any}

export const ShiftTimeLabel = (
  {start, end, shiftType, ...rest}
  : TimeProps & JSX.IntrinsicElements['span']) => {
  // we pass and store shift times always as utc!
  const startTime = (start && M(start).utc().format('HH:mm:ss'))
    || shiftType?.startTime
  const endTime = (end && M(end).utc().format('HH:mm:ss'))
    || (shiftType?.startTime && shiftEnd(shiftType))

  return startTime ? (
    <span {...rest}>
      {formatTime(startTime, 'shortest')}
      &thinsp;&ndash;&thinsp;
      {formatTime(endTime, 'short')}
    </span>
  ) : null
}
