import React from 'react'
import _ from 'lodash'
import {withColor, withEdge} from './theme'
import {PagebarHeading} from './Pagebar'


import {Tabs, Tab} from 'grommet'
import styled from 'styled-components/macro'

export const TabbedPagebar = ({level="4", data}
: {
  level?: '1'|'2'|'3'|'4'|'5'|'6',
  data: any
}) => (
  <Tabs justify="start" css={`
    & > :first-child {
      margin: 0 -0.5rem 0.5rem -0.5rem;
      padding: 0 0.5rem;
      color: ${withColor('grey-800')};
      &:last-child { margin-bottom: 0; }
    }
  `}>
    {_.map(data, (d, i) => (
      <PagebarTab key={i} title={(
        <PagebarHeading level={level}>
          {d.title}
        </PagebarHeading>
      )} >
        {d.content}
      </PagebarTab>
    ))}
  </Tabs>
)



export const PagebarTab = styled(Tab)`
  &:hover {
    background-color: ${withColor('active')};
  }
  & > div {
    margin: 0 .5em 0 0;
    padding: 0;
    border-bottom: 1px solid
      ${(props: any) => props.active
    ? props.theme.getColor('text-secondary') : 'transparent'};

    & * {
      font-weight: ${(props: any) => props.active ? 'bold' : 'normal'}
    }
  }
`
