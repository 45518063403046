import React from 'react';
import _ from 'lodash'
import M from '../common/moment'
import {useParams} from 'react-router-dom'
import 'styled-components/macro'


import Pagebar from '../components/Pagebar'
import {CardLabel} from '../components/CardLabel'
import {Paper} from '../components/Paper'
import {Grid, Box, Text, Button} from 'grommet'
import {FormEdit as EditIcon} from 'grommet-icons'

import {IntentContext} from '../components/intent/IntentContext'
import UpdateEmployee from '../components/intent/UpdateEmployee'

import gql from 'graphql-tag'
import {
  useEmployeeCardQuery,
  EmployeeCardQuery,
  WageSpec
} from '../generated/graphql'

// eslint-disable-next-line
const GET_EMPLOYEE_CARD = gql`
  query EmployeeCard($employeeId: ID!) {
    listEmployees(input: {employeeIds: [$employeeId]}) {
      employees {
        id
        passport {id, firstName, lastName}
        hiredDate
        firedDate
      }
    }
#    listJobContracts(input: {employeeId: $employeeId}) {
#      contracts {
#        id
#        job {id, name}
#        qualification {id, name}
#        startDate
#        wage {type, cents}
#      }
#    }
  }
`

export default function Employee() {
  const {employeeId} = useParams()
  const {onIntent} = React.useContext(IntentContext)
  const {data, loading, error} = useEmployeeCardQuery({
    variables: {employeeId}
  })
  if (loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  const employee = _.first(data.listEmployees.employees)
  // const contracts = data.listJobContracts.contracts

  return (
    <article>
      <Paper>
        <Pagebar
          title={`${employee.passport.firstName} ${employee.passport.lastName}`}
          actions={(<Button
            css="padding: 0 .3em 0 .3em"
            margin={{horizontal: 'xsmall'}}
            hoverIndicator
            icon={<EditIcon />}
            onClick={() => onIntent(new UpdateEmployee({
              employeeId: employee.id
            }))}
          />)}
        />
        <EmployeeCard employee={employee}
        // contracts={contracts}
        />
      </Paper>
    </article>
  )
}


interface CardProps {
  employee: EmployeeCardQuery["listEmployees"]["employees"][0],
  // contracts: EmployeeCardQuery["listJobContracts"]["contracts"]
}
const EmployeeCard = ({employee/*, contracts*/}: CardProps) => {
  return (
    <Box direction="column" gap="medium" pad={{vertical: 'medium'}}>
      <EmployeeHiredFired employee={employee} />
      {/*<EmployeeContracts contracts={contracts} />*/}
    </Box>
  )
}

const EmployeeHiredFired = ({employee}: Pick<CardProps, 'employee'>) => (
  <Box direction="column">
    <Text>
      <CardLabel>Принят: </CardLabel>
      {employee.hiredDate ? formatDate(employee.hiredDate) : '-'}</Text>
    <Text>
      <CardLabel>Уволен: </CardLabel>
      {employee.firedDate ? formatDate(employee.firedDate) : '-'}</Text>
  </Box>
)

// const EmployeeContracts = ({contracts}: Pick<CardProps, 'contracts'>) => (
//   <Grid
//     columns={['auto', 'auto', 'flex']}
//     gap="small"
//   >
//     {_.flatMap(_.sortBy(contracts, 'startDate'), c => ([
//       <Box key={`${c.id}-1`}>{formatDate(c.startDate)}</Box>,
//       <Box key={`${c.id}-2`} direction="column">
//         <CardLabel>{c.job.name}</CardLabel>
//         <Text color="dark-3">{c.qualification.name}</Text>
//       </Box>,
//       <Box key={`${c.id}-3`}>
//         <Text>{c.wage ? formatWage(c.wage) : '-'}</Text>
//       </Box>,
//     ]))}
//   </Grid>
// )

const formatDate = (date) => M(date).format('DD.MM.YY')

// const formatWage = (wage: WageSpec) => {
//   const dollars = Math.floor(wage.cents / 100)
//   const suffix = wage.type == 'HOURS' ? 'р/ч' : 'р/мес'
//   return `${dollars} ${suffix}`
// }
