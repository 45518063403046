import React from 'react';
import _ from 'lodash'
import {useParams} from 'react-router-dom'
import 'styled-components/macro'

import {formatTime, shiftEnd} from '../common/timeUtils'

import Pagebar from '../components/Pagebar'
import {Paper} from '../components/Paper'
import {JobLabel} from '../components/JobLabel'
import {CardLabel} from '../components/CardLabel'
import Anchor from '../components/RoutedAnchor';
import {List, Box, Text, Button} from 'grommet'
import {
  FormEdit as EditIcon,
  AddCircle as AddIcon
} from 'grommet-icons'

import {IntentContext} from '../components/intent/IntentContext'
import UpdateTeam from '../components/intent/UpdateTeam'
import CreateShiftType from '../components/intent/CreateShiftType'
import UpdateShiftType from '../components/intent/UpdateShiftType'

import gql from 'graphql-tag'
import {
  useTeamCardQuery,
  TeamCardQuery,
  ShiftTypeFaceFragmentDoc,
  ShiftTypeTimesFragmentDoc,
} from '../generated/graphql'

// eslint-disable-next-line
const GET_TEAM_CARD = gql`
  query TeamCard($teamId: ID!) {
    listTeams(input: {teamIds: [$teamId]}) {
      teams {
        id
        name
        shiftTypes {
          ...ShiftTypeFace, ...ShiftTypeTimes
        }
        members {
          employee {id, passport {id, firstName, lastName}}
          job {id, name}
        }
      }
    }
  }
  ${ShiftTypeFaceFragmentDoc}
  ${ShiftTypeTimesFragmentDoc}
`

interface CardProps {
  team: TeamCardQuery['listTeams']['teams'][0],
}

export default function Team() {
  const {teamId} = useParams()
  const {onIntent} = React.useContext(IntentContext)
  const {data, loading, error} = useTeamCardQuery({
    variables: {teamId}
  })
  if (loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  const team = _.first(data.listTeams.teams)

  return (
    <article>
      <Box direction="column" gap="medium">

        <Paper>
          <Pagebar title={team.name}
            actions={(<Button
              css="padding: 0 .3em 0 .3em"
              margin={{horizontal: 'xsmall'}}
              hoverIndicator
              icon={<EditIcon />}
              onClick={() => onIntent(new UpdateTeam({teamId}))}
            />)}
          />
        </Paper>


        <Paper>
          <Pagebar level="2" title="Смены"
            actions={(<Button
              css="padding: 0 .3em 0 .3em"
              margin={{horizontal: 'xsmall'}}
              hoverIndicator
              icon={<AddIcon />}
              onClick={() => onIntent(new CreateShiftType(
                {teamId: team.id}))}
            />)}
          />
          <TeamShiftTypes team={team} />
        </Paper>

        <Paper>
          <Pagebar level='2' title="Сотрудники" />
          {team.members.length ? (
            <MemberList members={team.members} />
          ) : (
              <Text>Никого нет</Text>
            )}
        </Paper>

      </Box>
    </article>
  )
}

const TeamShiftTypes = ({team}: CardProps) => {
  const {onIntent} = React.useContext(IntentContext)

  return (
    <List
      data={_.sortBy(team.shiftTypes, 'startTime')}
      primaryKey={shift =>
        <CardLabel key={shift.id}> {shift.name} </CardLabel>}
      secondaryKey={shift =>
        <ShiftIntervalLabel key={shift.id + 't'} shift={shift} />}
      css={`
      & li {justify-content: unset;}
    `}
      onClickItem={({item}) => onIntent(new UpdateShiftType({
        shiftTypeId: item.id
      }))}
    />
  )
}

const ShiftIntervalLabel = ({shift}) => (
  <Text>
    {formatTime(shift.startTime)} - {formatTime(shiftEnd(shift))}
  </Text>
)

type Membership = TeamCardQuery['listTeams']['teams'][0]['members']

const MemberList = ({members}: {members: Membership}) => {
  const byEmployee = _.groupBy(
    _.sortBy(members, ['employee.passport.lastName', 'job.name']),
    'employee.id'
  )
  const listData = _.values(byEmployee).map(jobs => ({
    employee: jobs[0].employee,
    jobs
  }))
  return (
    <List
      data={listData}
      primaryKey={m =>
        <EmployeeLink key={m.employee.id} employee={m.employee} />}
      secondaryKey={m =>
        <JobsLabel key={m.employee + 'j'} jobs={m.jobs} />}
      css={`
      & li {justify-content: unset;}
    `}
    />
  )
}

const EmployeeLink = ({employee}) => (
  <Text>
    <Anchor href={`/employees/${employee.id}`}>
      {employee.passport.firstName} {employee.passport.lastName}
    </Anchor>
  </Text>
)

const JobsLabel = ({jobs}) => (
  <Box direction="row" gap="xsmall" wrap css={`
    // hack for vertical spacing of wrapped flex elemers
    margin-top: -1ex;
    & > * {margin-top: 1ex}
  `} >
    {_.map(jobs, j => <JobLabel job={j.job} key={j.job.id} />)}
  </Box>
)
