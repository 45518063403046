import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
 */
  DateTime: any,
  /** 
 * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
 */
  Date: any,
  /** 
 * A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format
   * outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
 */
  Time: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};



export type AcceptInviteResponse = {
   __typename?: 'AcceptInviteResponse',
  employee?: Maybe<Employee>,
};

export type AssignJobInput = {
  employeeId: Scalars['ID'],
  qualificationId: Scalars['ID'],
  startDate: Scalars['Date'],
  wage?: Maybe<WageSpecInput>,
};

export type AssignShiftInput = {
  shiftTypeId: Scalars['ID'],
  employeeId: Scalars['ID'],
  date: Scalars['Date'],
  jobId: Scalars['ID'],
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CancelShiftInput = {
  schedulePlanId: Scalars['ID'],
};

export type Company = {
   __typename?: 'Company',
  id: Scalars['ID'],
  name: Scalars['String'],
  slug: Scalars['String'],
  employees?: Maybe<Array<Employee>>,
  createdStamp?: Maybe<Scalars['DateTime']>,
};

export type CreateCompanyInput = {
  name: Scalars['String'],
  slug: Scalars['String'],
};

export type CreateEmployeeInput = {
  companyId?: Maybe<Scalars['ID']>,
  firstName?: Maybe<Scalars['String']>,
  middleName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
};

export type CreateJobInput = {
  name: Scalars['String'],
};

export type CreatePassportInput = {
  firstName?: Maybe<Scalars['String']>,
  middleName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
};

export type CreateQualificationInput = {
  jobId: Scalars['ID'],
  name: Scalars['String'],
};

export type CreateShiftTypeInput = {
  teamId: Scalars['ID'],
  name: Scalars['String'],
  startTime: Scalars['Time'],
  duration: Scalars['Int'],
};

export type CreateTeamInput = {
  name: Scalars['String'],
};


export type DateInterval = {
   __typename?: 'DateInterval',
  from?: Maybe<Scalars['Date']>,
  to?: Maybe<Scalars['Date']>,
};


export type DeleteShiftResponse = {
   __typename?: 'DeleteShiftResponse',
  deletedIds?: Maybe<Array<Scalars['ID']>>,
};

export type Employee = {
   __typename?: 'Employee',
  id: Scalars['ID'],
  passport: Passport,
  company: Company,
  role?: Maybe<Scalars['String']>,
  hiredDate?: Maybe<Scalars['Date']>,
  firedDate?: Maybe<Scalars['Date']>,
  invites?: Maybe<Array<EmployeeInvite>>,
};

export type EmployeeInvite = {
   __typename?: 'EmployeeInvite',
  id: Scalars['ID'],
  pending: Scalars['Boolean'],
  acceptedStamp?: Maybe<Scalars['DateTime']>,
};

export type EmployeeInviteMeta = {
   __typename?: 'EmployeeInviteMeta',
  firstName?: Maybe<Scalars['String']>,
  middleName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  companyName: Scalars['String'],
  companyId: Scalars['ID'],
};

export type GetPendingInviteInput = {
  inviteId: Scalars['ID'],
};

export type GetPendingInviteResponse = {
   __typename?: 'GetPendingInviteResponse',
  invite?: Maybe<EmployeeInvite>,
  inviteMeta?: Maybe<EmployeeInviteMeta>,
};

export type GetShiftTypeInput = {
  id: Scalars['ID'],
};

export type GetShiftTypeResponse = {
   __typename?: 'GetShiftTypeResponse',
  shiftType: ShiftType,
};

export type Job = {
   __typename?: 'Job',
  id: Scalars['ID'],
  name: Scalars['String'],
  qualifications?: Maybe<Array<Maybe<Qualification>>>,
};

export type JobContract = {
   __typename?: 'JobContract',
  id: Scalars['ID'],
  employee: Employee,
  job: Job,
  qualification: Qualification,
  startDate: Scalars['Date'],
  wage?: Maybe<WageSpec>,
};

export type ListCompaniesInput = {
  companyIds?: Maybe<Array<Scalars['ID']>>,
};

export type ListCompaniesResponse = {
   __typename?: 'ListCompaniesResponse',
  companies?: Maybe<Array<Company>>,
};

export type ListEmployeesInput = {
  employeeIds?: Maybe<Array<Scalars['ID']>>,
  /** 
 *  If falsy, won't apply the criterion to the search
   * Empty datafield will qualify as a match 
 */
  firedLaterThan?: Maybe<Scalars['Date']>,
  /** 
 *  If falsy, won't apply the criterion to the search
   * Empty datafield will qualify as a match 
 */
  hiredNotLaterThan?: Maybe<Scalars['Date']>,
};

export type ListEmployeesResponse = {
   __typename?: 'ListEmployeesResponse',
  employees?: Maybe<Array<Employee>>,
};

export type ListJobContractsInput = {
  employeeId: Scalars['ID'],
  validOn?: Maybe<Scalars['Date']>,
  jobId?: Maybe<Scalars['ID']>,
};

export type ListJobContractsResponse = {
   __typename?: 'ListJobContractsResponse',
  contracts?: Maybe<Array<JobContract>>,
};

export type ListJobsInput = {
  jobIds?: Maybe<Array<Scalars['ID']>>,
  qualificationIds?: Maybe<Array<Scalars['ID']>>,
};

export type ListJobsResponse = {
   __typename?: 'ListJobsResponse',
  jobs?: Maybe<Array<Job>>,
};

export type ListPassportsInput = {
  passportIds?: Maybe<Array<Scalars['ID']>>,
  types?: Maybe<Array<Scalars['String']>>,
};

export type ListPassportsResponse = {
   __typename?: 'ListPassportsResponse',
  passports?: Maybe<Array<Passport>>,
};

export type ListScheduleInput = {
  teamIds?: Maybe<Array<Scalars['ID']>>,
  employeeIds?: Maybe<Array<Scalars['ID']>>,
  from: Scalars['Date'],
  to: Scalars['Date'],
};

export type ListScheduleResponse = {
   __typename?: 'ListScheduleResponse',
  plans?: Maybe<Array<SchedulePlan>>,
};

export type ListTeamsInput = {
  teamIds?: Maybe<Array<Scalars['ID']>>,
};

export type ListTeamsResponse = {
   __typename?: 'ListTeamsResponse',
  teams?: Maybe<Array<Team>>,
};

export type LoginResponse = {
   __typename?: 'LoginResponse',
  token?: Maybe<Scalars['String']>,
};

export type MeResponse = {
   __typename?: 'MeResponse',
  passport?: Maybe<Passport>,
  employeeSession?: Maybe<Employee>,
};

export type Mutation = {
   __typename?: 'Mutation',
  registerPassport?: Maybe<UpdatePassportResponse>,
  createCompany?: Maybe<UpdateCompanyResponse>,
  updateCompanyCard?: Maybe<UpdateCompanyResponse>,
  enlistEmployee?: Maybe<UpdateEmployeeResponse>,
  updateEmployeeCard?: Maybe<UpdateEmployeeResponse>,
  setEmployeeRole?: Maybe<UpdateEmployeeResponse>,
  toggleEmployeeInvite?: Maybe<UpdateEmployeeInviteResponse>,
  acceptEmployeeInvite?: Maybe<AcceptInviteResponse>,
  createTeam?: Maybe<UpdateTeamResponse>,
  updateTeamCard?: Maybe<UpdateTeamResponse>,
  createShiftType?: Maybe<GetShiftTypeResponse>,
  updateShiftType?: Maybe<GetShiftTypeResponse>,
  addTeamMember?: Maybe<UpdateTeamMembershipResponse>,
  createJob?: Maybe<UpdateJobResponse>,
  updateJobCard?: Maybe<UpdateJobResponse>,
  createQualification?: Maybe<UpdateQualificationResponse>,
  assignJob?: Maybe<UpdateJobContractResponse>,
  assignShift?: Maybe<UpdateShiftResponse>,
  cancelShift?: Maybe<DeleteShiftResponse>,
};


export type MutationRegisterPassportArgs = {
  input: CreatePassportInput
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput
};


export type MutationUpdateCompanyCardArgs = {
  input: UpdateCompanyInput
};


export type MutationEnlistEmployeeArgs = {
  input: CreateEmployeeInput
};


export type MutationUpdateEmployeeCardArgs = {
  input: UpdateEmployeeInput
};


export type MutationSetEmployeeRoleArgs = {
  input: SetEmployeeRoleInput
};


export type MutationToggleEmployeeInviteArgs = {
  input: ToggleEmployeeInviteInput
};


export type MutationAcceptEmployeeInviteArgs = {
  input: GetPendingInviteInput
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput
};


export type MutationUpdateTeamCardArgs = {
  input: UpdateTeamInput
};


export type MutationCreateShiftTypeArgs = {
  input: CreateShiftTypeInput
};


export type MutationUpdateShiftTypeArgs = {
  input: UpdateShiftTypeInput
};


export type MutationAddTeamMemberArgs = {
  input: UpdateTeamMembershipInput
};


export type MutationCreateJobArgs = {
  input: CreateJobInput
};


export type MutationUpdateJobCardArgs = {
  input: UpdateJobInput
};


export type MutationCreateQualificationArgs = {
  input?: Maybe<CreateQualificationInput>
};


export type MutationAssignJobArgs = {
  input: AssignJobInput
};


export type MutationAssignShiftArgs = {
  input: AssignShiftInput
};


export type MutationCancelShiftArgs = {
  input: CancelShiftInput
};

export type Passport = {
   __typename?: 'Passport',
  id: Scalars['ID'],
  type: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  middleName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  employments?: Maybe<Array<Employee>>,
  createdStamp?: Maybe<Scalars['DateTime']>,
};

export type Qualification = {
   __typename?: 'Qualification',
  id: Scalars['ID'],
  name: Scalars['String'],
  job: Job,
};

export type Query = {
   __typename?: 'Query',
  /** used to decide whether cache reset is needed */
  currentOrgId?: Maybe<Scalars['String']>,
  getPendingInvite?: Maybe<GetPendingInviteResponse>,
  me?: Maybe<MeResponse>,
  listPassports?: Maybe<ListPassportsResponse>,
  listCompanies?: Maybe<ListCompaniesResponse>,
  listEmployees?: Maybe<ListEmployeesResponse>,
  listJobs?: Maybe<ListJobsResponse>,
  listJobContracts?: Maybe<ListJobContractsResponse>,
  listTeams?: Maybe<ListTeamsResponse>,
  getShiftType?: Maybe<GetShiftTypeResponse>,
  listSchedule?: Maybe<ListScheduleResponse>,
};


export type QueryGetPendingInviteArgs = {
  input: GetPendingInviteInput
};


export type QueryListPassportsArgs = {
  input: ListPassportsInput
};


export type QueryListCompaniesArgs = {
  input: ListCompaniesInput
};


export type QueryListEmployeesArgs = {
  input: ListEmployeesInput
};


export type QueryListJobsArgs = {
  input: ListJobsInput
};


export type QueryListJobContractsArgs = {
  input: ListJobContractsInput
};


export type QueryListTeamsArgs = {
  input: ListTeamsInput
};


export type QueryGetShiftTypeArgs = {
  input: GetShiftTypeInput
};


export type QueryListScheduleArgs = {
  input: ListScheduleInput
};

export type SchedulePlan = {
   __typename?: 'SchedulePlan',
  id: Scalars['ID'],
  team: Team,
  employee: Employee,
  date: Scalars['Date'],
  start: Scalars['DateTime'],
  end: Scalars['DateTime'],
  shiftType: ShiftType,
  job: Job,
  teammatePlans?: Maybe<Array<SchedulePlan>>,
};

export type SetEmployeeRoleInput = {
  employeeId: Scalars['ID'],
  role: Scalars['String'],
};

export type ShiftType = {
   __typename?: 'ShiftType',
  id: Scalars['ID'],
  team: Team,
  name: Scalars['String'],
  color: Scalars['String'],
  startTime: Scalars['Time'],
  duration: Scalars['Int'],
  lastUsedDate?: Maybe<Scalars['Date']>,
  isDeleted: Scalars['Boolean'],
};

export type Team = {
   __typename?: 'Team',
  id: Scalars['ID'],
  name: Scalars['String'],
  shiftTypes?: Maybe<Array<Maybe<ShiftType>>>,
  members?: Maybe<Array<TeamMembership>>,
};


export type TeamMembersArgs = {
  date?: Maybe<Scalars['Date']>
};

export type TeamMembership = {
   __typename?: 'TeamMembership',
  team: Team,
  employee: Employee,
  job: Job,
};


export type ToggleEmployeeInviteInput = {
  employeeId: Scalars['ID'],
  enable: Scalars['Boolean'],
};

export type UpdateCompanyInput = {
  companyId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  slug: Scalars['String'],
};

export type UpdateCompanyResponse = {
   __typename?: 'UpdateCompanyResponse',
  company?: Maybe<Company>,
};

export type UpdateEmployeeInput = {
  employeeId: Scalars['ID'],
  firstName?: Maybe<Scalars['String']>,
  middleName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  hiredDate?: Maybe<Scalars['Date']>,
  firedDate?: Maybe<Scalars['Date']>,
};

export type UpdateEmployeeInviteResponse = {
   __typename?: 'UpdateEmployeeInviteResponse',
  invites?: Maybe<Array<EmployeeInvite>>,
};

export type UpdateEmployeeResponse = {
   __typename?: 'UpdateEmployeeResponse',
  employee?: Maybe<Employee>,
};

export type UpdateJobContractResponse = {
   __typename?: 'UpdateJobContractResponse',
  contract?: Maybe<JobContract>,
};

export type UpdateJobInput = {
  jobId: Scalars['ID'],
  name: Scalars['String'],
};

export type UpdateJobResponse = {
   __typename?: 'UpdateJobResponse',
  job?: Maybe<Job>,
};

export type UpdatePassportResponse = {
   __typename?: 'UpdatePassportResponse',
  passport?: Maybe<Passport>,
};

export type UpdateQualificationResponse = {
   __typename?: 'UpdateQualificationResponse',
  qualification?: Maybe<Qualification>,
};

export type UpdateShiftResponse = {
   __typename?: 'UpdateShiftResponse',
  plan?: Maybe<SchedulePlan>,
};

export type UpdateShiftTypeInput = {
  shiftTypeId: Scalars['ID'],
  name: Scalars['String'],
  color: Scalars['String'],
  startTime: Scalars['Time'],
  duration: Scalars['Int'],
  isDeleted: Scalars['Boolean'],
};

export type UpdateTeamInput = {
  teamId: Scalars['ID'],
  name: Scalars['String'],
};

export type UpdateTeamMembershipInput = {
  teamId: Scalars['ID'],
  employeeId: Scalars['ID'],
  jobId: Scalars['ID'],
  startDate?: Maybe<Scalars['Date']>,
};

export type UpdateTeamMembershipResponse = {
   __typename?: 'UpdateTeamMembershipResponse',
  membership?: Maybe<TeamMembership>,
};

export type UpdateTeamResponse = {
   __typename?: 'UpdateTeamResponse',
  team?: Maybe<Team>,
};


export type WageSpec = {
   __typename?: 'WageSpec',
  type: WageType,
  cents: Scalars['Int'],
};

export type WageSpecInput = {
  type: WageType,
  cents: Scalars['Int'],
};

export enum WageType {
  Hours = 'HOURS',
  Months = 'MONTHS'
}

export type AddTeamMemberMutationVariables = {
  input: UpdateTeamMembershipInput
};


export type AddTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & { addTeamMember: Maybe<(
    { __typename?: 'UpdateTeamMembershipResponse' }
    & { membership: Maybe<(
      { __typename?: 'TeamMembership' }
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
      ), employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
      ), job: (
        { __typename?: 'Job' }
        & Pick<Job, 'id'>
      ) }
    )> }
  )> }
);

export type RegisterPassportMutationVariables = {
  input: CreatePassportInput
};


export type RegisterPassportMutation = (
  { __typename?: 'Mutation' }
  & { registerPassport: Maybe<(
    { __typename?: 'UpdatePassportResponse' }
    & { passport: Maybe<(
      { __typename?: 'Passport' }
      & Pick<Passport, 'id'>
    )> }
  )> }
);

export type CreateJobMutationVariables = {
  input: CreateJobInput
};


export type CreateJobMutation = (
  { __typename?: 'Mutation' }
  & { createJob: Maybe<(
    { __typename?: 'UpdateJobResponse' }
    & { job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
    )> }
  )> }
);

export type CreateShiftTypeMutationVariables = {
  input: CreateShiftTypeInput
};


export type CreateShiftTypeMutation = (
  { __typename?: 'Mutation' }
  & { createShiftType: Maybe<(
    { __typename?: 'GetShiftTypeResponse' }
    & { shiftType: (
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'id' | 'name' | 'startTime' | 'duration' | 'isDeleted'>
    ) }
  )> }
);

export type CreateTeamMutationVariables = {
  input: CreateTeamInput
};


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam: Maybe<(
    { __typename?: 'UpdateTeamResponse' }
    & { team: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )> }
  )> }
);

export type AssignShiftMutationVariables = {
  input: AssignShiftInput
};


export type AssignShiftMutation = (
  { __typename?: 'Mutation' }
  & { assignShift: Maybe<(
    { __typename?: 'UpdateShiftResponse' }
    & { plan: Maybe<(
      { __typename?: 'SchedulePlan' }
      & Pick<SchedulePlan, 'id'>
    )> }
  )> }
);

export type CancelShiftMutationVariables = {
  input: CancelShiftInput
};


export type CancelShiftMutation = (
  { __typename?: 'Mutation' }
  & { cancelShift: Maybe<(
    { __typename?: 'DeleteShiftResponse' }
    & Pick<DeleteShiftResponse, 'deletedIds'>
  )> }
);

export type ListEmployeeShiftsQueryVariables = {
  employeeId: Scalars['ID'],
  date: Scalars['Date'],
  weekStart: Scalars['Date'],
  weekEnd: Scalars['Date']
};


export type ListEmployeeShiftsQuery = (
  { __typename?: 'Query' }
  & { ownShifts: Maybe<(
    { __typename?: 'ListScheduleResponse' }
    & { plans: Maybe<Array<(
      { __typename?: 'SchedulePlan' }
      & Pick<SchedulePlan, 'id' | 'start' | 'end'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ), shiftType: (
        { __typename?: 'ShiftType' }
        & Pick<ShiftType, 'id' | 'name'>
      ), job: (
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      ) }
    )>> }
  )>, weekShifts: Maybe<(
    { __typename?: 'ListScheduleResponse' }
    & { plans: Maybe<Array<(
      { __typename?: 'SchedulePlan' }
      & Pick<SchedulePlan, 'id'>
      & { shiftType: (
        { __typename?: 'ShiftType' }
        & Pick<ShiftType, 'id' | 'duration'>
      ), job: (
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type ListShiftTeammatesQueryVariables = {
  teamIds?: Maybe<Array<Scalars['ID']>>,
  date: Scalars['Date']
};


export type ListShiftTeammatesQuery = (
  { __typename?: 'Query' }
  & { listSchedule: Maybe<(
    { __typename?: 'ListScheduleResponse' }
    & { plans: Maybe<Array<(
      { __typename?: 'SchedulePlan' }
      & Pick<SchedulePlan, 'id' | 'start' | 'end'>
      & { employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & { passport: (
          { __typename?: 'Passport' }
          & Pick<Passport, 'id' | 'firstName' | 'lastName'>
        ) }
      ), team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ), shiftType: (
        { __typename?: 'ShiftType' }
        & Pick<ShiftType, 'id' | 'name'>
      ), job: (
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type EnlistEmployeeMutationVariables = {
  input: CreateEmployeeInput
};


export type EnlistEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { enlistEmployee: Maybe<(
    { __typename?: 'UpdateEmployeeResponse' }
    & { employee: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & { passport: (
        { __typename?: 'Passport' }
        & Pick<Passport, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type AcceptInviteMutationVariables = {
  input: GetPendingInviteInput
};


export type AcceptInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptEmployeeInvite: Maybe<(
    { __typename?: 'AcceptInviteResponse' }
    & { employee: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
      ) }
    )> }
  )> }
);

export type MeQueryVariables = {};


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'MeResponse' }
    & { passport: Maybe<(
      { __typename?: 'Passport' }
      & Pick<Passport, 'id' | 'type' | 'firstName' | 'lastName'>
      & { employments: Maybe<Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'role'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      )>> }
    )> }
  )> }
);

export type UpdateEmployeeCardMutationVariables = {
  input: UpdateEmployeeInput
};


export type UpdateEmployeeCardMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployeeCard: Maybe<(
    { __typename?: 'UpdateEmployeeResponse' }
    & { employee: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'firedDate' | 'hiredDate'>
      & { passport: (
        { __typename?: 'Passport' }
        & Pick<Passport, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type UpdateJobCardMutationVariables = {
  input: UpdateJobInput
};


export type UpdateJobCardMutation = (
  { __typename?: 'Mutation' }
  & { updateJobCard: Maybe<(
    { __typename?: 'UpdateJobResponse' }
    & { job: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
      & { qualifications: Maybe<Array<Maybe<(
        { __typename?: 'Qualification' }
        & Pick<Qualification, 'id' | 'name'>
      )>>> }
    )> }
  )> }
);

export type ShiftTypeFaceFragment = (
  { __typename?: 'ShiftType' }
  & Pick<ShiftType, 'id' | 'name' | 'color' | 'isDeleted'>
);

export type ShiftTypeTimesFragment = (
  { __typename?: 'ShiftType' }
  & Pick<ShiftType, 'startTime' | 'duration'>
);

export type ShiftTypeCardQueryVariables = {
  shiftTypeId: Scalars['ID']
};


export type ShiftTypeCardQuery = (
  { __typename?: 'Query' }
  & { getShiftType: Maybe<(
    { __typename?: 'GetShiftTypeResponse' }
    & { shiftType: (
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'lastUsedDate'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id'>
        & { shiftTypes: Maybe<Array<Maybe<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'id' | 'name'>
        )>>> }
      ) }
      & ShiftTypeFaceFragment
      & ShiftTypeTimesFragment
    ) }
  )> }
);

export type UpdateShiftTypeMutationVariables = {
  input: UpdateShiftTypeInput
};


export type UpdateShiftTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateShiftType: Maybe<(
    { __typename?: 'GetShiftTypeResponse' }
    & { shiftType: (
      { __typename?: 'ShiftType' }
      & ShiftTypeFaceFragment
      & ShiftTypeTimesFragment
    ) }
  )> }
);

export type UpdateTeamCardMutationVariables = {
  input: UpdateTeamInput
};


export type UpdateTeamCardMutation = (
  { __typename?: 'Mutation' }
  & { updateTeamCard: Maybe<(
    { __typename?: 'UpdateTeamResponse' }
    & { team: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )> }
  )> }
);

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput
};


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: Maybe<(
    { __typename?: 'UpdateCompanyResponse' }
    & { company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput
};


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyCard: Maybe<(
    { __typename?: 'UpdateCompanyResponse' }
    & { company: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'slug'>
    )> }
  )> }
);

export type EmployeeMetaQueryVariables = {
  employeeId: Scalars['ID']
};


export type EmployeeMetaQuery = (
  { __typename?: 'Query' }
  & { listEmployees: Maybe<(
    { __typename?: 'ListEmployeesResponse' }
    & { employees: Maybe<Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'role'>
      & { passport: (
        { __typename?: 'Passport' }
        & Pick<Passport, 'id' | 'type' | 'firstName' | 'lastName'>
      ), company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ), invites: Maybe<Array<(
        { __typename?: 'EmployeeInvite' }
        & Pick<EmployeeInvite, 'id'>
      )>> }
    )>> }
  )> }
);

export type SetEmployeeRoleMutationVariables = {
  input: SetEmployeeRoleInput
};


export type SetEmployeeRoleMutation = (
  { __typename?: 'Mutation' }
  & { setEmployeeRole: Maybe<(
    { __typename?: 'UpdateEmployeeResponse' }
    & { employee: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'role'>
    )> }
  )> }
);

export type ToggleEmployeeInviteMutationVariables = {
  input: ToggleEmployeeInviteInput
};


export type ToggleEmployeeInviteMutation = (
  { __typename?: 'Mutation' }
  & { toggleEmployeeInvite: Maybe<(
    { __typename?: 'UpdateEmployeeInviteResponse' }
    & { invites: Maybe<Array<(
      { __typename?: 'EmployeeInvite' }
      & Pick<EmployeeInvite, 'id'>
    )>> }
  )> }
);

export type EmployeeCardQueryVariables = {
  employeeId: Scalars['ID']
};


export type EmployeeCardQuery = (
  { __typename?: 'Query' }
  & { listEmployees: Maybe<(
    { __typename?: 'ListEmployeesResponse' }
    & { employees: Maybe<Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'hiredDate' | 'firedDate'>
      & { passport: (
        { __typename?: 'Passport' }
        & Pick<Passport, 'id' | 'firstName' | 'lastName'>
      ) }
    )>> }
  )> }
);

export type ListAllEmployeesQueryVariables = {};


export type ListAllEmployeesQuery = (
  { __typename?: 'Query' }
  & { listEmployees: Maybe<(
    { __typename?: 'ListEmployeesResponse' }
    & { employees: Maybe<Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & { passport: (
        { __typename?: 'Passport' }
        & Pick<Passport, 'id' | 'firstName' | 'lastName'>
      ) }
    )>> }
  )> }
);

export type JobCardQueryVariables = {
  jobId: Scalars['ID']
};


export type JobCardQuery = (
  { __typename?: 'Query' }
  & { listJobs: Maybe<(
    { __typename?: 'ListJobsResponse' }
    & { jobs: Maybe<Array<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
      & { qualifications: Maybe<Array<Maybe<(
        { __typename?: 'Qualification' }
        & Pick<Qualification, 'id' | 'name'>
      )>>> }
    )>> }
  )> }
);

export type ListAllJobsQueryVariables = {
  input: ListJobsInput
};


export type ListAllJobsQuery = (
  { __typename?: 'Query' }
  & { listJobs: Maybe<(
    { __typename?: 'ListJobsResponse' }
    & { jobs: Maybe<Array<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
    )>> }
  )> }
);

export type EmployeeInviteQueryVariables = {
  inviteId: Scalars['ID']
};


export type EmployeeInviteQuery = (
  { __typename?: 'Query' }
  & { getPendingInvite: Maybe<(
    { __typename?: 'GetPendingInviteResponse' }
    & { inviteMeta: Maybe<(
      { __typename?: 'EmployeeInviteMeta' }
      & Pick<EmployeeInviteMeta, 'firstName' | 'companyName' | 'companyId'>
    )> }
  )> }
);

export type ListScheduleQueryVariables = {
  from: Scalars['Date'],
  to: Scalars['Date']
};


export type ListScheduleQuery = (
  { __typename?: 'Query' }
  & { listTeams: Maybe<(
    { __typename?: 'ListTeamsResponse' }
    & { teams: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { members: Maybe<Array<(
        { __typename?: 'TeamMembership' }
        & { employee: (
          { __typename?: 'Employee' }
          & Pick<Employee, 'id'>
          & { passport: (
            { __typename?: 'Passport' }
            & Pick<Passport, 'id' | 'lastName' | 'firstName'>
          ) }
        ), job: (
          { __typename?: 'Job' }
          & Pick<Job, 'id' | 'name'>
        ) }
      )>> }
    )>> }
  )>, listSchedule: Maybe<(
    { __typename?: 'ListScheduleResponse' }
    & { plans: Maybe<Array<(
      { __typename?: 'SchedulePlan' }
      & Pick<SchedulePlan, 'id' | 'date'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      ), employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & { passport: (
          { __typename?: 'Passport' }
          & Pick<Passport, 'id' | 'lastName' | 'firstName'>
        ) }
      ), shiftType: (
        { __typename?: 'ShiftType' }
        & ShiftTypeFaceFragment
        & ShiftTypeTimesFragment
      ), job: (
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type TeamCardQueryVariables = {
  teamId: Scalars['ID']
};


export type TeamCardQuery = (
  { __typename?: 'Query' }
  & { listTeams: Maybe<(
    { __typename?: 'ListTeamsResponse' }
    & { teams: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { shiftTypes: Maybe<Array<Maybe<(
        { __typename?: 'ShiftType' }
        & ShiftTypeFaceFragment
        & ShiftTypeTimesFragment
      )>>>, members: Maybe<Array<(
        { __typename?: 'TeamMembership' }
        & { employee: (
          { __typename?: 'Employee' }
          & Pick<Employee, 'id'>
          & { passport: (
            { __typename?: 'Passport' }
            & Pick<Passport, 'id' | 'firstName' | 'lastName'>
          ) }
        ), job: (
          { __typename?: 'Job' }
          & Pick<Job, 'id' | 'name'>
        ) }
      )>> }
    )>> }
  )> }
);

export type ListAllTeamsQueryVariables = {};


export type ListAllTeamsQuery = (
  { __typename?: 'Query' }
  & { listTeams: Maybe<(
    { __typename?: 'ListTeamsResponse' }
    & { teams: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
    )>> }
  )> }
);

export type ListAllCompaniesQueryVariables = {};


export type ListAllCompaniesQuery = (
  { __typename?: 'Query' }
  & { listCompanies: Maybe<(
    { __typename?: 'ListCompaniesResponse' }
    & { companies: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>> }
  )> }
);

export type CompanyCardQueryVariables = {
  companyId: Scalars['ID']
};


export type CompanyCardQuery = (
  { __typename?: 'Query' }
  & { listCompanies: Maybe<(
    { __typename?: 'ListCompaniesResponse' }
    & { companies: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'slug' | 'createdStamp'>
      & { employees: Maybe<Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'role'>
        & { passport: (
          { __typename?: 'Passport' }
          & Pick<Passport, 'id' | 'type' | 'firstName' | 'lastName'>
        ) }
      )>> }
    )>> }
  )> }
);

export type PassportCardQueryVariables = {
  passportId: Scalars['ID']
};


export type PassportCardQuery = (
  { __typename?: 'Query' }
  & { listPassports: Maybe<(
    { __typename?: 'ListPassportsResponse' }
    & { passports: Maybe<Array<(
      { __typename?: 'Passport' }
      & Pick<Passport, 'id' | 'type' | 'firstName' | 'lastName' | 'createdStamp'>
      & { employments: Maybe<Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'role'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      )>> }
    )>> }
  )> }
);

export type OrgContextQueryVariables = {};


export type OrgContextQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'currentOrgId'>
);

export const ShiftTypeFaceFragmentDoc = gql`
    fragment ShiftTypeFace on ShiftType {
  id
  name
  color
  isDeleted
}
    `;
export const ShiftTypeTimesFragmentDoc = gql`
    fragment ShiftTypeTimes on ShiftType {
  startTime
  duration
}
    `;
export const AddTeamMemberDocument = gql`
    mutation AddTeamMember($input: UpdateTeamMembershipInput!) {
  addTeamMember(input: $input) {
    membership {
      team {
        id
      }
      employee {
        id
      }
      job {
        id
      }
    }
  }
}
    `;
export type AddTeamMemberMutationFn = ApolloReactCommon.MutationFunction<AddTeamMemberMutation, AddTeamMemberMutationVariables>;
export type AddTeamMemberComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>, 'mutation'>;

    export const AddTeamMemberComponent = (props: AddTeamMemberComponentProps) => (
      <ApolloReactComponents.Mutation<AddTeamMemberMutation, AddTeamMemberMutationVariables> mutation={AddTeamMemberDocument} {...props} />
    );
    
export type AddTeamMemberProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddTeamMemberMutation, AddTeamMemberMutationVariables> | TChildProps;
export function withAddTeamMember<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddTeamMemberMutation,
  AddTeamMemberMutationVariables,
  AddTeamMemberProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddTeamMemberMutation, AddTeamMemberMutationVariables, AddTeamMemberProps<TChildProps>>(AddTeamMemberDocument, {
      alias: 'addTeamMember',
      ...operationOptions
    });
};

/**
 * __useAddTeamMemberMutation__
 *
 * To run a mutation, you first call `useAddTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMemberMutation, { data, loading, error }] = useAddTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTeamMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTeamMemberMutation, AddTeamMemberMutationVariables>(AddTeamMemberDocument, baseOptions);
      }
export type AddTeamMemberMutationHookResult = ReturnType<typeof useAddTeamMemberMutation>;
export type AddTeamMemberMutationResult = ApolloReactCommon.MutationResult<AddTeamMemberMutation>;
export type AddTeamMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>;
export const RegisterPassportDocument = gql`
    mutation RegisterPassport($input: CreatePassportInput!) {
  registerPassport(input: $input) {
    passport {
      id
    }
  }
}
    `;
export type RegisterPassportMutationFn = ApolloReactCommon.MutationFunction<RegisterPassportMutation, RegisterPassportMutationVariables>;
export type RegisterPassportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterPassportMutation, RegisterPassportMutationVariables>, 'mutation'>;

    export const RegisterPassportComponent = (props: RegisterPassportComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterPassportMutation, RegisterPassportMutationVariables> mutation={RegisterPassportDocument} {...props} />
    );
    
export type RegisterPassportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RegisterPassportMutation, RegisterPassportMutationVariables> | TChildProps;
export function withRegisterPassport<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegisterPassportMutation,
  RegisterPassportMutationVariables,
  RegisterPassportProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RegisterPassportMutation, RegisterPassportMutationVariables, RegisterPassportProps<TChildProps>>(RegisterPassportDocument, {
      alias: 'registerPassport',
      ...operationOptions
    });
};

/**
 * __useRegisterPassportMutation__
 *
 * To run a mutation, you first call `useRegisterPassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPassportMutation, { data, loading, error }] = useRegisterPassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPassportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterPassportMutation, RegisterPassportMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterPassportMutation, RegisterPassportMutationVariables>(RegisterPassportDocument, baseOptions);
      }
export type RegisterPassportMutationHookResult = ReturnType<typeof useRegisterPassportMutation>;
export type RegisterPassportMutationResult = ApolloReactCommon.MutationResult<RegisterPassportMutation>;
export type RegisterPassportMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterPassportMutation, RegisterPassportMutationVariables>;
export const CreateJobDocument = gql`
    mutation CreateJob($input: CreateJobInput!) {
  createJob(input: $input) {
    job {
      id
      name
    }
  }
}
    `;
export type CreateJobMutationFn = ApolloReactCommon.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;
export type CreateJobComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateJobMutation, CreateJobMutationVariables>, 'mutation'>;

    export const CreateJobComponent = (props: CreateJobComponentProps) => (
      <ApolloReactComponents.Mutation<CreateJobMutation, CreateJobMutationVariables> mutation={CreateJobDocument} {...props} />
    );
    
export type CreateJobProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateJobMutation, CreateJobMutationVariables> | TChildProps;
export function withCreateJob<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateJobMutation,
  CreateJobMutationVariables,
  CreateJobProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateJobMutation, CreateJobMutationVariables, CreateJobProps<TChildProps>>(CreateJobDocument, {
      alias: 'createJob',
      ...operationOptions
    });
};

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, baseOptions);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = ApolloReactCommon.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const CreateShiftTypeDocument = gql`
    mutation CreateShiftType($input: CreateShiftTypeInput!) {
  createShiftType(input: $input) {
    shiftType {
      id
      name
      startTime
      duration
      isDeleted
    }
  }
}
    `;
export type CreateShiftTypeMutationFn = ApolloReactCommon.MutationFunction<CreateShiftTypeMutation, CreateShiftTypeMutationVariables>;
export type CreateShiftTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateShiftTypeMutation, CreateShiftTypeMutationVariables>, 'mutation'>;

    export const CreateShiftTypeComponent = (props: CreateShiftTypeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateShiftTypeMutation, CreateShiftTypeMutationVariables> mutation={CreateShiftTypeDocument} {...props} />
    );
    
export type CreateShiftTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateShiftTypeMutation, CreateShiftTypeMutationVariables> | TChildProps;
export function withCreateShiftType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateShiftTypeMutation,
  CreateShiftTypeMutationVariables,
  CreateShiftTypeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateShiftTypeMutation, CreateShiftTypeMutationVariables, CreateShiftTypeProps<TChildProps>>(CreateShiftTypeDocument, {
      alias: 'createShiftType',
      ...operationOptions
    });
};

/**
 * __useCreateShiftTypeMutation__
 *
 * To run a mutation, you first call `useCreateShiftTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftTypeMutation, { data, loading, error }] = useCreateShiftTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShiftTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShiftTypeMutation, CreateShiftTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateShiftTypeMutation, CreateShiftTypeMutationVariables>(CreateShiftTypeDocument, baseOptions);
      }
export type CreateShiftTypeMutationHookResult = ReturnType<typeof useCreateShiftTypeMutation>;
export type CreateShiftTypeMutationResult = ApolloReactCommon.MutationResult<CreateShiftTypeMutation>;
export type CreateShiftTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateShiftTypeMutation, CreateShiftTypeMutationVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    team {
      id
      name
    }
  }
}
    `;
export type CreateTeamMutationFn = ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;
export type CreateTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTeamMutation, CreateTeamMutationVariables>, 'mutation'>;

    export const CreateTeamComponent = (props: CreateTeamComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTeamMutation, CreateTeamMutationVariables> mutation={CreateTeamDocument} {...props} />
    );
    
export type CreateTeamProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateTeamMutation, CreateTeamMutationVariables> | TChildProps;
export function withCreateTeam<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTeamMutation,
  CreateTeamMutationVariables,
  CreateTeamProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTeamMutation, CreateTeamMutationVariables, CreateTeamProps<TChildProps>>(CreateTeamDocument, {
      alias: 'createTeam',
      ...operationOptions
    });
};

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = ApolloReactCommon.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const AssignShiftDocument = gql`
    mutation AssignShift($input: AssignShiftInput!) {
  assignShift(input: $input) {
    plan {
      id
    }
  }
}
    `;
export type AssignShiftMutationFn = ApolloReactCommon.MutationFunction<AssignShiftMutation, AssignShiftMutationVariables>;
export type AssignShiftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignShiftMutation, AssignShiftMutationVariables>, 'mutation'>;

    export const AssignShiftComponent = (props: AssignShiftComponentProps) => (
      <ApolloReactComponents.Mutation<AssignShiftMutation, AssignShiftMutationVariables> mutation={AssignShiftDocument} {...props} />
    );
    
export type AssignShiftProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AssignShiftMutation, AssignShiftMutationVariables> | TChildProps;
export function withAssignShift<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignShiftMutation,
  AssignShiftMutationVariables,
  AssignShiftProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AssignShiftMutation, AssignShiftMutationVariables, AssignShiftProps<TChildProps>>(AssignShiftDocument, {
      alias: 'assignShift',
      ...operationOptions
    });
};

/**
 * __useAssignShiftMutation__
 *
 * To run a mutation, you first call `useAssignShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignShiftMutation, { data, loading, error }] = useAssignShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignShiftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignShiftMutation, AssignShiftMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignShiftMutation, AssignShiftMutationVariables>(AssignShiftDocument, baseOptions);
      }
export type AssignShiftMutationHookResult = ReturnType<typeof useAssignShiftMutation>;
export type AssignShiftMutationResult = ApolloReactCommon.MutationResult<AssignShiftMutation>;
export type AssignShiftMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignShiftMutation, AssignShiftMutationVariables>;
export const CancelShiftDocument = gql`
    mutation CancelShift($input: CancelShiftInput!) {
  cancelShift(input: $input) {
    deletedIds
  }
}
    `;
export type CancelShiftMutationFn = ApolloReactCommon.MutationFunction<CancelShiftMutation, CancelShiftMutationVariables>;
export type CancelShiftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelShiftMutation, CancelShiftMutationVariables>, 'mutation'>;

    export const CancelShiftComponent = (props: CancelShiftComponentProps) => (
      <ApolloReactComponents.Mutation<CancelShiftMutation, CancelShiftMutationVariables> mutation={CancelShiftDocument} {...props} />
    );
    
export type CancelShiftProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CancelShiftMutation, CancelShiftMutationVariables> | TChildProps;
export function withCancelShift<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CancelShiftMutation,
  CancelShiftMutationVariables,
  CancelShiftProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CancelShiftMutation, CancelShiftMutationVariables, CancelShiftProps<TChildProps>>(CancelShiftDocument, {
      alias: 'cancelShift',
      ...operationOptions
    });
};

/**
 * __useCancelShiftMutation__
 *
 * To run a mutation, you first call `useCancelShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelShiftMutation, { data, loading, error }] = useCancelShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelShiftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelShiftMutation, CancelShiftMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelShiftMutation, CancelShiftMutationVariables>(CancelShiftDocument, baseOptions);
      }
export type CancelShiftMutationHookResult = ReturnType<typeof useCancelShiftMutation>;
export type CancelShiftMutationResult = ApolloReactCommon.MutationResult<CancelShiftMutation>;
export type CancelShiftMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelShiftMutation, CancelShiftMutationVariables>;
export const ListEmployeeShiftsDocument = gql`
    query ListEmployeeShifts($employeeId: ID!, $date: Date!, $weekStart: Date!, $weekEnd: Date!) {
  ownShifts: listSchedule(input: {employeeIds: [$employeeId], from: $date, to: $date}) {
    plans {
      id
      team {
        id
        name
      }
      shiftType {
        id
        name
      }
      job {
        id
        name
      }
      start
      end
    }
  }
  weekShifts: listSchedule(input: {employeeIds: [$employeeId], from: $weekStart, to: $weekEnd}) {
    plans {
      id
      shiftType {
        id
        duration
      }
      job {
        id
        name
      }
    }
  }
}
    `;
export type ListEmployeeShiftsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables>, 'query'> & ({ variables: ListEmployeeShiftsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ListEmployeeShiftsComponent = (props: ListEmployeeShiftsComponentProps) => (
      <ApolloReactComponents.Query<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables> query={ListEmployeeShiftsDocument} {...props} />
    );
    
export type ListEmployeeShiftsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables> | TChildProps;
export function withListEmployeeShifts<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListEmployeeShiftsQuery,
  ListEmployeeShiftsQueryVariables,
  ListEmployeeShiftsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables, ListEmployeeShiftsProps<TChildProps>>(ListEmployeeShiftsDocument, {
      alias: 'listEmployeeShifts',
      ...operationOptions
    });
};

/**
 * __useListEmployeeShiftsQuery__
 *
 * To run a query within a React component, call `useListEmployeeShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeShiftsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      date: // value for 'date'
 *      weekStart: // value for 'weekStart'
 *      weekEnd: // value for 'weekEnd'
 *   },
 * });
 */
export function useListEmployeeShiftsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables>(ListEmployeeShiftsDocument, baseOptions);
      }
export function useListEmployeeShiftsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables>(ListEmployeeShiftsDocument, baseOptions);
        }
export type ListEmployeeShiftsQueryHookResult = ReturnType<typeof useListEmployeeShiftsQuery>;
export type ListEmployeeShiftsLazyQueryHookResult = ReturnType<typeof useListEmployeeShiftsLazyQuery>;
export type ListEmployeeShiftsQueryResult = ApolloReactCommon.QueryResult<ListEmployeeShiftsQuery, ListEmployeeShiftsQueryVariables>;
export const ListShiftTeammatesDocument = gql`
    query ListShiftTeammates($teamIds: [ID!], $date: Date!) {
  listSchedule(input: {teamIds: $teamIds, from: $date, to: $date}) {
    plans {
      id
      employee {
        id
        passport {
          id
          firstName
          lastName
        }
      }
      team {
        id
        name
      }
      shiftType {
        id
        name
      }
      job {
        id
        name
      }
      start
      end
    }
  }
}
    `;
export type ListShiftTeammatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables>, 'query'> & ({ variables: ListShiftTeammatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ListShiftTeammatesComponent = (props: ListShiftTeammatesComponentProps) => (
      <ApolloReactComponents.Query<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables> query={ListShiftTeammatesDocument} {...props} />
    );
    
export type ListShiftTeammatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables> | TChildProps;
export function withListShiftTeammates<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListShiftTeammatesQuery,
  ListShiftTeammatesQueryVariables,
  ListShiftTeammatesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables, ListShiftTeammatesProps<TChildProps>>(ListShiftTeammatesDocument, {
      alias: 'listShiftTeammates',
      ...operationOptions
    });
};

/**
 * __useListShiftTeammatesQuery__
 *
 * To run a query within a React component, call `useListShiftTeammatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShiftTeammatesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShiftTeammatesQuery({
 *   variables: {
 *      teamIds: // value for 'teamIds'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useListShiftTeammatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables>(ListShiftTeammatesDocument, baseOptions);
      }
export function useListShiftTeammatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables>(ListShiftTeammatesDocument, baseOptions);
        }
export type ListShiftTeammatesQueryHookResult = ReturnType<typeof useListShiftTeammatesQuery>;
export type ListShiftTeammatesLazyQueryHookResult = ReturnType<typeof useListShiftTeammatesLazyQuery>;
export type ListShiftTeammatesQueryResult = ApolloReactCommon.QueryResult<ListShiftTeammatesQuery, ListShiftTeammatesQueryVariables>;
export const EnlistEmployeeDocument = gql`
    mutation EnlistEmployee($input: CreateEmployeeInput!) {
  enlistEmployee(input: $input) {
    employee {
      id
      passport {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type EnlistEmployeeMutationFn = ApolloReactCommon.MutationFunction<EnlistEmployeeMutation, EnlistEmployeeMutationVariables>;
export type EnlistEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnlistEmployeeMutation, EnlistEmployeeMutationVariables>, 'mutation'>;

    export const EnlistEmployeeComponent = (props: EnlistEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<EnlistEmployeeMutation, EnlistEmployeeMutationVariables> mutation={EnlistEmployeeDocument} {...props} />
    );
    
export type EnlistEmployeeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EnlistEmployeeMutation, EnlistEmployeeMutationVariables> | TChildProps;
export function withEnlistEmployee<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnlistEmployeeMutation,
  EnlistEmployeeMutationVariables,
  EnlistEmployeeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EnlistEmployeeMutation, EnlistEmployeeMutationVariables, EnlistEmployeeProps<TChildProps>>(EnlistEmployeeDocument, {
      alias: 'enlistEmployee',
      ...operationOptions
    });
};

/**
 * __useEnlistEmployeeMutation__
 *
 * To run a mutation, you first call `useEnlistEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnlistEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enlistEmployeeMutation, { data, loading, error }] = useEnlistEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnlistEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnlistEmployeeMutation, EnlistEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<EnlistEmployeeMutation, EnlistEmployeeMutationVariables>(EnlistEmployeeDocument, baseOptions);
      }
export type EnlistEmployeeMutationHookResult = ReturnType<typeof useEnlistEmployeeMutation>;
export type EnlistEmployeeMutationResult = ApolloReactCommon.MutationResult<EnlistEmployeeMutation>;
export type EnlistEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<EnlistEmployeeMutation, EnlistEmployeeMutationVariables>;
export const AcceptInviteDocument = gql`
    mutation AcceptInvite($input: GetPendingInviteInput!) {
  acceptEmployeeInvite(input: $input) {
    employee {
      id
      company {
        id
      }
    }
  }
}
    `;
export type AcceptInviteMutationFn = ApolloReactCommon.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;
export type AcceptInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptInviteMutation, AcceptInviteMutationVariables>, 'mutation'>;

    export const AcceptInviteComponent = (props: AcceptInviteComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptInviteMutation, AcceptInviteMutationVariables> mutation={AcceptInviteDocument} {...props} />
    );
    
export type AcceptInviteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AcceptInviteMutation, AcceptInviteMutationVariables> | TChildProps;
export function withAcceptInvite<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
  AcceptInviteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptInviteMutation, AcceptInviteMutationVariables, AcceptInviteProps<TChildProps>>(AcceptInviteDocument, {
      alias: 'acceptInvite',
      ...operationOptions
    });
};

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, baseOptions);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = ApolloReactCommon.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    passport {
      id
      type
      firstName
      lastName
      employments {
        id
        role
        company {
          id
          name
        }
      }
    }
  }
}
    `;
export type MeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MeQuery, MeQueryVariables>, 'query'>;

    export const MeComponent = (props: MeComponentProps) => (
      <ApolloReactComponents.Query<MeQuery, MeQueryVariables> query={MeDocument} {...props} />
    );
    
export type MeProps<TChildProps = {}> = ApolloReactHoc.DataProps<MeQuery, MeQueryVariables> | TChildProps;
export function withMe<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MeQuery,
  MeQueryVariables,
  MeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, MeQuery, MeQueryVariables, MeProps<TChildProps>>(MeDocument, {
      alias: 'me',
      ...operationOptions
    });
};

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateEmployeeCardDocument = gql`
    mutation UpdateEmployeeCard($input: UpdateEmployeeInput!) {
  updateEmployeeCard(input: $input) {
    employee {
      id
      firedDate
      hiredDate
      passport {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type UpdateEmployeeCardMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables>;
export type UpdateEmployeeCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables>, 'mutation'>;

    export const UpdateEmployeeCardComponent = (props: UpdateEmployeeCardComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables> mutation={UpdateEmployeeCardDocument} {...props} />
    );
    
export type UpdateEmployeeCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables> | TChildProps;
export function withUpdateEmployeeCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEmployeeCardMutation,
  UpdateEmployeeCardMutationVariables,
  UpdateEmployeeCardProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables, UpdateEmployeeCardProps<TChildProps>>(UpdateEmployeeCardDocument, {
      alias: 'updateEmployeeCard',
      ...operationOptions
    });
};

/**
 * __useUpdateEmployeeCardMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeCardMutation, { data, loading, error }] = useUpdateEmployeeCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables>(UpdateEmployeeCardDocument, baseOptions);
      }
export type UpdateEmployeeCardMutationHookResult = ReturnType<typeof useUpdateEmployeeCardMutation>;
export type UpdateEmployeeCardMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeCardMutation>;
export type UpdateEmployeeCardMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeCardMutation, UpdateEmployeeCardMutationVariables>;
export const UpdateJobCardDocument = gql`
    mutation UpdateJobCard($input: UpdateJobInput!) {
  updateJobCard(input: $input) {
    job {
      id
      name
      qualifications {
        id
        name
      }
    }
  }
}
    `;
export type UpdateJobCardMutationFn = ApolloReactCommon.MutationFunction<UpdateJobCardMutation, UpdateJobCardMutationVariables>;
export type UpdateJobCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateJobCardMutation, UpdateJobCardMutationVariables>, 'mutation'>;

    export const UpdateJobCardComponent = (props: UpdateJobCardComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateJobCardMutation, UpdateJobCardMutationVariables> mutation={UpdateJobCardDocument} {...props} />
    );
    
export type UpdateJobCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateJobCardMutation, UpdateJobCardMutationVariables> | TChildProps;
export function withUpdateJobCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateJobCardMutation,
  UpdateJobCardMutationVariables,
  UpdateJobCardProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateJobCardMutation, UpdateJobCardMutationVariables, UpdateJobCardProps<TChildProps>>(UpdateJobCardDocument, {
      alias: 'updateJobCard',
      ...operationOptions
    });
};

/**
 * __useUpdateJobCardMutation__
 *
 * To run a mutation, you first call `useUpdateJobCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobCardMutation, { data, loading, error }] = useUpdateJobCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJobCardMutation, UpdateJobCardMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJobCardMutation, UpdateJobCardMutationVariables>(UpdateJobCardDocument, baseOptions);
      }
export type UpdateJobCardMutationHookResult = ReturnType<typeof useUpdateJobCardMutation>;
export type UpdateJobCardMutationResult = ApolloReactCommon.MutationResult<UpdateJobCardMutation>;
export type UpdateJobCardMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJobCardMutation, UpdateJobCardMutationVariables>;
export const ShiftTypeCardDocument = gql`
    query ShiftTypeCard($shiftTypeId: ID!) {
  getShiftType(input: {id: $shiftTypeId}) {
    shiftType {
      ...ShiftTypeFace
      ...ShiftTypeTimes
      team {
        id
        shiftTypes {
          id
          name
        }
      }
      lastUsedDate
    }
  }
}
    ${ShiftTypeFaceFragmentDoc}
${ShiftTypeTimesFragmentDoc}`;
export type ShiftTypeCardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ShiftTypeCardQuery, ShiftTypeCardQueryVariables>, 'query'> & ({ variables: ShiftTypeCardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ShiftTypeCardComponent = (props: ShiftTypeCardComponentProps) => (
      <ApolloReactComponents.Query<ShiftTypeCardQuery, ShiftTypeCardQueryVariables> query={ShiftTypeCardDocument} {...props} />
    );
    
export type ShiftTypeCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<ShiftTypeCardQuery, ShiftTypeCardQueryVariables> | TChildProps;
export function withShiftTypeCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ShiftTypeCardQuery,
  ShiftTypeCardQueryVariables,
  ShiftTypeCardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ShiftTypeCardQuery, ShiftTypeCardQueryVariables, ShiftTypeCardProps<TChildProps>>(ShiftTypeCardDocument, {
      alias: 'shiftTypeCard',
      ...operationOptions
    });
};

/**
 * __useShiftTypeCardQuery__
 *
 * To run a query within a React component, call `useShiftTypeCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftTypeCardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftTypeCardQuery({
 *   variables: {
 *      shiftTypeId: // value for 'shiftTypeId'
 *   },
 * });
 */
export function useShiftTypeCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftTypeCardQuery, ShiftTypeCardQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftTypeCardQuery, ShiftTypeCardQueryVariables>(ShiftTypeCardDocument, baseOptions);
      }
export function useShiftTypeCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftTypeCardQuery, ShiftTypeCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftTypeCardQuery, ShiftTypeCardQueryVariables>(ShiftTypeCardDocument, baseOptions);
        }
export type ShiftTypeCardQueryHookResult = ReturnType<typeof useShiftTypeCardQuery>;
export type ShiftTypeCardLazyQueryHookResult = ReturnType<typeof useShiftTypeCardLazyQuery>;
export type ShiftTypeCardQueryResult = ApolloReactCommon.QueryResult<ShiftTypeCardQuery, ShiftTypeCardQueryVariables>;
export const UpdateShiftTypeDocument = gql`
    mutation UpdateShiftType($input: UpdateShiftTypeInput!) {
  updateShiftType(input: $input) {
    shiftType {
      ...ShiftTypeFace
      ...ShiftTypeTimes
    }
  }
}
    ${ShiftTypeFaceFragmentDoc}
${ShiftTypeTimesFragmentDoc}`;
export type UpdateShiftTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables>;
export type UpdateShiftTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables>, 'mutation'>;

    export const UpdateShiftTypeComponent = (props: UpdateShiftTypeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables> mutation={UpdateShiftTypeDocument} {...props} />
    );
    
export type UpdateShiftTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables> | TChildProps;
export function withUpdateShiftType<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateShiftTypeMutation,
  UpdateShiftTypeMutationVariables,
  UpdateShiftTypeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables, UpdateShiftTypeProps<TChildProps>>(UpdateShiftTypeDocument, {
      alias: 'updateShiftType',
      ...operationOptions
    });
};

/**
 * __useUpdateShiftTypeMutation__
 *
 * To run a mutation, you first call `useUpdateShiftTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftTypeMutation, { data, loading, error }] = useUpdateShiftTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables>(UpdateShiftTypeDocument, baseOptions);
      }
export type UpdateShiftTypeMutationHookResult = ReturnType<typeof useUpdateShiftTypeMutation>;
export type UpdateShiftTypeMutationResult = ApolloReactCommon.MutationResult<UpdateShiftTypeMutation>;
export type UpdateShiftTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables>;
export const UpdateTeamCardDocument = gql`
    mutation UpdateTeamCard($input: UpdateTeamInput!) {
  updateTeamCard(input: $input) {
    team {
      id
      name
    }
  }
}
    `;
export type UpdateTeamCardMutationFn = ApolloReactCommon.MutationFunction<UpdateTeamCardMutation, UpdateTeamCardMutationVariables>;
export type UpdateTeamCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTeamCardMutation, UpdateTeamCardMutationVariables>, 'mutation'>;

    export const UpdateTeamCardComponent = (props: UpdateTeamCardComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTeamCardMutation, UpdateTeamCardMutationVariables> mutation={UpdateTeamCardDocument} {...props} />
    );
    
export type UpdateTeamCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateTeamCardMutation, UpdateTeamCardMutationVariables> | TChildProps;
export function withUpdateTeamCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTeamCardMutation,
  UpdateTeamCardMutationVariables,
  UpdateTeamCardProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTeamCardMutation, UpdateTeamCardMutationVariables, UpdateTeamCardProps<TChildProps>>(UpdateTeamCardDocument, {
      alias: 'updateTeamCard',
      ...operationOptions
    });
};

/**
 * __useUpdateTeamCardMutation__
 *
 * To run a mutation, you first call `useUpdateTeamCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamCardMutation, { data, loading, error }] = useUpdateTeamCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamCardMutation, UpdateTeamCardMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTeamCardMutation, UpdateTeamCardMutationVariables>(UpdateTeamCardDocument, baseOptions);
      }
export type UpdateTeamCardMutationHookResult = ReturnType<typeof useUpdateTeamCardMutation>;
export type UpdateTeamCardMutationResult = ApolloReactCommon.MutationResult<UpdateTeamCardMutation>;
export type UpdateTeamCardMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeamCardMutation, UpdateTeamCardMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    company {
      id
      name
    }
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export type CreateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCompanyMutation, CreateCompanyMutationVariables>, 'mutation'>;

    export const CreateCompanyComponent = (props: CreateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> mutation={CreateCompanyDocument} {...props} />
    );
    
export type CreateCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCompanyMutation, CreateCompanyMutationVariables> | TChildProps;
export function withCreateCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  CreateCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCompanyMutation, CreateCompanyMutationVariables, CreateCompanyProps<TChildProps>>(CreateCompanyDocument, {
      alias: 'createCompany',
      ...operationOptions
    });
};

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompanyCard(input: $input) {
    company {
      id
      name
      slug
    }
  }
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export type UpdateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>, 'mutation'>;

    export const UpdateCompanyComponent = (props: UpdateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> mutation={UpdateCompanyDocument} {...props} />
    );
    
export type UpdateCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCompanyMutation, UpdateCompanyMutationVariables> | TChildProps;
export function withUpdateCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdateCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyMutation, UpdateCompanyMutationVariables, UpdateCompanyProps<TChildProps>>(UpdateCompanyDocument, {
      alias: 'updateCompany',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const EmployeeMetaDocument = gql`
    query EmployeeMeta($employeeId: ID!) {
  listEmployees(input: {employeeIds: [$employeeId]}) {
    employees {
      id
      role
      passport {
        id
        type
        firstName
        lastName
      }
      company {
        id
        name
      }
      invites {
        id
      }
    }
  }
}
    `;
export type EmployeeMetaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmployeeMetaQuery, EmployeeMetaQueryVariables>, 'query'> & ({ variables: EmployeeMetaQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmployeeMetaComponent = (props: EmployeeMetaComponentProps) => (
      <ApolloReactComponents.Query<EmployeeMetaQuery, EmployeeMetaQueryVariables> query={EmployeeMetaDocument} {...props} />
    );
    
export type EmployeeMetaProps<TChildProps = {}> = ApolloReactHoc.DataProps<EmployeeMetaQuery, EmployeeMetaQueryVariables> | TChildProps;
export function withEmployeeMeta<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmployeeMetaQuery,
  EmployeeMetaQueryVariables,
  EmployeeMetaProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, EmployeeMetaQuery, EmployeeMetaQueryVariables, EmployeeMetaProps<TChildProps>>(EmployeeMetaDocument, {
      alias: 'employeeMeta',
      ...operationOptions
    });
};

/**
 * __useEmployeeMetaQuery__
 *
 * To run a query within a React component, call `useEmployeeMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeMetaQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useEmployeeMetaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeMetaQuery, EmployeeMetaQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeMetaQuery, EmployeeMetaQueryVariables>(EmployeeMetaDocument, baseOptions);
      }
export function useEmployeeMetaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeMetaQuery, EmployeeMetaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeMetaQuery, EmployeeMetaQueryVariables>(EmployeeMetaDocument, baseOptions);
        }
export type EmployeeMetaQueryHookResult = ReturnType<typeof useEmployeeMetaQuery>;
export type EmployeeMetaLazyQueryHookResult = ReturnType<typeof useEmployeeMetaLazyQuery>;
export type EmployeeMetaQueryResult = ApolloReactCommon.QueryResult<EmployeeMetaQuery, EmployeeMetaQueryVariables>;
export const SetEmployeeRoleDocument = gql`
    mutation SetEmployeeRole($input: SetEmployeeRoleInput!) {
  setEmployeeRole(input: $input) {
    employee {
      id
      role
    }
  }
}
    `;
export type SetEmployeeRoleMutationFn = ApolloReactCommon.MutationFunction<SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables>;
export type SetEmployeeRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables>, 'mutation'>;

    export const SetEmployeeRoleComponent = (props: SetEmployeeRoleComponentProps) => (
      <ApolloReactComponents.Mutation<SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables> mutation={SetEmployeeRoleDocument} {...props} />
    );
    
export type SetEmployeeRoleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables> | TChildProps;
export function withSetEmployeeRole<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetEmployeeRoleMutation,
  SetEmployeeRoleMutationVariables,
  SetEmployeeRoleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables, SetEmployeeRoleProps<TChildProps>>(SetEmployeeRoleDocument, {
      alias: 'setEmployeeRole',
      ...operationOptions
    });
};

/**
 * __useSetEmployeeRoleMutation__
 *
 * To run a mutation, you first call `useSetEmployeeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmployeeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmployeeRoleMutation, { data, loading, error }] = useSetEmployeeRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEmployeeRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables>(SetEmployeeRoleDocument, baseOptions);
      }
export type SetEmployeeRoleMutationHookResult = ReturnType<typeof useSetEmployeeRoleMutation>;
export type SetEmployeeRoleMutationResult = ApolloReactCommon.MutationResult<SetEmployeeRoleMutation>;
export type SetEmployeeRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<SetEmployeeRoleMutation, SetEmployeeRoleMutationVariables>;
export const ToggleEmployeeInviteDocument = gql`
    mutation ToggleEmployeeInvite($input: ToggleEmployeeInviteInput!) {
  toggleEmployeeInvite(input: $input) {
    invites {
      id
    }
  }
}
    `;
export type ToggleEmployeeInviteMutationFn = ApolloReactCommon.MutationFunction<ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables>;
export type ToggleEmployeeInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables>, 'mutation'>;

    export const ToggleEmployeeInviteComponent = (props: ToggleEmployeeInviteComponentProps) => (
      <ApolloReactComponents.Mutation<ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables> mutation={ToggleEmployeeInviteDocument} {...props} />
    );
    
export type ToggleEmployeeInviteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables> | TChildProps;
export function withToggleEmployeeInvite<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ToggleEmployeeInviteMutation,
  ToggleEmployeeInviteMutationVariables,
  ToggleEmployeeInviteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables, ToggleEmployeeInviteProps<TChildProps>>(ToggleEmployeeInviteDocument, {
      alias: 'toggleEmployeeInvite',
      ...operationOptions
    });
};

/**
 * __useToggleEmployeeInviteMutation__
 *
 * To run a mutation, you first call `useToggleEmployeeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEmployeeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEmployeeInviteMutation, { data, loading, error }] = useToggleEmployeeInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleEmployeeInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables>(ToggleEmployeeInviteDocument, baseOptions);
      }
export type ToggleEmployeeInviteMutationHookResult = ReturnType<typeof useToggleEmployeeInviteMutation>;
export type ToggleEmployeeInviteMutationResult = ApolloReactCommon.MutationResult<ToggleEmployeeInviteMutation>;
export type ToggleEmployeeInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleEmployeeInviteMutation, ToggleEmployeeInviteMutationVariables>;
export const EmployeeCardDocument = gql`
    query EmployeeCard($employeeId: ID!) {
  listEmployees(input: {employeeIds: [$employeeId]}) {
    employees {
      id
      passport {
        id
        firstName
        lastName
      }
      hiredDate
      firedDate
    }
  }
}
    `;
export type EmployeeCardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmployeeCardQuery, EmployeeCardQueryVariables>, 'query'> & ({ variables: EmployeeCardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmployeeCardComponent = (props: EmployeeCardComponentProps) => (
      <ApolloReactComponents.Query<EmployeeCardQuery, EmployeeCardQueryVariables> query={EmployeeCardDocument} {...props} />
    );
    
export type EmployeeCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<EmployeeCardQuery, EmployeeCardQueryVariables> | TChildProps;
export function withEmployeeCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmployeeCardQuery,
  EmployeeCardQueryVariables,
  EmployeeCardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, EmployeeCardQuery, EmployeeCardQueryVariables, EmployeeCardProps<TChildProps>>(EmployeeCardDocument, {
      alias: 'employeeCard',
      ...operationOptions
    });
};

/**
 * __useEmployeeCardQuery__
 *
 * To run a query within a React component, call `useEmployeeCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCardQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useEmployeeCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeCardQuery, EmployeeCardQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeCardQuery, EmployeeCardQueryVariables>(EmployeeCardDocument, baseOptions);
      }
export function useEmployeeCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeCardQuery, EmployeeCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeCardQuery, EmployeeCardQueryVariables>(EmployeeCardDocument, baseOptions);
        }
export type EmployeeCardQueryHookResult = ReturnType<typeof useEmployeeCardQuery>;
export type EmployeeCardLazyQueryHookResult = ReturnType<typeof useEmployeeCardLazyQuery>;
export type EmployeeCardQueryResult = ApolloReactCommon.QueryResult<EmployeeCardQuery, EmployeeCardQueryVariables>;
export const ListAllEmployeesDocument = gql`
    query ListAllEmployees {
  listEmployees(input: {}) {
    employees {
      id
      passport {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type ListAllEmployeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListAllEmployeesQuery, ListAllEmployeesQueryVariables>, 'query'>;

    export const ListAllEmployeesComponent = (props: ListAllEmployeesComponentProps) => (
      <ApolloReactComponents.Query<ListAllEmployeesQuery, ListAllEmployeesQueryVariables> query={ListAllEmployeesDocument} {...props} />
    );
    
export type ListAllEmployeesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListAllEmployeesQuery, ListAllEmployeesQueryVariables> | TChildProps;
export function withListAllEmployees<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListAllEmployeesQuery,
  ListAllEmployeesQueryVariables,
  ListAllEmployeesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListAllEmployeesQuery, ListAllEmployeesQueryVariables, ListAllEmployeesProps<TChildProps>>(ListAllEmployeesDocument, {
      alias: 'listAllEmployees',
      ...operationOptions
    });
};

/**
 * __useListAllEmployeesQuery__
 *
 * To run a query within a React component, call `useListAllEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllEmployeesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAllEmployeesQuery, ListAllEmployeesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAllEmployeesQuery, ListAllEmployeesQueryVariables>(ListAllEmployeesDocument, baseOptions);
      }
export function useListAllEmployeesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAllEmployeesQuery, ListAllEmployeesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAllEmployeesQuery, ListAllEmployeesQueryVariables>(ListAllEmployeesDocument, baseOptions);
        }
export type ListAllEmployeesQueryHookResult = ReturnType<typeof useListAllEmployeesQuery>;
export type ListAllEmployeesLazyQueryHookResult = ReturnType<typeof useListAllEmployeesLazyQuery>;
export type ListAllEmployeesQueryResult = ApolloReactCommon.QueryResult<ListAllEmployeesQuery, ListAllEmployeesQueryVariables>;
export const JobCardDocument = gql`
    query JobCard($jobId: ID!) {
  listJobs(input: {jobIds: [$jobId]}) {
    jobs {
      id
      name
      qualifications {
        id
        name
      }
    }
  }
}
    `;
export type JobCardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<JobCardQuery, JobCardQueryVariables>, 'query'> & ({ variables: JobCardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const JobCardComponent = (props: JobCardComponentProps) => (
      <ApolloReactComponents.Query<JobCardQuery, JobCardQueryVariables> query={JobCardDocument} {...props} />
    );
    
export type JobCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<JobCardQuery, JobCardQueryVariables> | TChildProps;
export function withJobCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  JobCardQuery,
  JobCardQueryVariables,
  JobCardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, JobCardQuery, JobCardQueryVariables, JobCardProps<TChildProps>>(JobCardDocument, {
      alias: 'jobCard',
      ...operationOptions
    });
};

/**
 * __useJobCardQuery__
 *
 * To run a query within a React component, call `useJobCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobCardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobCardQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobCardQuery, JobCardQueryVariables>) {
        return ApolloReactHooks.useQuery<JobCardQuery, JobCardQueryVariables>(JobCardDocument, baseOptions);
      }
export function useJobCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobCardQuery, JobCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobCardQuery, JobCardQueryVariables>(JobCardDocument, baseOptions);
        }
export type JobCardQueryHookResult = ReturnType<typeof useJobCardQuery>;
export type JobCardLazyQueryHookResult = ReturnType<typeof useJobCardLazyQuery>;
export type JobCardQueryResult = ApolloReactCommon.QueryResult<JobCardQuery, JobCardQueryVariables>;
export const ListAllJobsDocument = gql`
    query ListAllJobs($input: ListJobsInput!) {
  listJobs(input: $input) {
    jobs {
      id
      name
    }
  }
}
    `;
export type ListAllJobsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListAllJobsQuery, ListAllJobsQueryVariables>, 'query'> & ({ variables: ListAllJobsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ListAllJobsComponent = (props: ListAllJobsComponentProps) => (
      <ApolloReactComponents.Query<ListAllJobsQuery, ListAllJobsQueryVariables> query={ListAllJobsDocument} {...props} />
    );
    
export type ListAllJobsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListAllJobsQuery, ListAllJobsQueryVariables> | TChildProps;
export function withListAllJobs<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListAllJobsQuery,
  ListAllJobsQueryVariables,
  ListAllJobsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListAllJobsQuery, ListAllJobsQueryVariables, ListAllJobsProps<TChildProps>>(ListAllJobsDocument, {
      alias: 'listAllJobs',
      ...operationOptions
    });
};

/**
 * __useListAllJobsQuery__
 *
 * To run a query within a React component, call `useListAllJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllJobsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAllJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAllJobsQuery, ListAllJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAllJobsQuery, ListAllJobsQueryVariables>(ListAllJobsDocument, baseOptions);
      }
export function useListAllJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAllJobsQuery, ListAllJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAllJobsQuery, ListAllJobsQueryVariables>(ListAllJobsDocument, baseOptions);
        }
export type ListAllJobsQueryHookResult = ReturnType<typeof useListAllJobsQuery>;
export type ListAllJobsLazyQueryHookResult = ReturnType<typeof useListAllJobsLazyQuery>;
export type ListAllJobsQueryResult = ApolloReactCommon.QueryResult<ListAllJobsQuery, ListAllJobsQueryVariables>;
export const EmployeeInviteDocument = gql`
    query EmployeeInvite($inviteId: ID!) {
  getPendingInvite(input: {inviteId: $inviteId}) {
    inviteMeta {
      firstName
      companyName
      companyId
    }
  }
}
    `;
export type EmployeeInviteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmployeeInviteQuery, EmployeeInviteQueryVariables>, 'query'> & ({ variables: EmployeeInviteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmployeeInviteComponent = (props: EmployeeInviteComponentProps) => (
      <ApolloReactComponents.Query<EmployeeInviteQuery, EmployeeInviteQueryVariables> query={EmployeeInviteDocument} {...props} />
    );
    
export type EmployeeInviteProps<TChildProps = {}> = ApolloReactHoc.DataProps<EmployeeInviteQuery, EmployeeInviteQueryVariables> | TChildProps;
export function withEmployeeInvite<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmployeeInviteQuery,
  EmployeeInviteQueryVariables,
  EmployeeInviteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, EmployeeInviteQuery, EmployeeInviteQueryVariables, EmployeeInviteProps<TChildProps>>(EmployeeInviteDocument, {
      alias: 'employeeInvite',
      ...operationOptions
    });
};

/**
 * __useEmployeeInviteQuery__
 *
 * To run a query within a React component, call `useEmployeeInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeInviteQuery({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useEmployeeInviteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeInviteQuery, EmployeeInviteQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeInviteQuery, EmployeeInviteQueryVariables>(EmployeeInviteDocument, baseOptions);
      }
export function useEmployeeInviteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeInviteQuery, EmployeeInviteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeInviteQuery, EmployeeInviteQueryVariables>(EmployeeInviteDocument, baseOptions);
        }
export type EmployeeInviteQueryHookResult = ReturnType<typeof useEmployeeInviteQuery>;
export type EmployeeInviteLazyQueryHookResult = ReturnType<typeof useEmployeeInviteLazyQuery>;
export type EmployeeInviteQueryResult = ApolloReactCommon.QueryResult<EmployeeInviteQuery, EmployeeInviteQueryVariables>;
export const ListScheduleDocument = gql`
    query ListSchedule($from: Date!, $to: Date!) {
  listTeams(input: {}) {
    teams {
      id
      name
      members {
        employee {
          id
          passport {
            id
            lastName
            firstName
          }
        }
        job {
          id
          name
        }
      }
    }
  }
  listSchedule(input: {from: $from, to: $to}) {
    plans {
      id
      team {
        id
        name
      }
      employee {
        id
        passport {
          id
          lastName
          firstName
        }
      }
      date
      shiftType {
        ...ShiftTypeFace
        ...ShiftTypeTimes
      }
      job {
        id
        name
      }
    }
  }
}
    ${ShiftTypeFaceFragmentDoc}
${ShiftTypeTimesFragmentDoc}`;
export type ListScheduleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListScheduleQuery, ListScheduleQueryVariables>, 'query'> & ({ variables: ListScheduleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ListScheduleComponent = (props: ListScheduleComponentProps) => (
      <ApolloReactComponents.Query<ListScheduleQuery, ListScheduleQueryVariables> query={ListScheduleDocument} {...props} />
    );
    
export type ListScheduleProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListScheduleQuery, ListScheduleQueryVariables> | TChildProps;
export function withListSchedule<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListScheduleQuery,
  ListScheduleQueryVariables,
  ListScheduleProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListScheduleQuery, ListScheduleQueryVariables, ListScheduleProps<TChildProps>>(ListScheduleDocument, {
      alias: 'listSchedule',
      ...operationOptions
    });
};

/**
 * __useListScheduleQuery__
 *
 * To run a query within a React component, call `useListScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScheduleQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useListScheduleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListScheduleQuery, ListScheduleQueryVariables>) {
        return ApolloReactHooks.useQuery<ListScheduleQuery, ListScheduleQueryVariables>(ListScheduleDocument, baseOptions);
      }
export function useListScheduleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListScheduleQuery, ListScheduleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListScheduleQuery, ListScheduleQueryVariables>(ListScheduleDocument, baseOptions);
        }
export type ListScheduleQueryHookResult = ReturnType<typeof useListScheduleQuery>;
export type ListScheduleLazyQueryHookResult = ReturnType<typeof useListScheduleLazyQuery>;
export type ListScheduleQueryResult = ApolloReactCommon.QueryResult<ListScheduleQuery, ListScheduleQueryVariables>;
export const TeamCardDocument = gql`
    query TeamCard($teamId: ID!) {
  listTeams(input: {teamIds: [$teamId]}) {
    teams {
      id
      name
      shiftTypes {
        ...ShiftTypeFace
        ...ShiftTypeTimes
      }
      members {
        employee {
          id
          passport {
            id
            firstName
            lastName
          }
        }
        job {
          id
          name
        }
      }
    }
  }
}
    ${ShiftTypeFaceFragmentDoc}
${ShiftTypeTimesFragmentDoc}`;
export type TeamCardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamCardQuery, TeamCardQueryVariables>, 'query'> & ({ variables: TeamCardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamCardComponent = (props: TeamCardComponentProps) => (
      <ApolloReactComponents.Query<TeamCardQuery, TeamCardQueryVariables> query={TeamCardDocument} {...props} />
    );
    
export type TeamCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<TeamCardQuery, TeamCardQueryVariables> | TChildProps;
export function withTeamCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamCardQuery,
  TeamCardQueryVariables,
  TeamCardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, TeamCardQuery, TeamCardQueryVariables, TeamCardProps<TChildProps>>(TeamCardDocument, {
      alias: 'teamCard',
      ...operationOptions
    });
};

/**
 * __useTeamCardQuery__
 *
 * To run a query within a React component, call `useTeamCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCardQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamCardQuery, TeamCardQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamCardQuery, TeamCardQueryVariables>(TeamCardDocument, baseOptions);
      }
export function useTeamCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamCardQuery, TeamCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamCardQuery, TeamCardQueryVariables>(TeamCardDocument, baseOptions);
        }
export type TeamCardQueryHookResult = ReturnType<typeof useTeamCardQuery>;
export type TeamCardLazyQueryHookResult = ReturnType<typeof useTeamCardLazyQuery>;
export type TeamCardQueryResult = ApolloReactCommon.QueryResult<TeamCardQuery, TeamCardQueryVariables>;
export const ListAllTeamsDocument = gql`
    query ListAllTeams {
  listTeams(input: {}) {
    teams {
      id
      name
    }
  }
}
    `;
export type ListAllTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListAllTeamsQuery, ListAllTeamsQueryVariables>, 'query'>;

    export const ListAllTeamsComponent = (props: ListAllTeamsComponentProps) => (
      <ApolloReactComponents.Query<ListAllTeamsQuery, ListAllTeamsQueryVariables> query={ListAllTeamsDocument} {...props} />
    );
    
export type ListAllTeamsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListAllTeamsQuery, ListAllTeamsQueryVariables> | TChildProps;
export function withListAllTeams<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListAllTeamsQuery,
  ListAllTeamsQueryVariables,
  ListAllTeamsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListAllTeamsQuery, ListAllTeamsQueryVariables, ListAllTeamsProps<TChildProps>>(ListAllTeamsDocument, {
      alias: 'listAllTeams',
      ...operationOptions
    });
};

/**
 * __useListAllTeamsQuery__
 *
 * To run a query within a React component, call `useListAllTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAllTeamsQuery, ListAllTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAllTeamsQuery, ListAllTeamsQueryVariables>(ListAllTeamsDocument, baseOptions);
      }
export function useListAllTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAllTeamsQuery, ListAllTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAllTeamsQuery, ListAllTeamsQueryVariables>(ListAllTeamsDocument, baseOptions);
        }
export type ListAllTeamsQueryHookResult = ReturnType<typeof useListAllTeamsQuery>;
export type ListAllTeamsLazyQueryHookResult = ReturnType<typeof useListAllTeamsLazyQuery>;
export type ListAllTeamsQueryResult = ApolloReactCommon.QueryResult<ListAllTeamsQuery, ListAllTeamsQueryVariables>;
export const ListAllCompaniesDocument = gql`
    query ListAllCompanies {
  listCompanies(input: {}) {
    companies {
      id
      name
    }
  }
}
    `;
export type ListAllCompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListAllCompaniesQuery, ListAllCompaniesQueryVariables>, 'query'>;

    export const ListAllCompaniesComponent = (props: ListAllCompaniesComponentProps) => (
      <ApolloReactComponents.Query<ListAllCompaniesQuery, ListAllCompaniesQueryVariables> query={ListAllCompaniesDocument} {...props} />
    );
    
export type ListAllCompaniesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListAllCompaniesQuery, ListAllCompaniesQueryVariables> | TChildProps;
export function withListAllCompanies<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListAllCompaniesQuery,
  ListAllCompaniesQueryVariables,
  ListAllCompaniesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListAllCompaniesQuery, ListAllCompaniesQueryVariables, ListAllCompaniesProps<TChildProps>>(ListAllCompaniesDocument, {
      alias: 'listAllCompanies',
      ...operationOptions
    });
};

/**
 * __useListAllCompaniesQuery__
 *
 * To run a query within a React component, call `useListAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAllCompaniesQuery, ListAllCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAllCompaniesQuery, ListAllCompaniesQueryVariables>(ListAllCompaniesDocument, baseOptions);
      }
export function useListAllCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAllCompaniesQuery, ListAllCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAllCompaniesQuery, ListAllCompaniesQueryVariables>(ListAllCompaniesDocument, baseOptions);
        }
export type ListAllCompaniesQueryHookResult = ReturnType<typeof useListAllCompaniesQuery>;
export type ListAllCompaniesLazyQueryHookResult = ReturnType<typeof useListAllCompaniesLazyQuery>;
export type ListAllCompaniesQueryResult = ApolloReactCommon.QueryResult<ListAllCompaniesQuery, ListAllCompaniesQueryVariables>;
export const CompanyCardDocument = gql`
    query CompanyCard($companyId: ID!) {
  listCompanies(input: {companyIds: [$companyId]}) {
    companies {
      id
      name
      slug
      employees {
        id
        role
        passport {
          id
          type
          firstName
          lastName
        }
      }
      createdStamp
    }
  }
}
    `;
export type CompanyCardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompanyCardQuery, CompanyCardQueryVariables>, 'query'> & ({ variables: CompanyCardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompanyCardComponent = (props: CompanyCardComponentProps) => (
      <ApolloReactComponents.Query<CompanyCardQuery, CompanyCardQueryVariables> query={CompanyCardDocument} {...props} />
    );
    
export type CompanyCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<CompanyCardQuery, CompanyCardQueryVariables> | TChildProps;
export function withCompanyCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompanyCardQuery,
  CompanyCardQueryVariables,
  CompanyCardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CompanyCardQuery, CompanyCardQueryVariables, CompanyCardProps<TChildProps>>(CompanyCardDocument, {
      alias: 'companyCard',
      ...operationOptions
    });
};

/**
 * __useCompanyCardQuery__
 *
 * To run a query within a React component, call `useCompanyCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCardQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyCardQuery, CompanyCardQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyCardQuery, CompanyCardQueryVariables>(CompanyCardDocument, baseOptions);
      }
export function useCompanyCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyCardQuery, CompanyCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyCardQuery, CompanyCardQueryVariables>(CompanyCardDocument, baseOptions);
        }
export type CompanyCardQueryHookResult = ReturnType<typeof useCompanyCardQuery>;
export type CompanyCardLazyQueryHookResult = ReturnType<typeof useCompanyCardLazyQuery>;
export type CompanyCardQueryResult = ApolloReactCommon.QueryResult<CompanyCardQuery, CompanyCardQueryVariables>;
export const PassportCardDocument = gql`
    query PassportCard($passportId: ID!) {
  listPassports(input: {passportIds: [$passportId]}) {
    passports {
      id
      type
      firstName
      lastName
      employments {
        id
        role
        company {
          id
          name
        }
      }
      createdStamp
    }
  }
}
    `;
export type PassportCardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PassportCardQuery, PassportCardQueryVariables>, 'query'> & ({ variables: PassportCardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PassportCardComponent = (props: PassportCardComponentProps) => (
      <ApolloReactComponents.Query<PassportCardQuery, PassportCardQueryVariables> query={PassportCardDocument} {...props} />
    );
    
export type PassportCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<PassportCardQuery, PassportCardQueryVariables> | TChildProps;
export function withPassportCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PassportCardQuery,
  PassportCardQueryVariables,
  PassportCardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PassportCardQuery, PassportCardQueryVariables, PassportCardProps<TChildProps>>(PassportCardDocument, {
      alias: 'passportCard',
      ...operationOptions
    });
};

/**
 * __usePassportCardQuery__
 *
 * To run a query within a React component, call `usePassportCardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePassportCardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePassportCardQuery({
 *   variables: {
 *      passportId: // value for 'passportId'
 *   },
 * });
 */
export function usePassportCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PassportCardQuery, PassportCardQueryVariables>) {
        return ApolloReactHooks.useQuery<PassportCardQuery, PassportCardQueryVariables>(PassportCardDocument, baseOptions);
      }
export function usePassportCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PassportCardQuery, PassportCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PassportCardQuery, PassportCardQueryVariables>(PassportCardDocument, baseOptions);
        }
export type PassportCardQueryHookResult = ReturnType<typeof usePassportCardQuery>;
export type PassportCardLazyQueryHookResult = ReturnType<typeof usePassportCardLazyQuery>;
export type PassportCardQueryResult = ApolloReactCommon.QueryResult<PassportCardQuery, PassportCardQueryVariables>;
export const OrgContextDocument = gql`
    query orgContext {
  currentOrgId @client
}
    `;
export type OrgContextComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgContextQuery, OrgContextQueryVariables>, 'query'>;

    export const OrgContextComponent = (props: OrgContextComponentProps) => (
      <ApolloReactComponents.Query<OrgContextQuery, OrgContextQueryVariables> query={OrgContextDocument} {...props} />
    );
    
export type OrgContextProps<TChildProps = {}> = ApolloReactHoc.DataProps<OrgContextQuery, OrgContextQueryVariables> | TChildProps;
export function withOrgContext<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrgContextQuery,
  OrgContextQueryVariables,
  OrgContextProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, OrgContextQuery, OrgContextQueryVariables, OrgContextProps<TChildProps>>(OrgContextDocument, {
      alias: 'orgContext',
      ...operationOptions
    });
};

/**
 * __useOrgContextQuery__
 *
 * To run a query within a React component, call `useOrgContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgContextQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgContextQuery, OrgContextQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgContextQuery, OrgContextQueryVariables>(OrgContextDocument, baseOptions);
      }
export function useOrgContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgContextQuery, OrgContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgContextQuery, OrgContextQueryVariables>(OrgContextDocument, baseOptions);
        }
export type OrgContextQueryHookResult = ReturnType<typeof useOrgContextQuery>;
export type OrgContextLazyQueryHookResult = ReturnType<typeof useOrgContextLazyQuery>;
export type OrgContextQueryResult = ApolloReactCommon.QueryResult<OrgContextQuery, OrgContextQueryVariables>;