import React from 'react'
import styled from 'styled-components/macro'
import {Box, Heading} from 'grommet'

export function NotFound() {
  return (
    <Box fill justify="center" align="center">
      <Heading level="2">
        Мы ничего не смогли найти по этой ссылке :(
      </Heading>
    </Box>
  )
}
