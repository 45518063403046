import deepFreeze from 'deep-freeze'
import {css} from 'styled-components'
import {rgba} from 'polished'
import {normalizeColor} from 'grommet/utils/colors'

// https://www.npmjs.com/package/@zooniverse/grommet-theme
// Zooniverse brand: https://projects.invisionapp.com/dsm/zooniverse/primary-brand/folder/colors/5bbd0dbcd018e900118186e8

// PROBLEM WITH FOCUS STYLING:
// grommet limits configuration of `focusStyle`, see here
// https://github.com/grommet/grommet/blob/master/src/js/utils/styles.js
// usage example here:
// https://github.com/grommet/grommet/blob/master/src/js/components/Button/StyledButton.js
const focusStyle = css`
  > circle,
  > ellipse,
  > line,
  > path,
  > polygon,
  > polyline,
  > rect {
    outline: ${props =>
    normalizeColor(props.theme.global.focus.border.color, props.theme)}
      solid 1px;
  }
  border-color: ${props =>
    normalizeColor(props.theme.global.focus.border.color, props.theme)};
  outline: none;
  box-shadow: none;
  ::-moz-focus-inner {
    border: 0;
  }
`;


const brand = '#078f52'

// dark colors
const darkBlack = '#2D2D2D'
const lightBlack = '#272727'
const darkestGrey = '#333333'
const darkerGrey = '#404040'
const darkGrey = '#5C5C5C'

// light colors
const lightestGrey = '#eff2f5'
const lighterGrey = '#ebebeb'
const lightGrey = '#e2e5e9'
const grey = '#CBCCCB'
const midGrey = '#a6a7a9'
const moreMiddleGrey = '#979797'

// status
const tomato = '#E45950'
const pink = '#FFB6AA'
const darkGold = '#CC9200'
const green = '#078F52'

// accent colors
const mint = '#B8E986'
const lightGold = '#f6d885'
const lightNavy = '#6D91B3'
const lightTeal = '#addde0'

// neutral colors
const darkGreen = '#345446'
const darkTeal = '#005D69'
const navy = '#0C4881'
const gold = '#f0b200'

const accentColors = [
  mint, // accent-1
  lightTeal, // accent-2
  lightNavy, // accent-3
  lightGold // accent-4
]

const neutralColors = [
  darkGreen, // neutral-1
  darkTeal, // neutral-2
  navy, // neutral-3
  gold // neutral-4
]

const statusColors = {
  critical: tomato,
  error: pink,
  warning: darkGold,
  ok: green,
  unknown: grey,
  disabled: grey
}

const darkColors = [
  darkBlack, // dark-1
  lightBlack, // dark-2
  darkestGrey, // dark-3
  darkerGrey, // dark-4
  darkGrey, // dark-5
  darkGrey // dark-6 (same as dark-5, could be updated)
]

const lightColors = [
  lightestGrey, // light-1
  lighterGrey, // light-2
  lightGrey, // light-3
  grey, // light-4
  midGrey, // light-5
  moreMiddleGrey // light-6
]


// SEE MATERIAL-UI THEME
// https://material-ui.com/customization/default-theme/?expend-path=$.palette
const primaryColors = {
  light: "#4d7393",
  main: "#396387",
  dark: "#234D71",
  contrastText: "#fff",
}
const secondaryColors = {
  light: "#f16889",
  main: "#ef476f",
  dark: "#c43b5b",
  contrastText: "#fff",
}
const textColors = {
  primary: "rgba(0, 0, 0, 0.87)",
  secondary: "rgba(0, 0, 0, 0.54)",
  disabled: "rgba(0, 0, 0, 0.38)",
  hint: "rgba(0, 0, 0, 0.38)",
}
const greyColors = {
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#eeeeee",
  300: "#e0e0e0",
  400: "#bdbdbd",
  500: "#9e9e9e",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
  A100: "#d5d5d5",
  A200: "#aaaaaa",
  A400: "#303030",
  A700: "#616161",
}


const focusColor = accentColors[1]

const colors = {
  active: rgba(221, 221, 221, 0.5),
  black: '#000000',
  border: {
    dark: rgba(255, 255, 255, 0.3),
    light: rgba(0, 0, 0, 0.15),
  },
  brand,
  control: {
    dark: 'accent-1',
    light: 'brand',
  },
  focus: focusColor,
  // "graph-0": 'accent-1',
  // "graph-1": 'neutral-1',
  // "graph-2": 'accent-2',
  // "graph-3": 'neutral-2',
  paper: {
    dark: darkGrey,
    light: 'white'
  },
  placeholder: '#AAAAAA',
  selected: 'brand',
  text: {
    dark: '#f8f8f8',
    light: '#444444',
  },
  icon: {
    dark: '#f8f8f8',
    light: '#666666',
  },
  white: '#FFFFFF',
};

const colorArray = (array, prefix) =>
  array.forEach((color, index) => {
    colors[`${prefix}-${index + 1}`] = color
  })

const mergeFlatkeys = (obj, prefix) => {
  for (let k in obj) {
    colors[`${prefix}-${k}`] = obj[k]
  }
}

colorArray(accentColors, 'accent')
colorArray(darkColors, 'dark')
colorArray(lightColors, 'light')
colorArray(neutralColors, 'neutral')

mergeFlatkeys(statusColors, 'status')
mergeFlatkeys(primaryColors, 'primary')
mergeFlatkeys(secondaryColors, 'secondary')
mergeFlatkeys(textColors, 'text')
mergeFlatkeys(greyColors, 'grey')

export const theme = deepFreeze({
  global: {
    font: {
      family: 'Roboto',
      // size: '16px',
      // height: '20px',
    },
    colors,
    control: {
      border: {radius: '0px'},
    },
    input: {
      weight: 'normal',
      extend: `
        &:focus {
          border-color: ${focusColor}
        }
      `
    },
    drop: {
      background: '#ffffff'
    },
  },
  anchor: {
    // textDecoration: 'none',
    // hover: {
    // textDecoration: 'underline',
    // },
    fontWeight: 'normal',
    color: {
      dark: 'accent-2',
      light: 'brand'
    },
  },
  box: {
    extend: css`
      ${(props: any) => props.focus && focusStyle}
    `
  },
  button: {
    border: {
      width: '0px',
      radius: '0px'
    },
    colors: {
      accent: 'accent-3',
    },
    extend: css`
      letter-spacing: 0.04em;
      ${(props: any) => props.plain ? '' : 'font-variant: all-small-caps;'}
      ${(props: any) => props.focus && (!props.plain || props.focusIndicator) && focusStyle}
    `
  },
  calendar: {
    heading: {level: '5'}
  },
  checkBox: {
    border: {
      color: {
        dark: 'light-3',
        light: 'dark-5'
      }
    },
    check: {
      color: {
        dark: 'accent-2',
        light: 'brand'
      }
    },
    color: {
      dark: 'accent-2',
      light: 'brand'
    },
    hover: {
      border: {
        color: {
          dark: 'accent-2',
          light: 'brand'
        }
      }
    },
    knob: {
      color: {
        dark: 'accent-2',
        light: 'brand'
      }
    },
    toggle: {
      border: {
        color: {
          dark: 'light-3',
          light: 'dark-5'
        }
      },
      color: {
        dark: 'light-3',
        light: 'dark-5'
      }
    }
  },
  formField: {
    border: {
      color: 'border',
    },
    label: {
      margin: {bottom: '-xsmall'},
      size: 'small',
      color: {
        dark: 'light-3',
        light: 'dark-5'
      }
    },
    error: {
      margin: {top: '0.3ex'},
      size: 'small',
    },
    help: {
      size: 'small'
    },
    extend: `
      & > label {
        font-variant: all-small-caps;
        letter-spacing: 0.02em;
      }
      &:focus-within>label {
        color: ${neutralColors[1]}
      }
      &:focus-within>*:last-child {
        border-color: ${focusColor}
      }
      & > span:last-child {
        line-height: 1.2;
      }
    `
  },
  grommet: {
    extend: `
      // width: 100%;
      // min-height: 100vh;
      background-color: #f0f0f0;`
  },
  //custom prop:
  header: {
    height: '40px'
  },
  layer: {
    border: {
      radius: '0px'
    }
  },
  paragraph: {
    extend: props => `margin: ${props.margin || '1em 0 1em 0'}`
  },
  radioButton: {
    check: {
      color: {
        dark: 'accent-2',
        light: 'brand'
      }
    },
    color: {
      dark: 'accent-2',
      light: 'brand'
    },
    icon: {
      size: '15px',
      extend: `
        circle {
          r: 10px;
        }
      `
    },
    size: '15px'
  },
  select: {
    control: {
      // override button styles
      extend: `
        letter-spacing: 0;
        &:focus {
          border-color: ${focusColor};
        }
      `
    },
    container: {
      extend: `
      & [role='menuitem'] {
        font-variant: none;
      }
    `},
    icons: {
      color: {
        dark: 'accent-2',
        light: 'brand'
      }
    },
  },
  textInput: {
    extend: `
      &:focus {
        box-shadow: none;
        border-color: ${focusColor}
      }
    `,
    container: {
      extend: `
        &:focus {
          outline: none;
        }
      `}
  }
})
