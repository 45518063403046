import React from 'react'
import M from '../common/moment'
import _ from 'lodash'
import {useHistory} from 'react-router-dom'
import {orgPath} from '../common/url'

import styled from 'styled-components/macro'
import {rgba} from 'polished'

import {Grid, Box, Text, Button, Menu} from 'grommet'
import {
  Down as ExpandedIcon,
  Next as CollapsedIcon,
  More as DotsIcon
} from 'grommet-icons'
import {withColor} from '../components/theme'

import {daysArray, shiftDuration} from '../common/timeUtils'
import {useQuery} from '../common/url'

import {IntentContext} from '../components/intent/IntentContext'
import EditShift from '../components/intent/EditShift'
import AddTeamMember from '../components/intent/AddTeamMember'

import Pagebar from '../components/Pagebar'
import {WeekPicker} from '../components/DatePicker'
import {Paper} from '../components/Paper'
import {ShiftTimeLabel} from '../components/ShiftTimeLabel'
import {ColoredBox} from '../components/ColoredBox'

import gql from 'graphql-tag'
import {
  useListScheduleQuery,
  ListScheduleQuery,
  ShiftTypeFaceFragmentDoc,
  ShiftTypeTimesFragmentDoc,
} from '../generated/graphql'

// eslint-disable-next-line
const GET_SCHEDULE = gql`
  query ListSchedule($from: Date!, $to: Date!) {
    listTeams(input: {}) {
      teams {
        id, name,
        members {
          employee {id, passport {id, lastName, firstName}},
          job {id, name}
        }
      }
    }
    listSchedule(input: {from: $from, to: $to}) {
      plans {
        id,
        team {id, name},
        employee {id, passport {id, lastName, firstName}},
        date,
        shiftType {...ShiftTypeFace, ...ShiftTypeTimes},
        job {id, name}
      }
    }
  }
  ${ShiftTypeFaceFragmentDoc}
  ${ShiftTypeTimesFragmentDoc}
`

type Team = ListScheduleQuery['listTeams']['teams'][0]
type Schedule = ListScheduleQuery['listSchedule']['plans']
type Shift = Schedule[0]
type ScheduleCell = {
  team: Team,
  job: Shift['job']
  employee: Shift['employee'],
  membership: Team['members'][0],
  date: M.Moment,
  shift: Shift
}

const TeamScheduleContext = React.createContext({
  showHours: false
})


export default function Shifts() {
  const {onIntent} = React.useContext(IntentContext)
  const history = useHistory()
  let {weekstart} = useQuery()

  weekstart = weekstart && M(weekstart, 'YYYY-MM-DD', true).isValid()
    ? weekstart : M().format('YYYY-MM-DD')

  const from = M(weekstart).startOf('isoWeek')
  const to = M(from).endOf('isoWeek').startOf('day')
  const days = daysArray(from, to)

  React.useEffect(() => {
    //clear intent area when changing filter
    //because query params don't trigger intent clearing
    onIntent(null)
  }, [weekstart])


  const {data, loading, error} = useListScheduleQuery({
    variables: {
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD')
    }
  })
  if (loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>;

  const teams = data.listTeams.teams
  const scheduleCells = buildScheduleData({
    days,
    teams,
    shifts: data.listSchedule.plans
  })
  const cellsByTeam = _.groupBy(scheduleCells, 'team.id')

  return (
    <article>
      <Paper>
        <Pagebar
          title="Расписание смен"
          actions={
            <WeekPicker
              value={[from, to]}
              size="small"
              hoverIndicator
              onChange={({value: [from, to]}) => {
                history.push(orgPath(
                  `/schedule?weekstart=${from
                    .format('YYYY-MM-DD')}`))
              }} />
          }
        />
      </Paper>
      {teams.map(
        (team, i) => (
          <TeamSchedule key={i}
            team={team} days={days} cells={cellsByTeam[team.id]} />
        ))}
    </article>
  )
}

type ScheduleProps = {
  days: M.Moment[],
  cells: ScheduleCell[],
}

const TeamSchedule = (props: ScheduleProps & {team: Team}) => {
  const [contentVisible, setContentVisible] = React.useState(true)
  const [hoursVisible, setHoursVisible] = React.useState(false)
  const {onIntent} = React.useContext(IntentContext)

  return (
    <TeamScheduleContext.Provider value={{showHours: hoursVisible}}>
      <Paper margin={{vertical: 'medium'}}
        background={contentVisible ? '' : 'grey-50'}
        onClick={contentVisible ? null : () => setContentVisible(true)}
        hoverIndicator
      >
        <Pagebar level='2' title={props.team.name}
          actions={[
            contentVisible && <Menu
              key="dots"
              hoverIndicator
              icon={<DotsIcon />}
              items={[{
                label: 'Добавить сотрудника',
                onClick: () => onIntent(new AddTeamMember({
                  teamId: props.team.id,
                }))
              }, {
                label: hoursVisible
                  ? 'Скрыть часы смен' : 'Показать часы смен',
                onClick: () => setHoursVisible(!hoursVisible)
              },
              ]}
            />,
            <Button
              key="collapse"
              css={`${p => p.theme.getEdge('padding', 'small')}`}
              hoverIndicator
              icon={contentVisible ? <ExpandedIcon /> : <CollapsedIcon />}
              onClick={() => setContentVisible(!contentVisible)}
            />]}
        />
        {contentVisible && <TeamScheduleContent {...props} />}
      </Paper>
    </TeamScheduleContext.Provider>
  )
}

const TeamScheduleContent = ({days, cells}: ScheduleProps) => {
  if (days.length != 7 && M(days[0]).isoWeekday() != 1) {
    throw new Error('Days should conform to a single week')
  }

  return (
    <Grid
      css="line-height: 1;"
      columns={['auto', 'auto', ...[...Array(7)].map(i => 'flex')]}
    // gap={{row: 'small'}}
    >
      <Box></Box>
      <Box></Box>
      <DayHeadings days={days} />

      {_.values(_.groupBy(cells, 'job.id')).flatMap(jobCells => {
        const job = jobCells[0].job
        return ([
          <JobRow key={job.id} job={job} />,
          ..._.values(_.groupBy(jobCells, 'employee.id'))
            .flatMap((eCells, i) => (
              <EmployeeRow
                key={`${job.id}-${i}`}
                days={days}
                cells={eCells}
              />))
        ])
      })}
    </Grid>
  )
}

const DayHeadings = ({days}: Pick<ScheduleProps, 'days'>) => (
  <>
    {days.map(d => (
      <StyledWeekdayCell key={d.toISOString()}>
        <Text textAlign="center" color="text-secondary" size="small">
          {d.format('dd D')}
        </Text>
      </StyledWeekdayCell>
    ))}
  </>
)

const JobRow = ({job}: {job: ScheduleCell['job']}) => (
  <>
    <Box
      pad={{vertical: '2px'}}
      direction="row"
      css={`
        grid-column: 1/-1;
        background: repeating-linear-gradient(
          -55deg, #f7f7f7, #f7f7f7 10px, #fff 10px, #fff 20px );
      `}>
      <Text size="xsmall" color="text-secondary"
        css="font-variant: all-small-caps; line-height: 1;">
        {job.name}
      </Text>
    </Box>
  </>
)

/**
 * Display an employee row, each for one job
 */
const EmployeeRow = ({days, cells}: ScheduleProps) => {
  const employee = cells[0].employee
  const membership = cells[0].membership

  return (
    <>
      <StyledScheduleCell pad={{right: '.3em'}}>
        <Text color="secondary-dark" size="xsmall">
          <span css="font-size: .8em">
            {shiftDuration(_.compact(_.map(cells, 'shift')))}
          </span>
        </Text>
      </StyledScheduleCell>
      <StyledScheduleCell
        pad={{vertical: 'xsmall'}}
        css={`${membership ? '' : 'font-style: italic;'}`}>
        <Text size="small" margin={{right: 'small'}}
          css="line-height: 1.1">
          {employee.passport.lastName}
        </Text>
        <Text color="text-secondary"
          size="small" margin={{right: 'small'}}
          css="line-height: 1.1">
          {employee.passport.firstName}
        </Text>
      </StyledScheduleCell>
      {cells.map(cell => (
        <ShiftCell key={cell.date.toISOString()} {...cell} />
      ))}
    </>
  )
}

const ShiftCell = ({shift, team, employee, date, job}: ScheduleCell) => {
  const {onIntent} = React.useContext(IntentContext)
  const {showHours} = React.useContext(TeamScheduleContext)

  return (
    <StyledShiftCell
      hoverIndicator
      onClick={() => onIntent(new EditShift({
        teamId: team.id,
        employeeId: employee.id,
        date,
        jobId: job.id,
      }))}
    >
      {shift ? (
        <ColoredBox fill justify="center"
          color={shift.shiftType.color}
          backgroundOpacity={0.4}
        >
          <Text
            color="inherit"
            size="small"
            css="line-height: 1; text-transform: lowercase"
          >
            {shift.shiftType.name}
          </Text>
          {showHours && <Text
            size="xsmall" color="text-secondary" margin={{top: '.6ex'}}
            css="line-height: 1">
            {<ShiftTimeLabel {...shift} css="font-size: .9em" />}
          </Text>}
        </ColoredBox>
      ) : (
          <Text color="text-secondary"> &middot; </Text>
        )}
    </StyledShiftCell>
  )
}

const StyledScheduleCell = styled(Box).attrs({
  fill: true,
  justify: 'center'
})``

const StyledWeekdayCell = styled(Box)`
  position: sticky;
  top: 0;
  background-color: ${p => rgba(p.theme.getColor('paper'), 0.8)}
`


const StyledShiftCell = styled(StyledScheduleCell)`
  padding: 1px;
  text-align: center;
  overflow-wrap: break-word;
  &:focus-within {
    outline: 1px solid ${withColor('brand')};
    outline-offset: -1px;
  }
`

function buildScheduleData({days, teams, shifts}: {
  days: M.Moment[],
  teams: Team[],
  shifts: Schedule
}): ScheduleCell[] {
  const shiftsByTeam = _.groupBy(shifts, 'team.id')

  return _.sortBy(teams, 'name').flatMap(team => {
    const memberships = _.mapValues(
      _.groupBy(team.members, 'job.id'),
      members => _.keyBy(members, 'employee.id'))

    const schedule = shiftsByTeam[team.id]
    const shiftsByJob = _.groupBy(schedule, 'job.id')
    const jobs = _.uniqBy([
      ..._.map(team.members, 'job'),
      ..._.map(schedule, 'job')], 'id')

    return _.sortBy(jobs, 'name').flatMap(job => {
      const schedule = shiftsByJob[job.id]
      const shiftsByEmpl = _.groupBy(schedule, 'employee.id')
      const employees = _.uniqBy([
        ..._.map(memberships[job.id], 'employee'),
        ..._.map(schedule, 'employee')], 'id')

      return _.sortBy(employees, ['passport.lastName', 'id']).flatMap(employee => {
        const schedule = shiftsByEmpl[employee.id]
        const byDate = _.keyBy(schedule, s => M(s.date).toISOString())

        return days.map(date => ({
          team,
          job,
          employee,
          membership: _.get(memberships, [job.id, employee.id]),
          date,
          shift: byDate[date.toISOString()]
        }))
      })
    })
  })
}
