import React from 'react'
import _ from 'lodash'
import M from '../../common/moment'
import 'styled-components/macro'

import {toast} from 'react-toastify'
import Intent from './Intent'

import {
  Form, FormField, Button, Heading, Box, Text
} from 'grommet'

import {Paper} from '../Paper'
import {TimePicker} from '../TimePicker'
import {ColorPicker} from '../ColorPicker'
import {toSeconds} from '../../common/timeUtils'

import gql from 'graphql-tag'
import {
  useUpdateShiftTypeMutation,
  useShiftTypeCardQuery,
  UpdateShiftTypeInput,
  TeamCardDocument,
  ShiftTypeFaceFragmentDoc,
  ShiftTypeTimesFragmentDoc
} from '../../generated/graphql'

// eslint-disable-next-line
const SHIFT_TYPE_CARD_FIELDS = gql`
  fragment ShiftTypeFace on ShiftType {
    id, name, color, isDeleted
  }
  fragment ShiftTypeTimes on ShiftType {
    startTime, duration
  }
`

// eslint-disable-next-line
const GET_SHIFT_TYPE_CARD = gql`
  query ShiftTypeCard($shiftTypeId: ID!) {
    getShiftType(input: {id: $shiftTypeId}) {
      shiftType {
        ...ShiftTypeFace
        ...ShiftTypeTimes
        team {id, shiftTypes {id, name}}
        lastUsedDate
      }
    }
  }
  ${SHIFT_TYPE_CARD_FIELDS}
`
// eslint-disable-next-line
const UPDATE_SHIFT_TYPE = gql`
  mutation UpdateShiftType($input: UpdateShiftTypeInput!) {
    updateShiftType(input: $input) {
      shiftType {
        ...ShiftTypeFace, ...ShiftTypeTimes
      }
    }
  }
  ${SHIFT_TYPE_CARD_FIELDS}
`

type IntentVariables = Pick<UpdateShiftTypeInput, 'shiftTypeId'>

export default class UpdateShiftType extends Intent<IntentVariables> {
  static type = 'team.update_shift_type'

  get type(): string {
    return UpdateShiftType.type
  }

  asComponent() {
    return () => {
      const [mutate, {data, loading, error}] = useUpdateShiftTypeMutation({
        refetchQueries: [
          TeamCardDocument.definitions[0].name.value,
          {
            query: GET_SHIFT_TYPE_CARD,
            variables: {shiftTypeId: this.initialProps.shiftTypeId}
          }
        ]
      })

      const submit = (data: FormVariables) => {
        mutate({
          variables: {
            input: {
              ..._.pick(data, 'shiftTypeId', 'name', 'color', 'isDeleted'),
              // saving this as GMT
              startTime: data.startTime.format('HH:mm:ss') + 'Z',
              duration: toSeconds(data.startTime, data.endTime)
            }
          }
        }).then(({data}) => {
          console.log('FINISHED', data)
          toast.success('Изменения сохранены')
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      // if (data) return <p>ГОТОВО</p>

      return (
        <UpdateShiftTypeForm onSubmit={submit} {...this.initialProps} />
      )
    }
  }
}

type FormVariables = {
  shiftTypeId: string,
  isDeleted: boolean,
  name: string,
  color: string,
  startTime: M.Moment,
  endTime: M.Moment
}
const UpdateShiftTypeForm = ({onSubmit, shiftTypeId}) => {
  const {data, loading, error} = useShiftTypeCardQuery({
    variables: {shiftTypeId},
    // we have a 'lastUsedDate' field which may invalidate cache
    // fetchPolicy: 'network-only'
  })

  const shiftType = data?.getShiftType.shiftType
  const shiftTypes = _.filter(
    shiftType?.team.shiftTypes, st => st.id != shiftType.id)
    .map(st => _.toLower(st.name))

  const [timeFreezeWarning, showTimeFreezeWarning] = React.useState(null)
  const [changes, setChanges] = React.useState<FormVariables>(null)
  const fields: FormVariables = changes || {
    shiftTypeId,
    ..._.pick(shiftType, 'isDeleted', 'name', 'color'),
    startTime: M.utc(shiftType?.startTime, 'HH:mm:ss'),
    endTime: M.utc(shiftType?.startTime, 'HH:mm:ss')
      .add(shiftType?.duration, 'seconds')
  }

  return shiftType ? (

    <Form value={fields}
      // form.onChange has a bug sometimes leaking the native event
      // into onChange (e.g. upon key press on input)
      // https://github.com/grommet/grommet/issues/3356
      onChange={v => {
        v.preventDefault ?? setChanges({
          ...v, name: v.name.toLowerCase()
        })
      }}
      onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Смена «{shiftType.name}»
      </Heading>
        <FormField name="name" required label="Название"
          validate={val => {
            return _.includes(shiftTypes, _.toLower(val))
              ? 'В команде уже есть смена с таким названием'
              : null
          }}
        />
        <Box direction="row" gap="small" hoverIndicator={false}
          onClick={shiftType.lastUsedDate && (() =>
            showTimeFreezeWarning(true))}
        >
          <FormField name="startTime" required label="С"
            disabled={shiftType.lastUsedDate}
            component={TimePicker}
            validate={(val, fields) => {
              return (val == fields.endTime)
                ? 'Значения не должны совпадать'
                : null
            }}
          />
          <FormField name="endTime" required label="По"
            disabled={shiftType.lastUsedDate}
            component={TimePicker}
            validate={(val, fields) => {
              return (val == fields.startTime)
                ? 'Значения не должны совпадать'
                : null
            }}
          />
        </Box>
        {timeFreezeWarning && <Text size="xsmall" color="status-warning" css="font-style: italic;">
          Вы не можете редактировать время смены, т.к. она
          уже использовалась в расписании (см {
            M(shiftType.lastUsedDate).format('D MMM YYYY')
          }). При необходимости создайте новую смену, а эту удалите.
          </Text>}

        <FormField name="color" label="Цвет"
          component={ColorPicker}
          placeholder={shiftType.name}
        />


        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary disabled={!changes}
            label="Сохранить" />
          <Button
            label="Удалить" />
        </Box>
      </Paper>

    </Form >
  ) : <i>ЗАГРУЗКА</i>
}
