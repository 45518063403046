import React from 'react'
import 'styled-components/macro'

import {useHistory} from 'react-router-dom'
import {useAuth} from '../common/auth'

import {Box, Text, Button, Menu} from 'grommet'
import {ChatOption, Menu as MenuIcon} from 'grommet-icons'


export const AppBar = (
  {onToggleNavbar, onToggleChatbar, ...props}
) => (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      background='brand'
      pad={{horizontal: 'medium', vertical: 'xsmall'}}
      style={{zIndex: 1}}
      {...props}
    >
      <Button
        onClick={() => onToggleNavbar()}
        icon={<MenuIcon />}
        label={<Text size="large" weight={300}>Smennik</Text>}
        plain
        css="font-variant: none; padding: 0"
      />

      <Box direction="row" align="center">
        <UserAccountMenu />
        {/* <Text></Text> */}
        {/* <Button
          icon={<ChatOption />}
          onClick={() => onToggleChatbar()} /> */}
      </Box>
    </Box>
  )

const UserAccountMenu = () => {
  const {user, loading, logout} = useAuth()
  const history = useHistory()

  if (loading) return <p>ЗАГРУЗКА...</p>

  return user ? (
    <Menu
      label={user.email}
      items={[{
        label: 'Выйти', onClick: () => {
          logout()
            .then(() => history.push('/'))
        }
      }]}
    />
  ) : (
      <Button onClick={() => history.push('/login')}>Войти</Button>
    )
}
