import React from 'react'
import _ from 'lodash'
import {
  Switch, Route, Redirect, useRouteMatch
} from "react-router-dom"

import {Normalize} from 'styled-normalize'
import {Grommet} from 'grommet'
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom"
import {useApolloClient} from '@apollo/react-hooks'
import {useAuth, onCompanyEnter} from '../common/auth'

import {useMeQuery} from '../generated/graphql'
import {currentMode, orgPath} from '../common/url'

import theme from '../components/theme'
import AppLayout from '../components/Layout'
import {Toastbar} from '../components/Toastbar'

import {
  LoginUser,
  LoginCompany,
  CompletePassport
} from '../pages/Login'

import AdminPages from './admin'

import Employees from './Employees'
import Employee from './Employee'
import Teams from './Teams'
import Team from './Team'
import Jobs from './Jobs'
import Job from './Job'
import Shifts from './Shifts'
import {JoinPreview, JoinAccept, JoinWelcome} from './Join'


export default function Pages() {
  return (
    <>
      <Normalize />
      <Grommet theme={theme} full>
        <Router>
          <Render />
        </Router>
        <Toastbar />
      </Grommet>
    </>
  )
}

function Render() {
  return currentMode() == 'admin' ? (
    <AppLayout> <AdminPages /> </AppLayout>
  ) : (
      <AppLayout> <AppPages /> </AppLayout>
    )
}

const AppPages = () => {
  return (
    <Switch>
      <Route path="/" exact><Redirect to="/org" /></Route>

      <Route path="/join/:inviteId"><JoinPreview /></Route>
      <LoginRoute path="/login"><Redirect to="/org" /></LoginRoute>

      <LoginRoute path="/org" exact>
        <LoginCompany />
      </LoginRoute>

      <LoginRoute path="/org/:orgId">
        <Switch>
          <Route path="/org/:orgId/join/:inviteId">
            <JoinAccept />
          </Route>

          <OrgMembershipGuard>
            <OrgRoutes />
          </OrgMembershipGuard>
        </Switch>
      </LoginRoute>

      {/* <Route path="/" exact>HOMEPAGE</Route> */}
    </Switch>
  )
  // {/* <Route path="/"><Redirect to={appUrl('', '')} /></Route> */}
}


function OrgRoutes() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let {path, url} = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/welcome`}><JoinWelcome /></Route>
      <Route path={`${path}/schedule`}><Shifts /></Route>
      <Route path={`${path}/jobs/:jobId`}><Job /></Route>
      <Route path={`${path}/jobs`}><Jobs /></Route>
      <Route path={`${path}/teams/:teamId`}><Team /></Route>
      <Route path={`${path}/teams`}><Teams /></Route>
      <Route path={`${path}/employees/:employeeId`}><Employee /></Route>
      <Route path={`${path}/employees`}><Employees /></Route>
      <Route path={path} /*exact*/ >
        <Redirect to={`${url}/schedule`} />
      </Route>
    </Switch>
  )
}



// TODO: redurect to /login page and pass as state original url
// @see https://reacttraining.com/react-router/web/example/auth-workflow
function LoginRoute({children, ...props}) {
  const {user, loading} = useAuth()
  return (
    <Route {...props}>
      {loading
        ? <p>LOADING...</p>
        : user
          ? <PassportGuard>{children}</PassportGuard>
          : <LoginUser />}
    </Route>
  )
}

// check cached version of passport.memberships to give
// access to the org route
function PassportGuard({children}) {
  const {data, loading, error} = useMeQuery({
    fetchPolicy: 'cache-first'
  })

  if (loading) return <p>LOADING...</p>
  if (error) return <p>ОШИБКА...</p>

  return data?.me.passport
    ? children
    : <CompletePassport />
}

// check cached version of passport.memberships to give
// access to the org route
function OrgMembershipGuard({children}) {
  const {orgId} = useParams()
  const {data, loading} = useMeQuery({
    fetchPolicy: 'cache-first'
  })
  const client = useApolloClient()

  if (loading) return <p>LOADING...</p>
  onCompanyEnter(client, orgId)

  const employments = data?.me.passport.employments
  const employed = orgId && _.find(employments, {company: {id: orgId}})

  return employed
    ? <>{children}</>
    : <Redirect to={orgPath.call({orgId: ''}, '')} />
}
