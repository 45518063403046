import React from 'react'
import Intent from './Intent'
import {useHistory} from 'react-router-dom'
import {orgPath} from '../../common/url'

import {
  Form,
  FormField,
  Button,
  Box,
  Heading
} from 'grommet'
import {Paper} from '../Paper'

import gql from 'graphql-tag'
import {
  CreateTeamInput,
  useCreateTeamMutation,
} from '../../generated/graphql'

// eslint-disable-next-line
const CREATE_TEAM = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
        name
      }
    }
  }
`

type IntentVariables = CreateTeamInput

export default class CreateTeam extends Intent<IntentVariables> {
  static type = 'teams.create'

  get type(): string {
    return CreateTeam.type
  }

  asComponent() {
    return () => {
      const history = useHistory()
      const [mutate, {data, loading, error}] = useCreateTeamMutation()

      const submit = (data: IntentVariables) => {
        mutate({
          variables: {input: data}
        }).then(({data}) => {
          console.log('FINISHED', data)
          return history.push(orgPath('/teams', data.createTeam.team.id))
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      if (data) return <p>ГОТОВО</p>

      return (
        <CreateTeamForm onSubmit={submit} />
      )
    }
  }
}

const CreateTeamForm = ({onSubmit}) => {
  return (
    <Form onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Новая команда
        </Heading>
        <FormField name="name" required label="Название" />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary label="Создать" />
        </Box>
      </Paper>
    </Form >
  )
}
