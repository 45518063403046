import React from 'react'
import _ from 'lodash'
import M from '../common/moment'
import 'styled-components/macro'


import {Box, Text, DropButton, Calendar, Button} from "grommet"
import {FormClose} from "grommet-icons"
import {useBreakpointsContext} from './BreakpointsContext'

type DatePickerParams = {
  value?: M.MomentInput | M.MomentInput[] | M.MomentInput[][],
  onChange?: any,
  placeholder?: any,
  erasable?: boolean,
  display?: any,
  hoverIndicator?: boolean
}

export const DatePicker = ({
  value,
  display = null,
  onChange,
  placeholder = "",
  erasable = false,
  hoverIndicator = false
}: DatePickerParams) => {
  const [open, setOpen] = React.useState();
  const {screenBelow} = useBreakpointsContext()

  const onSelect = selectedDate => {
    // setDate(selectedDate);
    onChange({value: selectedDate, target: {value: selectedDate}})
    setOpen(false)
  }

  const moments = value && ( _.isArray(value) ? value : [value])
  const dates = _.map(moments,
    (d: any) => _.isArray(d)
      ? d.map(d2 => M(d2).toISOString())
      : M(d).toISOString())

  const label = display
    ? (_.isFunction(display) ? display({value}) : display)
    : (<Text>
      {value ? M(_.flatten(dates)[0]).format('D.MM.YYYY') : placeholder}
    </Text>)

  return (
    <DropButton
      open={open}
      hoverIndicator={hoverIndicator}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      dropProps={{stretch: false}}
      dropAlign={{top: 'bottom', left: 'left'}}
      dropContent={
        <Calendar
          size={screenBelow('small') ? 'medium' : 'small'}
          dates={dates}
          onSelect={onSelect}
          firstDayOfWeek={1}
        />
      }
    >
      <Box direction="row" justify="between" align="center" pad="small">
        {label}
        {erasable && <Box
          hoverIndicator
          pad="0"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelect('')
          }}
        ><FormClose /></Box>}
        {/* <FormDown color="brand" /> */}
      </Box>
    </DropButton>
  )
}

type WeekPickerParams = Omit<DatePickerParams, 'value' | 'display'> & {
  value?: M.MomentInput[],
  size?: any
}
export const WeekPicker = ({
  value, onChange, size, ...props
}: WeekPickerParams) => {

  const onSelect = selectedDate => {
    const range = weekRangeForDate(selectedDate)
    onChange && onChange({value: range, target: {value: range}})
  }

  return (
    <DatePicker
      {...props}
      value={[value]}
      onChange={({value}) => onSelect(value)}
      display={value && <Text size={size}>
        {formatRange(value[0], value[1]).join(' - ')}
      </Text>}
    />
  )
}

function weekRangeForDate(date: M.MomentInput) {
  return [
    M(date).startOf('isoWeek'),
    M(date).endOf('isoWeek').startOf('day'),
  ]
}

function formatRange(from: M.MomentInput, to: M.MomentInput) {
  return (M(from).isSame(to, 'month'))
    ? [M(from).format('D'), M(to).format('D MMM')]
    : [M(from).format('D MMM'), M(to).format('D MMM')]
}
