import React from 'react'
import Intent from './Intent'
import {useHistory} from 'react-router-dom'
import {orgPath} from '../../common/url'

import {
  Form, FormField, Button, Heading, Box
} from 'grommet'

import {Paper} from '../Paper'

import gql from 'graphql-tag'
import {
  CreateJobInput,
  useCreateJobMutation,
  // ListAllJobsDocument
} from '../../generated/graphql'

// eslint-disable-next-line
const CREATE_JOB = gql`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      job {
        id
        name
      }
    }
  }
`

type IntentVariables = CreateJobInput

export default class CreateJob extends Intent<IntentVariables> {
  static type = 'jobs.create'

  get type(): string {
    return CreateJob.type
  }

  asComponent() {
    return () => {
      const history = useHistory()
      const [mutate, {data, loading, error}] = useCreateJobMutation()

      const submit = (data: IntentVariables) => {
        mutate({
          variables: {input: data},
          // refetchQueries: [
          //   ListAllJobsDocument.definitions[0].name.value
          // ]
        }).then(({data}) => {
          console.log('FINISHED', data)
          return history.push(orgPath('/jobs', data.createJob.job.id))
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      if (data) return <p>ГОТОВО</p>

      return (
        <CreateJobForm onSubmit={submit} />
      )
    }
  }
}

const CreateJobForm = ({onSubmit}) => {
  return (
    <Form onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>Новая должность</Heading>
        <FormField name="name" required label="Название" />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary
            label="Создать" />
        </Box>
      </Paper>
    </Form >
  )
}
