import React from 'react'
import Intent from './Intent'

import {
  Form,
  FormField,
  Button,
  Heading,
  Box
} from 'grommet'

import {Paper} from '../Paper'


import gql from 'graphql-tag'
import {
  CreateEmployeeInput,
  useEnlistEmployeeMutation,
} from '../../generated/graphql'

// eslint-disable-next-line
const ENLIST_EMPLOYEE = gql`
  mutation EnlistEmployee($input: CreateEmployeeInput!) {
    enlistEmployee(input: $input) {
      employee {
        id
        passport {id, firstName, lastName}
      }
    }
  }
`

type IntentIn = CreateEmployeeInput
type IntentOut = {id: string}

export default class EnlistEmployee extends Intent<IntentIn> {
  static type = 'employees.create'

  get type(): string {
    return EnlistEmployee.type
  }

  asComponent() {
    return () => {
      const [mutate, {data, loading, error}] = useEnlistEmployeeMutation()

      const submit = (data: IntentIn) => {
        mutate({
          variables: {input: {
            ...data,
            companyId: this.initialProps?.companyId
          }}
        }).then(({data}) => {
          const id = data.enlistEmployee.employee.id
          this.options?.then?.({id})
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      if (data) return <p>ГОТОВО</p>

      return (
        <EnlistEmployeeForm onSubmit={submit} />
      )
    }
  }
}

const EnlistEmployeeForm = ({onSubmit}) => {
  return (
    <Form onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Новый сотрудник
        </Heading>
        <FormField name="lastName" required label="Фамилия" />
        <FormField name="firstName" required label="Имя" />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary label="Создать" />
        </Box>
      </Paper>
    </Form >
  )
}
