import React from 'react'
import 'styled-components/macro'

import {useParams} from 'react-router-dom'
import {
  Paragraph,
  Button,
  Box,
  Heading,
  Form
} from 'grommet'
import {Paper} from '../components/Paper'
import {NotFound} from '../components/NotFound'
import {orgPath} from '../common/url'

import {useHistory} from 'react-router-dom'
import gql from 'graphql-tag'

import {
  useEmployeeInviteQuery,
  GetPendingInviteInput
} from '../generated/graphql'

import JoinCompanyIntent from '../components/intent/JoinCompany'


// eslint-disable-next-line
const GET_INVITE = gql`
  query EmployeeInvite($inviteId: ID!) {
    getPendingInvite(input: {inviteId: $inviteId}) {
      inviteMeta {
       firstName, companyName, companyId
      }
    }
  }
`

export function JoinAccept() {
  const {inviteId} = useParams()
  const Intent = React.useMemo(
    () => new JoinCompanyIntent({inviteId}).asComponent(),
    [inviteId])

  return <Intent />
}

export function JoinWelcome() {
  const history = useHistory()

  return (
    <Box fill align="center">
      <WelcomeForm onSubmit={() => history.push(orgPath())} />
    </Box>
  )
}


export function JoinPreview() {
  const {inviteId} = useParams()
  const history = useHistory()
  const {data, loading, error} = useEmployeeInviteQuery({
    variables: {inviteId},
    fetchPolicy: 'network-only'
  })
  if (loading) return <p>ЗАГРУЗКА...</p>
  if (error) return <p>ОШИБКА</p>

  const invite = data.getPendingInvite?.inviteMeta
  if (!invite) return <NotFound />

  const submit = () => history.push(
    orgPath.call({orgId: invite.companyId}, '/join', inviteId)
  )

  return (
    <Box fill align="center">
      <JoinForm onSubmit={submit} invite={invite} />
    </Box>
  )
}

const JoinForm = ({onSubmit, invite}) => {
  return (
    <Form css="max-width: 400px" onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          {invite.firstName}, присоединяйся к <b>{invite.companyName}</b>!
        </Heading>
        <Box css="p {font-size: .9rem}">
          <Paragraph margin="xsmall">
            Приняв приглашение, ты сможешь пользоваться
            Сменником вместе с командой.
         </Paragraph>
        </Box>

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary label="С удовольствием" />
        </Box>
      </Paper>
    </Form >
  )
}

const WelcomeForm = ({onSubmit}) => {
  return (
    <Form css="max-width: 400px" onSubmit={({value}: any) => onSubmit(value)}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Добро пожаловать на борт!
        </Heading>
        <Box css="p {font-size: .9rem}">
          <Paragraph margin="xsmall">
            Мы рады, что ты теперь вместе с нами.
         </Paragraph>
          <Paragraph margin="xsmall">
            Если будут возникать любые вопросы в процессе, пиши на почту <b>mail.maslennikov@gmail.com</b>. Этот адрес ты в любое время
            найдешь в самом снизу под главным меню.
         </Paragraph>
        </Box>

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary label="Поехали" />
        </Box>
      </Paper>
    </Form >
  )
}
