import React from 'react'
import 'styled-components/macro'

import _ from 'lodash'
import Intent from './Intent'

import {
  Box, Text, Form, FormField, Button, Select, Heading
} from 'grommet'

import {Paper} from '../Paper'
import {SearchSelect} from '../SearchSelect'

import gql from 'graphql-tag'
import {
  useAddTeamMemberMutation,
  useListAllEmployeesQuery,
  useListAllJobsQuery,
  useTeamCardQuery,
  UpdateTeamMembershipInput,
  ListScheduleDocument
} from '../../generated/graphql'

// eslint-disable-next-line
const ADD_TEAM_MEMBER = gql`
  mutation AddTeamMember($input: UpdateTeamMembershipInput!) {
    addTeamMember(input: $input) {
      membership {team {id}, employee {id}, job {id}}
    }
  }
`

type IntentVariables = {
  teamId: string,
  // startDate: Date
}

export default class AddTeamMember extends Intent<IntentVariables> {
  static type = 'team.add_member'

  get type(): string {
    return AddTeamMember.type
  }

  asComponent() {
    return () => {
      const [mutation, {data, loading, error}] = useAddTeamMemberMutation()

      const submit = (data: UpdateTeamMembershipInput) => {
        mutation({
          variables: {input: data},
          refetchQueries: [
            ListScheduleDocument.definitions[0].name.value,
          ]
        }).then(({data}) => {
          console.log('FINISHED', data)
          // return navigate(`/employee/${data.AddTeamMember.employee.id}`)
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      if (data) return <p>ГОТОВО</p>

      return (
        <IntentForm onSubmit={submit} {...this.initialProps} />
      )
    }
  }
}

const IntentForm = ({
  onSubmit, teamId
}: {onSubmit: any} & IntentVariables) => {

  const teamQ = useTeamCardQuery({
    variables: {teamId}
  })
  const employeeQ = useListAllEmployeesQuery()
  const jobsQ = useListAllJobsQuery({
    variables: {input: {}}
  })

  const [changes, setChanges] = React.useState(null)
  const [jobOptions, setJobOptions] = React.useState([])

  const team = teamQ.data?.listTeams.teams[0]
  const employees = _.sortBy(
    employeeQ.data?.listEmployees.employees, 'passport.lastName')
  const jobs = _.sortBy(jobsQ.data?.listJobs.jobs, 'name')
  const memberships = _.sortBy(
    teamQ.data?.listTeams.teams[0].members,
    ['employee.passport.lastName', 'job.name'])

  const fields = changes ?? {
    employee: '',
    job: '',
  }

  React.useEffect(() => {
    const employeeId = fields.employee.id
    if (!employeeId) return setJobOptions([])

    const usedJobs = memberships
      .filter(m => m.employee.id == employeeId)
      .map(m => m.job.id)
    const options = jobs.filter(j => !_.includes(usedJobs, j.id))

    setJobOptions(options)
  }, [fields.employee.id])

  if (_.find([employeeQ, teamQ, jobsQ], 'loading')) {
    return <b>ЗАГРУЗКА</b>
  }
  if (_.find([employeeQ, teamQ, jobsQ], 'error')) {
    return <b>ОШИБКА</b>
  }

  return (
    <Form
      value={fields}
      // form.onChange has a bug sometimes leaking the native event
      // into onChange (e.g. upon key press on input)
      // https://github.com/grommet/grommet/issues/3356
      onChange={v => {v.preventDefault ?? setChanges(v)}}
      onSubmit={({value}: any) => onSubmit({
        teamId: team.id,
        employeeId: value.employee.id,
        jobId: value.job.id,
      })}>
      <Paper>
        <Heading level="2" truncate margin="none">
          Добавить в команду
        </Heading>
        <Box>
          <Text
            color="primary-dark"
            size="small"
            css={`
          font-variant: all-small-caps;
          line-height: 1.7;
          letter-spacing: 0.02em
          `}
          >
            {team.name}
          </Text>
        </Box>
        <FormField name="employee" label="Сотрудник" required
          component={SearchSelect}
          dropHeight="medium"
          options={employees}
          valueKey="id"
          labelKey={e => e.passport.lastName}
          searchKey={e => e.passport.lastName}
        />
        <FormField name="job" label="Должность" required
          component={SearchSelect}
          dropHeight="medium"
          options={jobOptions}
          valueKey="id"
          labelKey="name"
          searchKey="name"
          placeholder={!fields.employee.id && 'Сначала выберите сотрудника'}
        />

        <Button type="submit" primary alignSelf="end"
          label="Сохранить" />
      </Paper>
    </Form >
  )

}
