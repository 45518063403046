import React from 'react'
import _ from 'lodash'
import M from '../../common/moment'
import {useParams, useHistory, useRouteMatch} from 'react-router-dom'
import 'styled-components/macro'

import Pagebar from '../../components/Pagebar'
import {Paper} from '../../components/Paper'
import {JobLabel} from '../../components/JobLabel'
import Anchor from '../../components/RoutedAnchor'
import {CardLabel} from '../../components/CardLabel'
import {NotFound} from '../../components/NotFound'

import {List, Box, Text, Button} from 'grommet'
import {
  FormEdit as EditIcon,
  AddCircle as AddIcon
} from 'grommet-icons'

import {useQuery} from '../../common/url'
import {IntentContext} from '../../components/intent/IntentContext'
import UpdateEmployeeMeta from '../../components/intent/admin/UpdateEmployeeMeta'

import gql from 'graphql-tag'
import {
  usePassportCardQuery,
  PassportCardQuery,
} from '../../generated/graphql'

// eslint-disable-next-line
const GET_PASSPORT_CARD = gql`
  query PassportCard($passportId: ID!) {
    listPassports(input: {passportIds: [$passportId]}) {
      passports {
        id
        type
        firstName, lastName
        employments {
          id, role, company {id, name}
        }
        createdStamp
      }
    }
  }
`


export default function AdminPassport() {
  const history = useHistory()
  const {passportId} = useParams()
  const match = useRouteMatch()
  const {employeeId} = useQuery()
  const {onIntent} = React.useContext(IntentContext)
  const {data, loading, error} = usePassportCardQuery({
    variables: {passportId},
    fetchPolicy: 'cache-and-network'
  })
  const passport = _.first(data?.listPassports.passports)

  React.useEffect(() => {
    if (!employeeId || !passport) return
    onIntent(new UpdateEmployeeMeta({employeeId}))
  }, [employeeId, onIntent, passport])

  const showEmployee = React.useCallback(employeeId => {
    history.replace(`${match.url}?employeeId=${employeeId}`)
  }, [])

  if (!data && loading) return <p>ЗАГРУЗКА...</p>;
  if (error) return <p>ОШИБКА</p>
  if (!passport) return <NotFound />

  return (
    <article>
      <Box direction="column" gap="medium">

        <Paper>
          <Pagebar title={`${passport.firstName} ${passport.lastName}`}
            actions={(<Button
              css="padding: 0 .3em 0 .3em"
              margin={{horizontal: 'xsmall'}}
              hoverIndicator
              icon={<EditIcon />}
              onClick={() => {}}
            // onClick={() => onIntent(new UpdateTeam({teamId}))}
            />)}
          />
          <PassportCard passport={passport} />
        </Paper>

        <Paper>
          <Pagebar level='2' title="В организациях" />
          {passport.employments.length ? (
            <EmploymentList
              employees={passport.employments}
              employeeId={employeeId}
              onSelect={id => showEmployee(id)}
            />
          ) : (
              <Text>Не состоит ни в одной</Text>
            )}
        </Paper>

      </Box>
    </article>
  )
}

interface CardProps {
  passport: PassportCardQuery["listPassports"]["passports"][0],
  // contracts: EmployeeCardQuery["listJobContracts"]["contracts"]
}
const PassportCard = ({passport}: CardProps) => {
  return (
    <Box direction="column" gap="medium" pad={{vertical: 'medium'}}>
      <Text>
        <CardLabel>Тип: </CardLabel>
        {passport.type}
      </Text>
      <Text>
        <CardLabel>Паспорт создан: </CardLabel>
        {M(passport.createdStamp).format('DD.MM.YYYY HH:mm')}
      </Text>
    </Box>
  )
}

interface EmploymentListProps {
  employees: PassportCardQuery['listPassports']['passports'][0]['employments'],
  employeeId?: string,
  onSelect: (id: string) => any
}
const EmploymentList = ({employees, employeeId, onSelect}: EmploymentListProps) => {
  const sorted = _.sortBy(employees, ['company.name'])
  const selectedIndex = _.findIndex(sorted, {id: employeeId})

  return (
    <List
      data={sorted}
      primaryKey={e =>
        <CompanyLink key={e.company.id} company={e.company} />}
      secondaryKey={e =>
        <Box key={e.id} direction="row" gap="xsmall" align="center">
          <Taglist tags={[
            ...(e.role == 'owner' ? [{name: 'владелец'}] : []),
            ...(e.role == 'manager' ? [{name: 'менеджер'}] : []),
          ]} />
        </Box>}
      itemProps={selectedIndex != -1
        ? {[selectedIndex]: {background: 'grey-50'}}
        : undefined}
      css={`& li {justify-content: unset;}`
      }
      onClickItem={e => onSelect(e.item.id)}
    />
  )
}

const CompanyLink = ({company}) => (
  <Text>
    <Anchor
      href={`/companies/${company.id}`}>
      {company.name}
    </Anchor>
  </Text>
)

// copypasted from Team.JobsLabel
const Taglist = ({tags}) => (
  <Box direction="row" gap="xsmall" wrap css={`
    // hack for vertical spacing of wrapped flex elemers
    margin-top: -1ex;
    & > * {margin-top: 1ex}
  `} >
    {_.map(tags, t => <JobLabel job={t} key={t.name} />)}
  </Box>
)
