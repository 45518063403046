import React from 'react'
import 'styled-components/macro'

import Intent from '../Intent'

import gql from 'graphql-tag'
import {
  UpdateCompanyInput,
  useUpdateCompanyMutation,
  useCompanyCardQuery,
  CompanyCardDocument,
} from '../../../generated/graphql'

import {CompanyCardForm} from './CreateCompany'

// eslint-disable-next-line
const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompanyCard(input: $input) {
      company {id, name, slug}
    }
  }
`

type IntentVariables = {
  companyId: string
}

export default class UpdateCompany extends Intent<IntentVariables> {
  static type = 'companies.update'

  get type(): string {
    return UpdateCompany.type
  }

  asComponent() {
    return () => {
      const companyQ = useCompanyCardQuery({
        variables: {companyId: this.initialProps.companyId}
      })
      const [mutate, {data, loading, error}] = useUpdateCompanyMutation({
        refetchQueries: [CompanyCardDocument.definitions[0].name.value]
      })

      const submit = (data: UpdateCompanyInput) => {
        mutate({
          variables: {input: {
            ...data,
            companyId: this.initialProps?.companyId
          }}
        }).then(({data}) => { })
      }

      if (companyQ.loading) return <p>РАБОТАЮ...</p>
      if (companyQ.error) return <p>ОШИБКА</p>

      if (loading) return <p>РАБОТАЮ...</p>
      if (error) return <p>ОШИБКА</p>
      if (data) return <p>ГОТОВО</p>

      const company = companyQ.data.listCompanies.companies[0]

      return (
        <CompanyCardForm onSubmit={submit} {...company}/>
      )
    }
  }
}
