import React from 'react'
import {
  Switch, Route, Redirect
} from "react-router-dom"

import {useAuth} from '../../common/auth'
import {LoginUser} from '../../pages/Login'
import {useMeQuery} from '../../generated/graphql'
import {appUrl} from '../../common/url'


import AdminCompanies from './Companies'
import AdminCompany from './Company'
import AdminPassport from './Passport'



export default function AdminPages() {
  const {user, loading} = useAuth()
  //FIXME обрабатывать основной логин LOADING прелоадер где-то в корневом роуте
  if (loading) return <p>LOADING...</p>
  if (!user) return <LoginUser />

  return (
    <AdminScopeGuard>
      <Switch>
        <Route path="/companies/:companyId"><AdminCompany /></Route>
        <Route path="/companies"><AdminCompanies /></Route>
        <Route path="/passports/:passportId"><AdminPassport /></Route>
        <Route path="/"><Redirect to="/companies" /></Route>
      </Switch>
    </AdminScopeGuard>
  )
}

function AdminScopeGuard({children}) {
  const {logout} = useAuth()
  const {data, loading, error} = useMeQuery()

  if (loading) return <p>LOADING...</p>
  if (error) {
    console.error(error)
    return <p>ERROR...</p>
  }

  const isAdmin = data?.me.passport?.type == 'admin'

  if (!isAdmin) {
    logout().finally(() => {
      window.location.replace(appUrl(''))
    })
    return null
  }

  return <>{children}</>
}
