import React from "react"
import {useHistory, useRouteMatch} from 'react-router-dom'
import {Button, ButtonProps} from "grommet"

type PropsT = ButtonProps
  & Omit<JSX.IntrinsicElements['button'], 'color'>
  & {exactMatch?: boolean}


// @see https://codesandbox.io/s/216vvpmlqj as a reference implementation
const RoutedButton = ({
  href, active, exactMatch, onClick, children, ...props
}: PropsT) => {
  const history = useHistory()
  const match = useRouteMatch({
    path: href,
    exact: exactMatch
  });

  return (
    <Button
      {...props}
      active={!!match || active}
      href={href}
      onClick={e => {
        e.preventDefault();
        onClick && onClick(e);
        history.push(href)
      }}>
      {children}
    </Button>
  )
}

export default RoutedButton
