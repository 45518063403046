import React from 'react'
import 'styled-components/macro'

import Intent from '../Intent'
import {appUrl} from '../../../common/url'

import {
  Form,
  FormField,
  Button,
  Box,
  Heading,
  Text,
  Paragraph,
  Select,
  CheckBox,
  Anchor as NoRouterAnchor,
} from 'grommet'
import {Paper} from '../../Paper'
import Pagebar from '../../Pagebar'


import gql from 'graphql-tag'
import {
  SetEmployeeRoleInput,
  useSetEmployeeRoleMutation,
  useToggleEmployeeInviteMutation,
  useEmployeeMetaQuery,
  EmployeeMetaQuery,
  EmployeeMetaDocument,
} from '../../../generated/graphql'
import {NotFound} from '../../NotFound'

//TODO implement as query fragment
// eslint-disable-next-line
const GET_EMPLOYEE_META = gql`
  query EmployeeMeta($employeeId: ID!) {
    listEmployees(input: {employeeIds: [$employeeId]}) {
      employees {
        id, role
        passport {id, type, firstName, lastName}
        company {id, name}
        invites {id}
      }
    }
  }
`
// eslint-disable-next-line
const SET_EMPLOYEE_ROLE = gql`
  mutation SetEmployeeRole($input: SetEmployeeRoleInput!) {
    setEmployeeRole(input: $input) {
      employee {id, role}
    }
  }
`

// eslint-disable-next-line
const TOGGLE_EMPLOYEE_INVITE = gql`
  mutation ToggleEmployeeInvite($input: ToggleEmployeeInviteInput!) {
    toggleEmployeeInvite(input: $input) {
      invites {id}
    }
  }
`

type IntentVariables = {
  employeeId: string
}

export default class UpdateEmployeeMeta extends Intent<IntentVariables> {
  static type = 'employees.updateMeta'

  get type(): string {
    return UpdateEmployeeMeta.type
  }

  asComponent() {
    return () => {
      const [mutate, {data, loading, error}] = useSetEmployeeRoleMutation()

      const submit = (data: SetEmployeeRoleInput) => {
        mutate({
          variables: {
            input: {
              ...data,
              employeeId: this.initialProps?.employeeId
            }
          }
        }).then(({data}) => {})
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      if (data) return <p>ГОТОВО</p>

      return (
        <EmployeeMetaForm onSubmit={submit}
          employeeId={this.initialProps?.employeeId}
        />
      )
    }
  }
}

export function EmployeeMetaForm({
  employeeId, onSubmit, submitLabel = ''
}) {
  const roles = [
    {name: 'сотрудник', key: 'employee'},
    {name: 'менеджер', key: 'manager'},
    {name: 'владелец', key: 'owner'},
  ]

  const {data, loading, error} = useEmployeeMetaQuery({
    variables: {employeeId: employeeId},
    fetchPolicy: 'network-only'
  })

  const employee = data?.listEmployees.employees[0]

  const [changes, setChanges] = React.useState(null)
  const fields = changes || {
    role: roles.find(({key}) => key == employee?.role)
  }

  if (loading) return <p>РАБОТАЮ...</p>
  if (error) return <p>ОШИБКА</p>
  if (!employee) return <NotFound />

  return (
    <Box gap="1.5ex">
      <Form value={fields}
        onSubmit={({value}: any) => onSubmit({
          role: value.role.key
        })}
      >
        <Paper gap="xsmall">
          <Box>
            <Heading level="2" margin="none">
              Карточка сотрудника
            </Heading>
            <Text color="secondary-dark" size="small"
              css={`
              font-variant: all-small-caps;
              line-height: 1.7;
              letter-spacing: 0.02em
            `}>
              {employee.company.name}
            </Text>
          </Box>
          <FormField name="role" required label="Роль"
            component={Select}
            dropHeight="medium"
            options={roles}
            valueKey="key"
            labelKey="name"
          />

          <Box direction="row-reverse" justify="between"
            margin={{top: 'small'}}
          >
            <Button type="submit" primary label={submitLabel || 'Сохранить'} />
          </Box>
        </Paper>
      </Form >

      {employee.passport.type == 'userless'
        && <InviteForm {...employee} />}
    </Box>
  )
}

function InviteForm({id, company, passport, invites}
  : EmployeeMetaQuery['listEmployees']['employees'][0]) {

  const [mutate, {data, loading, error}]
    = useToggleEmployeeInviteMutation({
      refetchQueries: [EmployeeMetaDocument.definitions[0].name.value]
    })

  const onSubmit = ({enable}) => mutate({
    variables: {
      input: {enable, employeeId: id}
    }
  })

  // if (loading) return <p>РАБОТАЮ...</p>
  if (error) return <p>ОШИБКА</p>

  // array may contain zero or one invites
  const invite = invites[0]
  const inviteUrl = appUrl(`/join/${invite?.id}`)

  const fields = {
    enabled: !loading && !!invite
  }

  return (
    <Paper gap="xsmall">
      <Pagebar
        title="Приглашение"
        level="3"
        actions={<CheckBox toggle checked={fields.enabled}
          onChange={e => onSubmit({enable: e.target.checked})}
        />}
      />
      {invite && (
        <>
          <NoRouterAnchor href={inviteUrl} target="_blank"
            css="overflow-wrap: break-word;"
          >
            {inviteUrl}
          </NoRouterAnchor>
          <Paragraph size="small" color="text-secondary">
            Скопируйте и отправьте эту ссылку вашему сотруднику. Перейдя по ней, он сможет присоединиться к организации «<b>{company.name}</b>» и совершать действия как <b>{passport.firstName} {passport.lastName}</b>
          </Paragraph>
        </>
      )}
    </Paper>
  )
}
