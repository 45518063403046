import React from 'react'
import 'styled-components/macro'
import _ from 'lodash'

import {useHistory} from 'react-router-dom'
import gql from 'graphql-tag'
import Intent from './Intent'
import {toast} from 'react-toastify'
import {orgPath} from '../../common/url'

import {
  useAcceptInviteMutation,
  GetPendingInviteInput,
  MeDocument
} from '../../generated/graphql'


// eslint-disable-next-line
const ACCEPT_INVITE = gql`
  mutation AcceptInvite($input: GetPendingInviteInput!) {
    acceptEmployeeInvite(input: $input) {
      employee {
       id, company {id}
      }
    }
  }
`

type IntentVariables = GetPendingInviteInput

export default class Login extends Intent<IntentVariables> {
  static type = 'company.join'

  get type(): string {
    return Login.type
  }

  asComponent() {
    return () => {
      const history = useHistory()
      const [mutate, {data, loading}] = useAcceptInviteMutation({
        errorPolicy: 'all'
      })

      React.useEffect(() => {
        mutate({
          variables: {
            input: {inviteId: this.initialProps.inviteId}
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            MeDocument.definitions[0].name.value,
          ]
        }).then(() => {
          history.replace(orgPath('welcome'))
        }).catch(e => {
          toast.error('Не удалось принять приглашение')
          console.error(e.message)
          history.replace(`/join/${this.initialProps.inviteId}`)
        })
      }, [])

      return <p>РАБОТАЮ...</p>
    }
  }
}
