import React from 'react'
import {
  FirebaseContext,
  FirebaseContextValue,
  Auth, User
} from '../firebase'
import {useAuthState} from 'react-firebase-hooks/auth'

import ApolloClient from 'apollo-client'
import {useApolloClient} from '@apollo/react-hooks'

import {initialData} from '../schema'
import {
  MeQuery,
  OrgContextDocument
} from '../generated/graphql'


export const useAuth = () => {
  const {auth} = React.useContext(FirebaseContext)
  const client = useApolloClient()
  const [user, loading, error] = useAuthState(auth)

  const login = React.useCallback(
    async credentials => onLogin(auth, client, credentials),
    [auth, client])

  const register = React.useCallback(
    async credentials => onRegister(auth, client, credentials),
    [auth, client])

  const logout = React.useCallback(
    async () => onLogout(auth, client),
    [auth, client])

  return {loading, error, user, login, register, logout}
}

async function onLogout<T>(auth: Auth, client: ApolloClient<T>) {
  // logout as user and employee
  window.localStorage.clear()

  await auth.signOut()

  // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
  // https://stackoverflow.com/questions/48887480
  await client.resetStore()
}

async function onLogin<T>(auth: Auth, client: ApolloClient<T>, creds) {
  const {email, password} = creds
  //should be done before user sign in
  //otherwise it'll cause me-queries interruption
  await client.resetStore()
  await auth.signInWithEmailAndPassword(email, password)
}

async function onRegister<T>(auth: Auth, client: ApolloClient<T>, creds) {
  const {email, password} = creds
  //should be done before user sign in
  //otherwise it'll cause me-queries interruption
  await client.resetStore()
  await auth.createUserWithEmailAndPassword(email, password)
}

export async function onCompanyEnter<T>(client: ApolloClient<T>, orgId) {
  const {data} = await client.query({query: OrgContextDocument})

  if (!data.currentOrgId) {
    return client.writeData({
      data: {
        currentOrgId: orgId
      }
    })
  }
  if (data.currentOrgId == orgId) return

  // org id changed, reset all store cache
  await client.resetStore()
}
