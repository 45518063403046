/**
 * Local graphql schema amendments
 */

import gql from 'graphql-tag'

const LOGIN_STATUS = gql`
  query orgContext {
    currentOrgId @client
  }
`;

// data to populate cache after store reset
export const initialData = {
  currentOrgId: null,
}

export const typeDefs = gql`
  extend type Query {
    # used to decide whether cache reset is needed
    currentOrgId: String
  }
`;

export const resolvers = {
  // none here, see examples in the apollo tutorial
}
