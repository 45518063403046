/**
 * To change locale via app, do something like this
  export default function App() {
  const [locale, setLocale] = useState("ru");
  useEffect(() => {
    // this changes the locale GLOBALLY! for the local setup, check the docs
    // TODO: find a better place for moment locale config
    moment.locale(locale)
  }, [locale])
 */

import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export default moment
