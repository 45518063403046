import React from 'react'


import {Select} from 'grommet'



export const SearchSelect = ({options, searchKey, onClose, ...props}
  : React.ComponentProps<typeof Select> & {searchKey?: string}) => {

  const [searchOptions, setSearchOptions] = React.useState(null)

  return (
    <Select
      options={searchOptions || options}
      {...props}
      onClose={(...args) => {setSearchOptions(null); onClose?.(...args)}}
      onSearch={searcher(options, searchKey, setSearchOptions)}
    />
  )
}

function searcher(options, key, onResult) {
  const getText = option => (key ? option[key] : option)

  return pattern => {
    // The line below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escaped = pattern.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")

    // Create the regular expression with modified value which
    // handles escaping special characters. Without escaping special
    // characters, errors will appear in the console
    const exp = new RegExp(escaped, "i")
    onResult(options.filter(o => exp.test(getText(o))))
  }
}
