import React from 'react'
import 'styled-components/macro'

import {Box} from 'grommet'
import LoginUserIntent from '../components/intent/LoginUser'
import LoginCompanyIntent from '../components/intent/LoginCompany'
import CompletePassportIntent from '../components/intent/CompletePassport'


export function LoginUser() {
  const LoginForm = new LoginUserIntent().asComponent()

  return (
    <Box fill align="center">
      <LoginForm/>
    </Box>
  )
}

export function CompletePassport() {
  const PassportForm = new CompletePassportIntent().asComponent()

  return (
    <Box fill align="center">
      <PassportForm/>
    </Box>
  )
}

export function LoginCompany() {
  const LoginForm = new LoginCompanyIntent().asComponent()

  return (
    <Box fill align="center">
      <LoginForm/>
    </Box>
  )
}
