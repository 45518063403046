import React from 'react'
import _ from 'lodash'
import {toast} from 'react-toastify'
import Intent from './Intent'

import {
  Form, FormField, Button, Heading, Box
} from 'grommet'

import {Paper} from '../Paper'

import gql from 'graphql-tag'
import {
  useUpdateTeamCardMutation,
  useTeamCardQuery,
  TeamCardDocument,
  UpdateTeamInput,
} from '../../generated/graphql'

// eslint-disable-next-line
const UPDATE_TEAM = gql`
  mutation UpdateTeamCard($input: UpdateTeamInput!) {
    updateTeamCard(input: $input) {
      team {
        id, name
      }
    }
  }
`

type IntentVariables = Pick<UpdateTeamInput, 'teamId'>

export default class UpdateTeam extends Intent<IntentVariables> {
  static type = 'teams.update_card'

  get type(): string {
    return UpdateTeam.type
  }

  asComponent() {
    return () => {
      const [mutate, {data, loading, error}] =
        useUpdateTeamCardMutation({
          refetchQueries: [TeamCardDocument.definitions[0].name.value]
        })

      const submit = (data: UpdateTeamInput) => {
        mutate({
          variables: {input: data}
        }).then(({data}) => {
          console.log('FINISHED', data)
          toast.success('Изменения сохранены')
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      // if (data) return <p>ГОТОВО</p>

      return (
        <UpdateTeamForm onSubmit={submit} {...this.initialProps} />
      )
    }
  }
}

const UpdateTeamForm = ({onSubmit, teamId}) => {
  const {data, loading, error} = useTeamCardQuery({
    variables: {teamId}
  })

  const [changes, setChanges] = React.useState(null)
  const team = data?.listTeams.teams[0]
  const fields = changes || team

  return team ? (
    <Form value={fields}
      onChange={v => {v.preventDefault ?? setChanges(v)}}
      onSubmit={({value}: any) => onSubmit({
        teamId,
        ..._.pick(value, 'name'),
      })}>
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          Карточка команды
        </Heading>
        <FormField name="name" required label="Название" />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary disabled={!changes}
            label="Сохранить" />
        </Box>
      </Paper>
    </Form >
  ) : <i>'ЗАГРУЗКА'</i>
}
