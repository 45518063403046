import React from 'react'
import 'styled-components/macro'
import _ from 'lodash'

import {useApolloClient} from '@apollo/react-hooks'
import {useHistory} from 'react-router-dom'
import {orgPath} from '../../common/url'
import Intent from './Intent'

import {
  Paragraph,
  Box,
  List,
  Heading
} from 'grommet'
import {Paper} from '../Paper'

import {
  useMeQuery,
  MeQuery
} from '../../generated/graphql'


type IntentVariables = {
  employee: MeQuery['me']['passport']['employments'][0]
}

export default class Login extends Intent<IntentVariables> {
  static type = 'app.loginCompany'

  get type(): string {
    return Login.type
  }

  asComponent() {
    return () => {
      const client = useApolloClient()
      const {data, loading, error} = useMeQuery({
        fetchPolicy: 'network-only',
        // onCompleted: data => {
        //   onResetMemberships(client, data.me.passport)
        // }
      })
      if (loading) return <p>ЗАГРУЗКА...</p>;
      if (error) return <p>ОШИБКА</p>;

      // const submit = (data: IntentVariables) => {
      // }

      return (
        <LoginForm employments={data.me.passport.employments} />
      )
    }
  }
}

const LoginForm = ({employments}: {
  employments: IntentVariables['employee'][]
}) => {
  const history = useHistory()
  const submit = (employment: IntentVariables['employee']) => {
    history.push(orgPath.call(
      {orgId: employment.company.id}, ''))
  }

  return (
    <Paper css="width: 100%; max-width: 400px">
      <Heading level="2" margin={{top: '0'}}>
        Вход в компанию
      </Heading>
      {_.isEmpty(employments) ? <NoEmploymentsMessage /> : (
        <EmploymentsList
          employments={employments}
          onSelect={submit}
        />
      )}
    </Paper>
  )
}

const EmploymentsList = ({employments, onSelect}) => (
  <List
    primaryKey={i => i.company.name}
    // secondaryKey="percent"
    data={employments}
    onClickItem={e => onSelect(e.item)}
  />
)

const NoEmploymentsMessage = () => (
  <Box css="p {font-size: .9rem}">
    <Paragraph margin="xsmall">
      У вас нет доступа ни к одной организации.
    </Paragraph> <Paragraph margin="xsmall">
      Обратитесь к администратору вашей компании, чтобы получить ссылку с приглашением.
    </Paragraph> <Paragraph margin="xsmall">
      Никто не приглашает? Зарегистрируйте свою компанию, написав в вотсап <b>+7-999-7008007</b> или
      по почте <b>mail.maslennikov@gmail.com</b>
    </Paragraph>
  </Box>
)
