import React from 'react'

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as Firebase from 'firebase/app'
// Add the Firebase products that you want to use
import 'firebase/auth'

export const firebase = Firebase
export type Auth = Firebase.auth.Auth
export type User = Firebase.User

export const config = {
  apiKey: "AIzaSyDNW9-_cR31E-Ey5KXfM8uxPL2sLWI7Uk4",
  authDomain: "smennik-e751f.firebaseapp.com",
  databaseURL: "https://smennik-e751f.firebaseio.com",
  projectId: "smennik-e751f",
  storageBucket: "smennik-e751f.appspot.com",
  messagingSenderId: "270199414155",
  appId: "1:270199414155:web:318de649a8489040e5638e",
  measurementId: "G-4ZZF6XVL58"
}

export const FirebaseContext = React.createContext
  <FirebaseContextValue>(undefined)

export class FirebaseContextValue {
  constructor(cfg = config) {
    firebase.initializeApp(cfg)
  }

  get auth(): Firebase.auth.Auth {
    return firebase.auth()
  }
}
