import React from 'react'
import Intent from './Intent'


export const IntentContext = React.createContext({
  onIntent: (intent: Intent<any>) => {}
})

export class IntentContextValue {
  public intent: Intent<any>
  public setIntent: (intent: Intent<any>) => void
  private onIntent: (intent: Intent<any>) => any

  constructor(onIntent) {
    this.onIntent = onIntent
    this.setIntent = (intent: Intent<any>) => {
      this.intent = intent
      this.onIntent(intent)
    }
  }
}
