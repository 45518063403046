import React from 'react'
import 'styled-components/macro'

import Intent from '../Intent'
import {useHistory} from 'react-router-dom'

import {
  Form,
  FormField,
  Button,
  Box,
  Heading
} from 'grommet'
import {Paper} from '../../Paper'

import gql from 'graphql-tag'
import {
  CreateCompanyInput,
  useCreateCompanyMutation,
} from '../../../generated/graphql'

// eslint-disable-next-line
const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {id, name}
    }
  }
`

type IntentVariables = {
  companyId: string
}

export default class CreateCompany extends Intent<IntentVariables> {
  static type = 'companies.create'

  get type(): string {
    return CreateCompany.type
  }

  asComponent() {
    return () => {
      const history = useHistory()
      const [mutate, {data, loading, error}] = useCreateCompanyMutation({
        // we do not refetch listings
        // refetchQueries: [
        //   ListAllCompaniesDocument.definitions[0].name.value,
        // ],
        // awaitRefetchQueries: true
      })

      const submit = (data: CreateCompanyInput) => {
        mutate({
          variables: {input: data},

        }).then(({data}) => {
          console.log('FINISHED', data)
          //TODO: сделать интерфейс, принимающий редирект-ссылку/колбек и refetchQueries. И если будет редирект, выставлять `awaitRefetchQueries`:true, потому что иначе Promise будет fulfilled не дожидаясь всех рефетчей, произойдет редирект, и эти queries почему-то не обновят кэш.
          // https://www.apollographql.com/docs/react/api/react-apollo/#optionsawaitrefetchqueries
          return history.push(`/companies/${data.createCompany.company.id}`)
        })
      }

      if (loading) return <p>РАБОТАЮ...</p>;
      if (error) return <p>ОШИБКА</p>;
      if (data) return <p>ГОТОВО</p>

      return (
        <CompanyCardForm title="Новая организация"
        onSubmit={submit} submitLabel="Создать"/>
      )
    }
  }
}

export function CompanyCardForm({
  title='', onSubmit, submitLabel='', name='', slug=''
}) {
  const [changes, setChanges] = React.useState(null)
  const fields = changes || {name, slug}

  return (
    <Form value={fields}
      // form.onChange has a bug sometimes leaking the native event
      // into onChange (e.g. upon key press on input)
      // https://github.com/grommet/grommet/issues/3356
      onChange={v => {
        v.preventDefault ?? setChanges({
          ...v, slug: v.slug.toLowerCase()
        })
      }}
      onSubmit={({value}: any) => onSubmit(value)}
    >
      <Paper>
        <Heading level="2" margin={{top: '0'}}>
          {title || 'Карточка организации'}
        </Heading>
        <FormField name="name" required label="Название" />
        <FormField name="slug" required label="URL"
          validate={(val: string, fields) => {
            if (['admin', 'smennik'].includes(val))
              return 'Значение запрещено'
            if (!/^[0-9a-z-]+$/.test(val))
              return 'Может включать только цифры, буквы и дефис'
            if (/^[\d-]+$/.test(val))
              return 'Не может состоять только из цифр'
            if (!/^[0-9a-z]/.test(val))
              return 'Должно начинаться с цифры или буквы'
            if (!/[0-9a-z]$/.test(val))
              return 'Должно оканчиваться цифрой или буквой'
            if (val.length < 4)
              return 'Слишком короткое'
            if (val.length > 25)
              return 'Слишком длинное'
            return null
          }}
        />

        <Box direction="row-reverse" justify="between"
          margin={{top: 'small'}}
        >
          <Button type="submit" primary label={submitLabel || 'Сохранить'} />
        </Box>
      </Paper>
    </Form >
  )
}
